import { combineReducers } from "redux";
import profileSlice from "./slices/profileSlice";
import authenticationSlice from "./slices/authenticationSlice";
import menuSlice from "./slices/menuSlice";
import claimantSlice from "./slices/claimantSlice";
import notesSlice from "./slices/notesSlice";
import messagesSlice from "./slices/messageSlice";
import documentsSlice from "./slices/documentsSlice";
import eSignSlice from "./slices/eSignSlice";
import hdrSlice from "./slices/hdrSlice";
import loadingSlice from "./slices/loadingSlice";
import tenancySlice from "./slices/tenancySlice";
import pcpSlice from "./slices/pcpSlice";
import dbSlice from "./slices/dbSlice";
import olSlice from "./slices/olSlice";
import plSlice from "./slices/plSlice";
import immigrationSlice from "./slices/immigrationSlice";
import medicalNegligenceSlice from "./slices/medicalNegligenceSlice";
import elSlice from "./slices/elSlice";
import hireSlice from "./slices/hireSlice";
import rtaSlice from "./slices/rtaSlice";
import invoiceSlice from "./slices/invoiceSlice";
import dropdownSlice from "./slices/dropdownSlice";
import TokenExpirySlice from "./slices/TokenExpirySlice";
import captchaVerifiedSlice from "./slices/captchaVerifiedSlice";

export default combineReducers({
  profileSlice,
  authenticationSlice,
  menuSlice,
  claimantSlice,
  notesSlice,
  messagesSlice,
  documentsSlice,
  eSignSlice,
  hdrSlice,
  loadingSlice,
  tenancySlice,
  pcpSlice,
  dbSlice,
  olSlice,
  plSlice,
  immigrationSlice,
  medicalNegligenceSlice,
  elSlice,
  hireSlice,
  rtaSlice,
  invoiceSlice,
  dropdownSlice,
  TokenExpirySlice,
  captchaVerifiedSlice,
});
