import { Dialog } from "primereact/dialog";
import React from "react";
import AddCompanyUser from "./AddCompanyUser";

function AddUserDialog({ showModal, onCloseModal, getuserList, id }) {
  return (
    <Dialog
      header="Add User"
      position="top"
      draggable={false}
      visible={showModal}
      style={{ width: "80vw" }}
      onHide={onCloseModal}
    >
      <div className="mt-4">
        <AddCompanyUser
          getuserList={getuserList}
          onCloseModal={onCloseModal}
          id={id}
        />
      </div>
    </Dialog>
  );
}

export default AddUserDialog;
