import * as Yup from "yup";

const initialValues = {
  compaigncode: "",
  bike: "",
  taxi: "",
  compaigndescr: "",
  createdon: "",
  faultrepairs: "",
  housingFee: "",
  hybrid: "",
  wiplash: "",
  minor: "",
  pedestrian: "",
  prestigeroadworthy: "",
  prestigeunroadworthy: "",
  recovery: "",
  remarks: "",
  repairs: "",
  salvage: "",
  scotishRta: "",
  serious: "",
  solicitorsfees: "",
  standardroadworthy: "",
  standardunroadworthy: "",
  status: "",
  storage: "",
};

const validationSchema = Yup.object().shape({
  compaigncode: Yup.string().required("Required"),
  status: Yup.string().required("Required"),
});

export default validationSchema;

export { initialValues, validationSchema };
