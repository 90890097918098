import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authenticationSlice",
  initialState: {
    loginData: "",
    token: localStorage.getItem("token"),
    pwdUpdateFlag: localStorage.getItem("pwdUpdateFlag"),
    nav: localStorage.getItem("nav"),
    menu: localStorage.getItem("menu"),
    directIntroducer: localStorage.getItem("directIntroducer"),
    loggedIn: localStorage.getItem("loggedIn"),
    idleLogoutTimeMM: localStorage.getItem("idleLogoutTimeMM"),
    tblUser: localStorage.getItem("tblUser"),
  },
  reducers: {
    LOGIN_SUCCESS: (state, action) => {
      return {
        ...state,
        loginData: action.payload,
        token: localStorage.getItem("token"),
        pwdUpdateFlag: localStorage.getItem("pwdUpdateFlag"),
        nav: localStorage.getItem("nav"),
        menu: localStorage.getItem("menu"),
        loggedIn: localStorage.getItem("loggedIn"),
        directIntroducer: localStorage.getItem("directIntroducer"),
        idleLogoutTimeMM: localStorage.getItem("idleLogoutTimeMM"),
        tblUser: localStorage.getItem("tblUser"),
      };
    },
    LOGIN_ERROR: (state) => {
      return {
        ...state,
        loginData: "",
        token: "",
        nav: "",
        menu: "",
        loggedIn: "",
        idleLogoutTimeMM: "",
        tblUser: "",
      };
    },
  },
});

export const { LOGIN_SUCCESS, LOGIN_ERROR } = slice.actions;
export default slice.reducer;
