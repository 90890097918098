import React, { useState } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import {
  FaSignature,
  FaArrowLeft,
  FaPencilAlt,
  FaKeyboard,
} from "react-icons/fa";
import styled from "styled-components";
import SignatureDraw from "./SignatureDraw";
import SignatureType from "./SignatureType";
import { handlePostRequest } from "../../services/PostTemplate";
import ModesInfo from "./ModesInfo";
import ButtonModeSelector from "./ButtonModeSelector";
import { getESigns } from "../../redux/actions/eSignAction";
import { useDispatch } from "react-redux";

const BackButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

function Signoff({
  campaignCode,
  campaignName,
  formValues,
  encryptedUrl,
  url,
  pass,
}) {
  const [showSignOffModal, setShowSignOffModal] = useState(false);
  const [selectedMode, setSelectedMode] = useState(null);
  const dispatch = useDispatch();

  const handleSubmit = async (signature) => {
    const api = `${campaignName}/addESign`;
    // Check if formValues has keys and values
    let data;
    if (formValues && Object.keys(formValues).length > 0) {
      data = {
        code: campaignCode,
        eSign: signature,
        encryptedUrl,
        ...formValues,
      };
    } else {
      data = {
        code: campaignCode,
        eSign: signature,
        encryptedUrl,
      };
    }
    try {
      await handlePostRequest(data, api);
      clearSelection();
      setShowSignOffModal(false);
      dispatch(
        await getESigns(campaignCode, campaignName, pass, encryptedUrl, url)
      );
    } catch (err) {
      console.log(err.response);
    }
  };

  const clearSelection = () => {
    setSelectedMode(null);
  };

  const renderSignatureComponent = () => {
    switch (selectedMode) {
      case "draw":
        return <SignatureDraw onSubmit={handleSubmit} />;
      case "type":
        return <SignatureType onSubmit={handleSubmit} />;
      default:
        return null;
    }
  };

  const renderMainButtons = () => (
    <div className="pb-4">
      <ModesInfo />
      <div align="center">
        <ButtonModeSelector
          label="Draw Signature"
          icon={<FaPencilAlt />}
          onClick={() => setSelectedMode("draw")}
          size={35}
        />

        <ButtonModeSelector
          label="Type Signature"
          icon={<FaKeyboard />}
          onClick={() => setSelectedMode("type")}
          size={35}
        />
      </div>
    </div>
  );

  const renderBackButton = () => (
    <BackButtonContainer>
      <Button
        label="Back"
        icon={<FaArrowLeft />}
        onClick={() => {
          clearSelection();
          setSelectedMode(null);
        }}
        className="btn btn-secondary"
      />
    </BackButtonContainer>
  );

  return (
    <>
      <div align="center" className="mt-4">
        <Button
          onClick={() => setShowSignOffModal(true)}
          className="btn btn-primary"
        >
          <FaSignature className="pdf-file" />
          &nbsp;Click here to sign
        </Button>
      </div>
      <Dialog
        header="E-signature"
        visible={showSignOffModal}
        style={{ minWidth: "50vw", maxWidth: "80vw" }}
        onHide={() => {
          setShowSignOffModal(false);
          clearSelection();
        }}
        breakpoints={{ "960px": "75vw", "641px": "100vw" }}
      >
        {selectedMode ? renderBackButton() : renderMainButtons()}
        {renderSignatureComponent()}
      </Dialog>
    </>
  );
}

export default Signoff;
