import React, { useEffect, useState } from "react";
import BroadcastDescr from "./BroadcastDescr";
import { useFormik } from "formik";
import * as Yup from "yup";
import classNames from "classnames";
import { handleGetRequest } from "../../../services/GetTemplate";
import { handlePostRequest } from "../../../services/PostTemplate";
import { MultiSelect } from "primereact/multiselect";
import { IoMdMegaphone } from "react-icons/io";
import styled from "styled-components";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Panel } from "primereact/panel";
import EmailInputField from "./EmailInputField";

function AddBroadcast() {
  const [editorContent, setEditorContent] = useState("");
  const [usersOptions, setUsersOptions] = useState([]);
  const [introducersOptions, setIntroducersOptions] = useState([]);
  const [legalInternalOptions, setLegalInternalOptions] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [otherEmails, setOtherEmails] = useState(false);
  const onEditorChange = (value) => {
    console.log(value);
    setEditorContent(value);
  };

  const onEmailChange = (value) => {
    setOtherEmails(value);
  };

  useEffect(() => {
    const getUsersOptions = async () => {
      try {
        const response = await handleGetRequest("lovSolicitorUsers");
        setUsersOptions(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getUsersOptions();
  }, []);

  useEffect(() => {
    const getIntroducersOptions = async () => {
      try {
        const response = await handleGetRequest("lovIntroducerUsers");
        setIntroducersOptions(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getIntroducersOptions();
  }, []);

  useEffect(() => {
    const getLegalInternalOptions = async () => {
      try {
        const response = await handleGetRequest("lovLegalInternalsUsers");
        setLegalInternalOptions(response.data);
      } catch (error) {
        console.error(error);
      }
    };
    getLegalInternalOptions();
  }, []);

  const validationSchema = Yup.object().shape({
    now: Yup.string().required("Please select one option"),
    sechduleDate: Yup.string().when("now", {
      is: "N",
      then: Yup.string().required("Please select a date"),
    }),
    sechduleTime: Yup.string().when("now", {
      is: "N",
      then: Yup.string().required("Please select a time"),
    }),
    subject: Yup.string().required("Please enter a subject"),
  });

  const initialValues = {
    usercode: [],
    introducercode: [],
    legalinternalcode: [],
    now: "Y",
    sechduleDate: "",
    sechduleTime: "",
    subject: "",
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      const userList =
        data.usercode.length > 0 ? data.usercode.map((item) => item.code) : [];
      const introducerList =
        data.introducercode.length > 0
          ? data.introducercode.map((item) => item.code)
          : [];
      const legalInternalList =
        data.legalinternalcode.length > 0
          ? data.legalinternalcode.map((item) => item.code)
          : [];
      const toUsers = [...userList, ...introducerList, ...legalInternalList];
      data["toUsers"] = toUsers;
      delete data.usercode;
      delete data.introducercode;
      delete data.legalinternalcode;
      data["message"] = editorContent;
      // split the emails array  into an string separated by comma
      const otherEmailsString =
        otherEmails.length > 0 ? otherEmails.join(",") : "";
      data["other"] = otherEmailsString;
      console.log(JSON.stringify(data));
      try {
        await handlePostRequest(data, "userManagement/createBroadCastMessage");
      } catch (e) {
        console.log(e);
      }
    },
  });
  return (
    <div>
      <div align="center">
        <StyledHeading>
          Broadcast{" "}
          <span>
            {" "}
            <IoMdMegaphone size={24} color="#333" />
          </span>{" "}
        </StyledHeading>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-8 p-md-8 p-mt-2 p-mb-6">
            <Panel header="Create Your Message Here">
              <div className="p-field p-col-12 p-md-12 p-mt-2">
                <label className="p-d-block">Subject</label>
                <InputText
                  id="subject"
                  placeholder="Enter Subject"
                  name="subject"
                  type="text"
                  value={formik.values.subject}
                  onChange={formik.handleChange}
                  className={classNames({
                    "p-invalid": isFormFieldValid("subject"),
                  })}
                />
                {getFormErrorMessage("subject")}
              </div>
              <BroadcastDescr onEditorChange={onEditorChange} />
            </Panel>
          </div>
          <div className="p-field p-col-4 p-md-4 p-mt-6 p-mb-6">
            <StyledKeyPoints>
              <ul>
                <li>
                  You can broadcast message to all users or to a specific user.
                </li>
                <li>
                  You can Shedule your message to be broadcasted at a later
                  date.
                </li>
                <li>You can style your message using the toolbar above.</li>
              </ul>
            </StyledKeyPoints>
          </div>
          <div className="p-field p-col-8 p-md-8 p-mt-2">
            <Panel header="Select Your Audience">
              <div className="p-field p-col-12 p-md-8 p-mt-2">
                <Checkbox
                  onChange={(e) => {
                    if (e.checked) {
                      setIsSelectAll(true);
                      formik.setFieldValue("usercode", usersOptions);
                      formik.setFieldValue(
                        "introducercode",
                        introducersOptions
                      );
                      formik.setFieldValue(
                        "legalinternalcode",
                        legalInternalOptions
                      );
                    } else {
                      setIsSelectAll(false);
                      formik.setFieldValue("usercode", []);
                      formik.setFieldValue("introducercode", []);
                      formik.setFieldValue("legalinternalcode", []);
                    }
                  }}
                  checked={isSelectAll}
                />
                <label htmlFor="now" className="p-ml-2">
                  Select All Users to Broadcast Message ?
                </label>
              </div>
              <div className="p-field p-col-12 p-md-12 p-mt-2">
                <label htmlFor="usercode">Users</label>
                <MultiSelect
                  filter
                  id="usercode"
                  name="usercode"
                  options={usersOptions}
                  optionLabel="name"
                  value={formik.values.usercode}
                  onChange={formik.handleChange}
                  className={
                    (classNames({
                      "p-invalid": isFormFieldValid("usercode"),
                    }),
                    "Width__100")
                  }
                />
                {getFormErrorMessage("usercode")}
              </div>
              <div className="p-field p-col-12 p-md-12 p-mt-2">
                <label htmlFor="introducercode">Introducers</label>
                <MultiSelect
                  filter
                  id="introducercode"
                  name="introducercode"
                  options={introducersOptions}
                  optionLabel="name"
                  value={formik.values.introducercode}
                  onChange={formik.handleChange}
                  className={
                    (classNames({
                      "p-invalid": isFormFieldValid("introducercode"),
                    }),
                    "Width__100")
                  }
                />
                {getFormErrorMessage("introducercode")}
              </div>
              <div className="p-field p-col-12 p-md-12 p-mt-2">
                <label htmlFor="legalinternalcode">Legal Internal</label>
                <MultiSelect
                  filter
                  id="legalinternalcode"
                  name="legalinternalcode"
                  options={legalInternalOptions}
                  optionLabel="name"
                  value={formik.values.legalinternalcode}
                  onChange={formik.handleChange}
                  className={
                    (classNames({
                      "p-invalid": isFormFieldValid("legalinternalcode"),
                    }),
                    "Width__100")
                  }
                />
                {getFormErrorMessage("legalinternalcode")}
              </div>
              <div className="p-field p-col-12 p-md-12 p-mt-2">
                <label>Other</label>
                <EmailInputField onEmailChange={onEmailChange} />
              </div>
            </Panel>
          </div>
          <div className="p-field p-col-4 p-md-4 p-mt-2">
            <Panel header="Schedule to Broadcast Your Message">
              <div className="p-field p-col-12 p-md-12 p-mt-2 p-mb-4">
                <Checkbox
                  inputId="now"
                  name="now"
                  value="now"
                  onChange={(e) => {
                    formik.setFieldValue("now", e.checked ? "Y" : "N");
                  }}
                  checked={formik.values.now === "Y"}
                />
                <label htmlFor="now" className="p-ml-2">
                  Broadcast your message right now ?
                </label>
              </div>
              {formik.values.now === "N" && (
                <>
                  <div className="p-field p-col-12 p-md-12 p-mt-2">
                    <label className="p-d-block">Sechdule Date</label>
                    <InputText
                      id="sechduleDate"
                      placeholder="Sechdule Date"
                      name="sechduleDate"
                      type="date"
                      value={formik.values.sechduleDate}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("sechduleDate"),
                      })}
                    />
                    {getFormErrorMessage("sechduleDate")}
                  </div>
                  <div className="p-field p-col-12 p-md-12 p-mt-2">
                    <label className="p-d-block">Sechdule Time</label>
                    <InputText
                      id="sechduleTime"
                      placeholder="Sechdule Date"
                      name="sechduleTime"
                      type="time"
                      value={formik.values.sechduleTime}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("sechduleTime"),
                      })}
                    />
                    {getFormErrorMessage("sechduleTime")}
                  </div>
                </>
              )}
            </Panel>
          </div>
        </div>

        <div align="center" className="p-col-12 p-md-12">
          <Button type="submit" className="btn btn-primary mr-2">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

const StyledHeading = styled.h1`
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
`;

const StyledKeyPoints = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f5f5f5;
  ul {
    list-style: square;
    padding: 0;
    padding-left: 1rem;
    margin: 0;
    li {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
      color: #333;
    }
  }
`;

export default AddBroadcast;
