import React from "react";
import { useFormik } from "formik";
import { Button } from "primereact/button";
import { FaSpinner } from "react-icons/fa";
import LaInput from "../../../components/LaInput";
import * as Yup from "yup";
import { useSelector } from "react-redux";

const initialValues = {
  password: "",
};

const validationSchema = Yup.object().shape({
  password: Yup.string().required("required"),
});

function EsignPasswordForm({ onSubmitPassword }) {
  const { loading } = useSelector((state) => state.loadingSlice);
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (formData) => {
      try {
        onSubmitPassword(formData.password);
      } catch (error) {
        console.error(error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid p-px-4">
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Password</label>
            <LaInput keyName="password" type="password" {...props} />
          </div>
        </div>
        <div align="center" className="p-col-12 p-md-12 mt-4">
          {loading ? (
            <div className="spinner-container">
              <FaSpinner className="spinner-icon" />
            </div>
          ) : (
            <Button type="submit" className="btn btn-primary mr-2">
              Submit
            </Button>
          )}
        </div>
      </form>
    </>
  );
}

export default EsignPasswordForm;
