import React from "react";
import LaInput from "../../../components/LaInput";
import LaMultiSelect from "../../../components/LaMultiSelect/LaMultiSelect";
import LaStaticDropdown from "../../../components/LaStaticDropdown";

function UserForm({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  disabled,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    disabled,
  };

  return (
    <>
      <div className="p-fluid p-formgrid p-grid p-px-4">
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">Login ID</label>
          <LaInput keyName="loginid" placeholder="loginid" {...props} />
        </div>
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">User Roles</label>
          <LaMultiSelect
            keyName="rolecodes"
            optionLabel="name"
            placeholder=""
            url={"/lovRole"}
            {...props}
          />
        </div>
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">Email</label>
          <LaInput keyName="username" placeholder="email" {...props} />
        </div>
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">Status</label>
          <LaStaticDropdown
            keyName="status"
            optionLabel="label"
            options={[
              { value: true, label: "Active" },
              { value: false, label: "Inactive" },
            ]}
            {...props}
          />
        </div>
      </div>
    </>
  );
}

export default UserForm;
