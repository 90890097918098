import React, { useState, useEffect, useRef } from "react";
import { AiFillCaretDown } from "react-icons/ai";

/**
 * Hook for handling closing when clicking outside of an element
 * @param {React.node} el
 * @param {boolean} initialState
 */
export const useDetectOutsideClick = (el, initialState) => {
  const [isActive, setIsActive] = useState(initialState);

  useEffect(() => {
    const onClick = (e) => {
      // If the active element exists and is clicked outside of
      if (el.current !== null && !el.current.contains(e.target)) {
        setIsActive(!isActive);
      }
    };

    // If the item is active (ie open) then listen for clicks outside
    if (isActive) {
      window.addEventListener("click", onClick);
    }

    return () => {
      window.removeEventListener("click", onClick);
    };
  }, [isActive, el]);

  return [isActive, setIsActive];
};

function NavDropdown({ icon, title, children }) {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useDetectOutsideClick(dropdownRef, false);

  return (
    <>
      <div
        className="navbar-dropdown"
        onClick={() => setIsOpen((open) => !open)}
        ref={dropdownRef}
      >
        {icon}
        &nbsp;
        {title}
        &nbsp;
        <AiFillCaretDown />
        {isOpen && children}
      </div>
    </>
  );
}

const NavMenuItemWrapper = ({ children }) => {
  return <div className="nav-menu">{children}</div>;
};

const NavMenuItem = ({ children, onClick }) => {
  return (
    <div className="nav-menu-item" onClick={() => (onClick ? onClick() : null)}>
      {children}
    </div>
  );
};

export { NavDropdown, NavMenuItemWrapper, NavMenuItem };
