import React, { useState, useEffect, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Badge } from "primereact/badge";
import { Button } from "primereact/button";
import "./DataTable.css";
import { getLovRole } from "../../services/UsersRegister";

const EditViewUsersData = ({ companyUsersData, editRow }) => {
  const [usersData, setusersData] = useState(null);
  const [userRole, setuserRole] = useState([]);
  const dt = useRef(null);

  useEffect(() => {
    setusersData(companyUsersData);
  }, [companyUsersData]);

  const loginIdBodyTemplate = (rowData) => {
    return <React.Fragment>{rowData.loginid}</React.Fragment>;
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Status</span>
        {rowData.status === "Y" ? (
          <Badge value="Active" severity="success" className="p-mr-2"></Badge>
        ) : (
          <Badge value="Inactive" severity="warning" className="p-mr-2"></Badge>
        )}
      </React.Fragment>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button
          icon="pi pi-pencil"
          className="p-button-rounded p-button-info p-button-text p-mr-2"
          onClick={() => editRow(rowData)}
        />
      </React.Fragment>
    );
  };

  async function funcgetLovRole() {
    const res = await getLovRole();
    setuserRole(res.data);
  }

  useEffect(() => {
    funcgetLovRole();
  }, []);

  const userRolesBodyTemplate = (rowData) => {
    const tblUserroles = rowData?.lovResponse;
    return (
      <>
        {tblUserroles?.map((x) => (
          <Badge value={x.name} severity="info" className="p-mr-2"></Badge>
        ))}
      </>
    );
  };

  return (
    <div className="datatable-filter-demo">
      <div className="card p-datatable-sm">
        <DataTable
          ref={dt}
          value={usersData}
          paginator
          rows={10}
          className="p-datatable-customers"
          emptyMessage="No customers found."
        >
          <Column
            style={{ textAlign: "center" }}
            field="loginid"
            header="Login ID"
            body={loginIdBodyTemplate}
            filter
            sortable
          />
          <Column field="username" header="Email" filter sortable />
          <Column
            style={{ textAlign: "center" }}
            field="status"
            header="Status"
            body={statusBodyTemplate}
            filter
            sortable
          />
          <Column
            style={{ textAlign: "center" }}
            field="rolecodes"
            header="Roles"
            body={userRolesBodyTemplate}
            filter
            sortable
          />
          <Column
            style={{ textAlign: "center" }}
            body={actionBodyTemplate}
            filterMatchMode="contains"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};
export default EditViewUsersData;
