import React, { useState } from "react";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import "./Accordion.css";
import { motion, AnimatePresence } from "framer-motion";

function Accordion({ children, title, className, headerRightArea, open }) {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <div className={`mt-4 `}>
      <div className="d-flex justify-content-between accordion-header">
        <p className="text-semibold">{title}</p>
        <div className="accordion-header-right d-flex p-col-2 justify-content-between">
          <div>{headerRightArea}</div>
          <div
            className="accordion-header-right-icon"
            onClick={() => {
              setIsOpen((active) => !active);
            }}
          >
            {isOpen ? <FaArrowAltCircleUp /> : <FaArrowAltCircleDown />}
          </div>
        </div>
      </div>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            // // key={isOpen}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "100%" }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                duration: 0.25,
                ease: "easeIn",
                type: "tween",
                stiffness: 100,
                damping: 15,
              },
            }}
            transition={{
              ease: "easeOut",
              type: "spring",
              stiffness: 100,
              damping: 15,
            }}
            className={`accordion-body ${className}`}
          >
            {children}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Accordion;
