import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { nameTitleOptions } from "../../../utilities/constants";
import LaInput from "../../../components/LaInput";
import LaAddress from "../../../components/LaAddress";
import OptionButton from "../../../components/LaVehicleReg/OptionButton";
import { useSelector } from "react-redux";
import { calculate_age } from "../../../utilities/commonFunctions";
// import ClawbackDate, {
//   formatClawBackDate,
// } from "../../rta/rtaFormComponents/ClawbackDate";

function ClaimantDetails({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const [currentAge, setCurrentAge] = useState(false);
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCat = user?.tblUsercategory?.categorycode;
  const statusCode = useSelector((state) => state.elSlice.el.status);
  // const clawbackDate =
  //   formatClawBackDate(
  //     useSelector((state) => state.elSlice.el?.clawbackDate)
  //   ) ?? null;
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  const ninumberOptions = ["Will be provided to the solicitor", "Minor"];

  if (
    userCat &&
    userCat === "4" &&
    (!statusCode ||
      (statusCode !== "1" &&
        statusCode !== "2" &&
        statusCode !== "3" &&
        statusCode !== "4"))
  ) {
    ninumberOptions.push("Solicitor obtained Number");
  }

  const onChangeOptionNinumber = (option) => {
    formik.setFieldValue("ninumber", option);
  };

  const addressProps = {
    ...props,
    keyNameCity: "city",
    keyNameRegion: "region",
    keyNamePostcode: "postalcode",
    keyNameAddress1: "address1",
    keyNameAddress2: "address2",
    keyNameAddress3: "address3",
    placeholder: "Enter Postcode",
    formik,
  };

  const handleMaxDate = (e, key) => {
    // max date should be yesterday
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (e.target.value > yesterday.toISOString().split("T")[0]) {
      formik.setFieldValue(key, yesterday.toISOString().split("T")[0]);
    } else {
      formik.setFieldValue(key, e.target.value);
    }
  };

  useEffect(() => {
    if (values?.dob) {
      let age = calculate_age(values.dob);
      setCurrentAge(age);
    }
  }, [values?.dob]);

  return (
    <>
      <div>
        <div className="Panel__Heading">Main Info</div>
        {/* <ClawbackDate {...props} clawbackDate={clawbackDate} /> */}
      </div>
      <div className="p-col-12">
        <label className="p-d-block">
          Name<span className="text-danger">*</span>
        </label>
        <Dropdown
          id="title"
          name="title"
          options={nameTitleOptions}
          optionLabel="name"
          placeholder="Select a Title"
          value={values.title}
          onChange={onChange}
          className={classNames({
            "p-invalid": isFormFieldValid("title"),
          })}
        />
        {getFormErrorMessage("title")}
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="firstname" placeholder="First Name" {...props} />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="middlename" placeholder="Middle Name" {...props} />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="lastname" placeholder="Surname" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Date of Birth<span className="text-danger">*</span>
        </label>
        <InputText
          type="date"
          id="dob"
          placeholder="Date of Birth"
          name="dob"
          value={values.dob}
          onChange={(e) => {
            handleMaxDate(e, "dob");
          }}
          className={classNames(
            {
              "p-invalid": isFormFieldValid("dob"),
            },
            "Width__50"
          )}
        />
        {getFormErrorMessage("dob")}
      </div>
      {formik.values.dob && (
        <div className="p-col-12">
          <span className="label-info">Age {currentAge}</span>
        </div>
      )}

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          National Insurance Number<span className="text-danger">*</span>
        </label>
        <div className="p-inputgroup la__inputGroup">
          <InputText
            id="ninumber"
            placeholder="National Insurance Number"
            name="ninumber"
            value={values.ninumber}
            onChange={onChange}
            className={classNames({
              "p-invalid": isFormFieldValid("ninumber"),
            })}
          />
          <div>
            <OptionButton
              options={ninumberOptions}
              onChangeOption={onChangeOptionNinumber}
            />
          </div>
        </div>
        {getFormErrorMessage("ninumber")}
      </div>

      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">
          Mobile<span className="text-danger">*</span>
        </label>
        <LaInput keyName="mobile" placeholder="Mobile" {...props} />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Email</label>
        <LaInput keyName="email" placeholder="Email" {...props} />
      </div>
      <LaAddress {...addressProps} />
    </>
  );
}

export default ClaimantDetails;
