import React, { useState } from "react";
import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { CSVLink } from "react-csv";
import { Chip } from "primereact/chip";
import { FaFileExcel } from "react-icons/fa";
import { handlePostRequest } from "../../services/PostTemplate";
import "./CaseList.css";

function CaseList() {
  const [rtacases, setrtacases] = useState([]);
  const [expandedRows, setExpandedRows] = useState();
  const [loading, setloading] = useState(false);
  const [value, onChange] = useState([new Date(), new Date()]);

  const formatDate = (input) => {
    var datePart = input.match(/\d+/g),
      year = datePart[0], // get only two digits
      month = datePart[1],
      day = datePart[2];
    return day + "-" + month + "-" + year;
  };

  const dateTemplate = (rowData) => {
    let allDate = rowData.accidentDate.split(" ");
    let thisDate = allDate[0].split("-");
    let newDate = [thisDate[2], thisDate[1], thisDate[0]].join("-");
    return newDate;
  };

  const searchCases = async () => {
    setloading(true);
    try {
      const data = {
        fromDate: formatDate(value[0].toISOString().slice(0, 10)),
        toDate: formatDate(value[1].toISOString().slice(0, 10)),
      };
      const res = await handlePostRequest(data, "rta/rtaCaseReport");
      // TODO: remove this random number when backend is ready
      res.data.forEach((element) => {
        element.rtacode = Math.floor(Math.random() * 1000000);
      });
      setrtacases(res.data);
    } catch (error) {
      console.log(error);
    }
    setloading(false);
  };

  const statusTemplate = (rowData) => {
    return (
      <center>
        <Chip label={rowData?.status} className="p-px-4 custom-chip" />
      </center>
    );
  };

  const headers = [
    { label: "Date Created", key: "dateCreated" },
    { label: "Date Modified", key: "dateModified" },
    { label: "Reference Number", key: "referenceNumber" },
    { label: "RTA Code", key: "rtacode" },
    { label: "Client Name", key: "clientName" },
    { label: "Introducer Company", key: "introducerCompany" },
    { label: "Introducer User", key: "introducerUser" },
    { label: "Solicitor Company", key: "solicitorCompany" },
    { label: "Solicitor User", key: "solicitorUser" },
    { label: "Status", key: "status" },
    { label: "Reject Reason", key: "rejectReason" }, // Changed from "Rejected Reason" to match the key in your original array
    { label: "Current Task", key: "currentTask" },
    { label: "Submitted On", key: "submittedOn" },
    { label: "Invoice Date", key: "invoiceDate" },
    { label: "Accident Date", key: "accidentDate" },
    { label: "CFA", key: "cfa" },
    { label: "Proof of ID", key: "proofOfId" },
    { label: "Proof of Address", key: "proofOfAddress" },
    { label: "AML", key: "aml" },
    { label: "National Insurance Number", key: "nationalInsuranceNumber" },
    { label: "Date of Birth", key: "dateOfBirth" },
    { label: "Postal Code", key: "postalcode" },
    { label: "Injuries", key: "injuries" },
  ];

  const rowExpansionTemplate = (data) => {
    return (
      <div className="expanded-card" style={{ display: "flex", width: "100%" }}>
        <div className="box" style={{ width: "33%", padding: "1em" }}>
          <strong>Introducer : </strong>
          <p>{data?.introducerCompany || ""}</p>
          <strong> AML: </strong>
          <p>{data?.aml === "Y" ? "Yes" : "No"}</p>
          <strong>CFA : </strong>
          <p>{data?.cfa === "Y" ? "Yes" : "No"}</p>
        </div>
        <div className="box" style={{ width: "33%", padding: "1em" }}>
          <strong>Solicitor : </strong>
          <p>{data?.solicitorCompany || ""}</p>
          <strong>Submitted On : </strong>
          <p>{data?.submittedOn}</p>
          <strong>DOB : </strong>
          <p>{data?.dateOfBirth}</p>
        </div>
        <div className="box" style={{ width: "33%", padding: "1em" }}>
          <strong>Proof of ID : </strong>
          <p>{data?.proofOfId === "Y" ? "Yes" : "No"}</p>
          <strong>Proof of Address : </strong>
          <p>{data?.proofOfAddress === "Y" ? "Yes" : "No"}</p>
          <strong>Current Task : </strong>
          <p>{data?.currentTask || ""}</p>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="expanded-card p-d-flex p-jc-between">
        <div className="p-d-flex ">
          <DateTimeRangePicker
            autoFocus={true}
            format="y-MM-dd"
            onChange={onChange}
            value={value}
          />
          &nbsp;
          <Button
            disabled={loading}
            label={loading ? "Searching..." : "Search"}
            onClick={searchCases}
          />
        </div>
        <CSVLink data={rtacases} headers={headers}>
          <Button disabled={loading}>
            <FaFileExcel />
            &nbsp;Export
          </Button>
        </CSVLink>
      </div>
      <div className="card p-datatable-sm mt-4">
        <DataTable
          value={rtacases}
          scrollable
          paginator
          rows={10}
          className="p-datatable-customers"
          emptyMessage="No data found."
          expandedRows={expandedRows}
          dataKey="rtacode"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ maxWidth: "60px" }}></Column>
          <Column field="clientName" header="Client Name" sortable />
          <Column field="introducerCompany" header="Introducer" sortable />
          <Column field="solicitorCompany" header="Solicitor" sortable />
          <Column
            field="accidentDate"
            header="Accident Date"
            sortable
            body={dateTemplate}
          />
          <Column field="status" header="Status" body={statusTemplate} />
        </DataTable>
      </div>
    </>
  );
}
export default CaseList;
