import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "primereact/button";
import { useFormik } from "formik";
import * as Yup from "yup";
import LaInput from "../../components/LaInput";
import styled from "styled-components";
import Signoff from "./Signoff";

function DynamicFields({ campaignCode, campaignName, encryptedUrl, url }) {
  const formData = useSelector(
    (state) => state?.eSignSlice?.eFields?.dynamicFields
  );
  const [formValues, setFormValues] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSignOff, setShowSignOff] = useState(false);

  const initialValues = {};
  const validationSchema = {};

  // Filter out fields with the key containing the word "signature"
  const filteredFormData = formData?.filter(
    (field) => !field.key.toLowerCase().includes("signature")
  );

  // Update the form initialization and rendering logic to use filteredFormData
  filteredFormData?.forEach((field) => {
    initialValues[field.key] = "";
    if (field.isRequired === "true" || field.isRequired === true) {
      validationSchema[field.key] = Yup.string().required("required");
    }
  });

  const formik = useFormik({
    initialValues,
    validationSchema: Yup.object().shape(validationSchema),
    onSubmit: (values) => {
      setIsSubmitting(true);
      // filter out the fields with the key containing the word "signature"
      const filteredValues = Object.keys(values).reduce((acc, key) => {
        if (!key.toLowerCase().includes("signature")) {
          acc[key] = values[key];
        }
        return acc;
      }, {});
      setFormValues(filteredValues);
      setShowSignOff(true);
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) =>
    isFormFieldValid(name) && (
      <small className="label__error p-d-block">{formik.errors[name]}</small>
    );

  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
    disabled: isSubmitting, // Disable form fields when submitting
  };

  const handleEditForm = () => {
    setIsSubmitting(false);
    setShowSignOff(false);
  };

  if (!formData || formData.length === 0) {
    return null;
  }

  return (
    <StyledBox>
      <form onSubmit={formik.handleSubmit}>
        <h3
          style={{ color: "#0156B3" }}
          className="mt-2 d-flex justify-content-center"
        >
          Please Fill the Form
        </h3>
        <div className="p-fluid p-formgrid p-grid p-pr-4">
          {filteredFormData.map((field) => (
            <div key={field.key} className="p-mt-2 p-col-12 p-md-6 p-sm-12">
              <label className="p-d-block">
                {field.label.replace(/_/g, " ")}
                {(field.isRequired === true || field.isRequired === "true") && (
                  <span className="text-danger">*</span>
                )}
              </label>
              <LaInput
                keyName={field.key}
                placeholder={field.label}
                type={field.type || "text"}
                {...props}
              />
            </div>
          ))}
        </div>
        <div className="p-d-flex p-jc-center p-mt-4">
          {isSubmitting && (
            <Button
              type="button"
              className="btn btn-secondary"
              label="Edit Form Again"
              onClick={handleEditForm}
            />
          )}
          {!isSubmitting && (
            <Button type="submit" className="btn btn-primary" label="Submit" />
          )}
        </div>
        {showSignOff && (
          <div className="p-d-flex p-jc-center p-mt-2">
            <Signoff
              campaignCode={campaignCode}
              campaignName={campaignName}
              formValues={formValues}
              encryptedUrl={encryptedUrl}
              url={url}
            />
          </div>
        )}
      </form>
    </StyledBox>
  );
}

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  max-width: 100%;
  overflow-x: hidden;
`;

export default DynamicFields;
