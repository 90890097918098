import validator from "validator";
const mobileNumberRegex = /^\+?\d{11,}$/;
const niNumberRegex = /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1,}?\s*$/;

const isEmpty = (value) =>
  value === undefined ||
  value === null ||
  (typeof value === "object" && Object.keys(value).length === 0) ||
  (typeof value === "string" && value.trim().length === 0);

const hdrValidation = async (post, isJointTenancy) => {
  let errors = {};
  let cfname = !isEmpty(post?.cfname) ? post?.cfname.toString() : "";
  let cdob = !isEmpty(post?.cdob) ? post?.cdob.toString() : "";
  let cmobileno = !isEmpty(post?.cmobileno) ? post?.cmobileno.toString() : "";
  // let cmname = !isEmpty(post?.cmname) ? post?.cmname.toString() : "";
  let csname = !isEmpty(post?.csname) ? post?.csname.toString() : "";
  let cemail = !isEmpty(post?.cemail) ? post?.cemail.toString() : "";
  let clandline = !isEmpty(post?.clandline) ? post?.clandline.toString() : "";
  let cninumber = !isEmpty(post?.cninumber) ? post?.cninumber.toString() : "";
  let ctitle = !isEmpty(post?.ctitle) ? post?.ctitle.toString() : "";
  let defectFirstreportedon = !isEmpty(post?.defectFirstreportedon)
    ? post?.defectFirstreportedon.toString()
    : "";

  let arrearsAmount = !isEmpty(post?.arrearsAmount) ? post?.arrearsAmount : "";

  if (validator.isEmpty(arrearsAmount)) {
    errors.arrearsAmount = "required.";
  }

  if (isJointTenancy === "Y") {
    let tfname = !isEmpty(post?.tfname) ? post?.tfname.toString() : "";
    let tmname = !isEmpty(post?.tmname) ? post?.tmname.toString() : "";
    let tdob = !isEmpty(post?.tdob) ? post?.tdob.toString() : "";
    let tmobileno = !isEmpty(post?.tmobileno) ? post?.tmobileno.toString() : "";
    let tlandline = !isEmpty(post?.tlandline) ? post?.tlandline.toString() : "";
    let taddress1 = !isEmpty(post?.taddress1) ? post?.taddress1.toString() : "";
    let tninumber = !isEmpty(post?.tninumber) ? post?.tninumber.toString() : "";

    if (validator.isEmpty(tfname)) {
      errors.tfname = "required.";
    }

    if (validator.isEmpty(tmname)) {
      errors.tmname = "required.";
    }
    if (validator.isEmpty(tdob)) {
      errors.tdob = "required.";
    }
    if (validator.isEmpty(tmobileno)) {
      errors.tmobileno = "required.";
    } else if (!mobileNumberRegex.test(tmobileno)) {
      errors.tmobileno = "UK number.";
    }
    if (tlandline && !mobileNumberRegex.test(tlandline)) {
      errors.tlandline = "UK number.";
    }
    if (validator.isEmpty(taddress1)) {
      errors.taddress1 = "required.";
    }
    if (
      !validator.isEmpty(tninumber) &&
      !validator.matches(tninumber, niNumberRegex)
    ) {
      errors.tninumber = "NI number is not valid";
    }
  }

  if (validator.isEmpty(defectFirstreportedon)) {
    errors.defectFirstreportedon = "required.";
  }

  let llName = !isEmpty(post?.llName) ? post?.llName.toString() : "";
  let defectLastreportedon = !isEmpty(post?.defectLastreportedon)
    ? post?.defectLastreportedon.toString()
    : "";

  let startDate = !isEmpty(post?.startDate) ? post?.startDate.toString() : "";
  let propertyType = !isEmpty(post?.propertyType)
    ? post?.propertyType.toString()
    : "";

  let healthAffected = !isEmpty(post?.healthAffected)
    ? post?.healthAffected.toString()
    : "";

  if (validator.isEmpty(cfname)) {
    errors.cfname = "required.";
  }
  if (validator.isEmpty(ctitle)) {
    errors.ctitle = "required.";
  }
  if (validator.isEmpty(cdob)) {
    errors.cdob = "required.";
  }
  if (validator.isEmpty(cmobileno)) {
    errors.cmobileno = "required.";
  } else if (!mobileNumberRegex.test(cmobileno)) {
    errors.cmobileno = "UK number.";
  }
  // cmobileno min 11 max 13
  if (cmobileno.length < 11 || cmobileno.length > 13) {
    errors.cmobileno = "UK number.";
  }

  if (validator.isEmpty(csname)) {
    errors.csname = "required.";
  }
  if (clandline && !mobileNumberRegex.test(clandline)) {
    errors.clandline = "UK number.";
  }

  // validate email
  if (cemail && !validator.isEmail(cemail)) {
    errors.cemail = "Email is not valid";
  }
  if (
    !validator.isEmpty(cninumber) &&
    !validator.matches(cninumber, niNumberRegex)
  ) {
    errors.cninumber = "NI number is not valid";
  }
  if (validator.isEmpty(llName)) {
    errors.llName = "required.";
  }
  if (validator.isEmpty(defectLastreportedon)) {
    errors.defectLastreportedon = "required.";
  }

  if (validator.isEmpty(startDate)) {
    errors.startDate = "required.";
  }
  if (validator.isEmpty(propertyType)) {
    errors.propertyType = "required.";
  }

  if (validator.isEmpty(healthAffected)) {
    errors.healthAffected = "required.";
  }

  return {
    isValid: isEmpty(errors),
    errors,
  };
};

const passengerValidation = async (post) => {
  let errors = {};

  let firstname = !isEmpty(post?.firstname) ? post?.firstname.toString() : "";
  let lastname = !isEmpty(post?.lastname) ? post?.lastname.toString() : "";
  let dob = !isEmpty(post?.dob) ? post?.dob.toString() : "";
  let ninumber = !isEmpty(post?.ninumber) ? post?.ninumber.toString() : "";
  let mobile = !isEmpty(post?.mobile) ? post?.mobile.toString() : "";
  let postalcode = !isEmpty(post?.postalcode)
    ? post?.postalcode.toString()
    : "";

  if (validator.isEmpty(firstname)) {
    errors.firstname = "First name is required";
  } else if (!validator.isLength(firstname, { min: 2, max: 50 })) {
    errors.firstname = "First name must be between 2 to 50 characters";
  }

  if (validator.isEmpty(lastname)) {
    errors.lastname = "Last name is required";
  } else if (!validator.isLength(lastname, { min: 2, max: 50 })) {
    errors.lastname = "Last name must be between 2 to 50 characters";
  }

  if (validator.isEmpty(dob)) {
    errors.dob = "Date of birth is required";
  }

  if (validator.isEmpty(ninumber)) {
    errors.ninumber = "NI number is required";
  } else if (!validator.matches(ninumber, niNumberRegex)) {
    errors.ninumber = "NI number is not valid";
  }

  if (validator.isEmpty(mobile)) {
    errors.mobile = "Mobile number is required";
  } else if (!mobileNumberRegex.test(mobile)) {
    errors.tmobileno = "UK number.";
  }

  if (validator.isEmpty(postalcode)) {
    errors.postalcode = "Address is required";
  }

  return {
    isValid: isEmpty(errors),
    errors,
  };
};

const minorValidation = async (post) => {
  let errors = {};
  let gfirstname = !isEmpty(post?.gfirstname)
    ? post?.gfirstname.toString()
    : "";
  let glastname = !isEmpty(post?.glastname) ? post?.glastname.toString() : "";
  let gdob = !isEmpty(post?.gdob) ? post?.gdob.toString() : "";
  let gpostalcode = !isEmpty(post?.gpostalcode)
    ? post?.gpostalcode.toString()
    : "";

  if (validator.isEmpty(gfirstname)) {
    errors.gfirstname = "First name is required";
  } else if (!validator.isLength(gfirstname, { min: 2, max: 50 })) {
    errors.gfirstname = "First name must be between 2 to 50 characters";
  }

  if (validator.isEmpty(glastname)) {
    errors.glastname = "Last name is required";
  } else if (!validator.isLength(glastname, { min: 2, max: 50 })) {
    errors.glastname = "Last name must be between 2 to 50 characters";
  }

  if (validator.isEmpty(gdob)) {
    errors.gdob = "Date of birth is required";
  }

  if (validator.isEmpty(gpostalcode)) {
    errors.gpostalcode = "Address is required";
  }

  return {
    isValid: isEmpty(errors),
    errors,
  };
};

export { hdrValidation, passengerValidation, minorValidation };
