import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";
import { handleLogout } from "../App";

export const isTokenExpired = async (token) => {
  console.log("Checking token expiration...");

  const tokenData = JSON.parse(atob(token.split(".")[1]));
  const expirationTimeUTC = tokenData.exp * 1000;

  // Get the current time in UTC
  const currentTimeUTC = Date.now();

  // Define the server's time zone offset (UK is typically GMT or GMT+1)
  const serverTimeZoneOffset = 0 * 60 * 60 * 1000; // GMT (no offset)
  // You may need to adjust this offset based on the actual server timezone

  // Adjust the current time to the server's timezone
  const currentTimeServerTZ = currentTimeUTC + serverTimeZoneOffset;

  if (currentTimeServerTZ >= expirationTimeUTC) {
    console.log("Token has expired, refreshing...");
    await refreshToken(); // Refresh the token if it's expired
  } else {
    console.log("Token is still valid.");
  }
};

export const login = async (data) => {
  let res;
  await axios({
    method: "post",
    url: `${baseURL}OtpLoginVerify`,
    data: data,
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      toast.success(response.data.messages);
      res = response.data.data;
    })
    .catch((err) => {
      toast.warn(err?.response?.data?.messages || "Something went wrong!!");
      res = false;
    });
  return res;
};

export const refreshToken = async () => {
  try {
    const token = localStorage.getItem("token");
    if (!token) {
      // handleLogout(); // Handle logout if token is not present
      // return null;
    }
    const response = await axios({
      method: "get",
      url: `${baseURL}refreshToken`,
      data: {},
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
    });

    // Set the new token in local storage
    localStorage.setItem("token", response?.data?.data);

    return response;
  } catch (err) {
    // handleLogout();
    toast.warn("Session expired. Please login again");
    return null;
  }
};

export const updatePassword = async (password) => {
  let res;
  await axios({
    method: "post",
    url: `${baseURL}userManagement/changePassword`,
    data: { password },
    headers: {
      "Content-Type": "application/json",
      Authorization: localStorage.getItem("token"),
    },
  })
    .then((response) => {
      toast.success(response.data.data);
      res = response?.data?.data;
    })
    .catch((err) => {
      toast.warn(err?.response?.data?.messages || "Something went wrong");
      res = false;
    });
  return res;
};
