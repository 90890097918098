import React from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import LaInput from "../../components/LaInput";
import LaDropdown from "../../components/LaDropdown";
import LaCheckbox from "../../components/LaCheckbox";
import { handlePostRequest } from "../../services/PostTemplate";
import Dropzone from "../../components/UploadModal/Dropzone";
import { RadioButton } from "primereact/radiobutton";

function AddCompanyCFA() {
  const initialValues = {
    companyCode: "",
    fileName: "",
    countryType: "",
    ageNature: "",
    compaignCode: "",
    JointTenency: "N",
    bike: false,
    multipartFiles: "",
    driverpassenger: "",
    hireservice: "",
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const formik = useFormik({
    initialValues: initialValues,
    onSubmit: async (data) => {
      Object.keys(data).forEach((key) => {
        if (data[key] === true || data[key] === "true") {
          data[key] = "Y";
        }
        if (data[key] === false) {
          data[key] = "N";
        }
        if (data[key] === "") {
          data[key] = null;
        }
      });
      try {
        // form data
        const formData = new FormData();
        formData.append("companyCode", data.companyCode);
        formData.append("fileName", data.fileName);
        formData.append("countryType", data.countryType);
        formData.append("ageNature", data.ageNature);
        formData.append("compaignCode", data.compaignCode);
        formData.append("JointTenency", data.JointTenency);
        formData.append("bike", data.bike === "Y" ? "B" : "N");
        formData.append("multipartFiles", data.multipartFiles);
        formData.append("driverpassenger", data.driverpassenger);
        // remove keys which are null
        for (var pair of formData.entries()) {
          if (pair[1] === null) {
            formData.delete(pair[0]);
          }
        }
        await handlePostRequest(formData, "userManagement/addcompanyCfa");
      } catch (e) {
        console.log(e);
      }
    },
  });

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  const handleDropZoneFiles = async (file) => {
    formik.setFieldValue("multipartFiles", file[0]);
    console.log(file, "multipartFiles");
  };

  return (
    <div>
      <div align="center">
        <h3>Company CFA</h3>
      </div>

      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-12 p-mt-2">
            <LaDropdown
              keyName="companyCode"
              optionLabel="name"
              placeholder="Company Code"
              url={"/lovCompany"}
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <LaInput keyName="fileName" placeholder="File Name" {...props} />
          </div>
          <div className="p-col-12 p-mt-2">
            <LaInput
              keyName="countryType"
              placeholder="Country Type"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <LaInput keyName="ageNature" placeholder="Age Nature" {...props} />
          </div>
          <div className="p-col-12 p-mt-2">
            <LaInput
              keyName="hireservice"
              placeholder="Hire Service"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <LaDropdown
              keyName="compaignCode"
              optionLabel="name"
              placeholder="Compaign Code"
              url={"/lovCompaign"}
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2" style={{ display: "flex" }}>
            <LaCheckbox
              keyName="JointTenency"
              {...props}
              style={{ display: "flex" }}
            />
            <label className="p-d-block" style={{ marginLeft: "10px" }}>
              Joint Tenency
            </label>
          </div>
          <div className="p-col-12 p-mt-2" style={{ display: "flex" }}>
            <LaCheckbox keyName="bike" {...props} style={{ display: "flex" }} />
            <label className="p-d-block" style={{ marginLeft: "10px" }}>
              Bike
            </label>
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Claimant:</label>
            <div className="Radio__Btn">
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="driverpassenger"
                  name="driverpassenger"
                  value="D"
                  onChange={props.onChange}
                  checked={props.values.driverpassenger === "D"}
                />
                <label
                  htmlFor="driverpassenger"
                  style={{ marginRight: "10px" }}
                >
                  Driver
                </label>
              </div>
              <div className="p-field-radiobutton">
                <RadioButton
                  inputId="driverpassenger"
                  name="driverpassenger"
                  value="P"
                  onChange={props.onChange}
                  checked={props.values.driverpassenger === "P"}
                />
                <label htmlFor="driverpassenger">Passenger</label>
              </div>
            </div>
            {getFormErrorMessage("driverpassenger")}
          </div>
          <div className="p-col-12 p-mt-2">
            <Dropzone handleDropZoneFiles={handleDropZoneFiles} acceptPdfOnly />
            {formik.values.multipartFiles ? (
              <div>
                <h5>Files</h5>
                <ul>
                  <li>
                    {formik.values.multipartFiles?.name} -{" "}
                    {formik.values.multipartFiles?.size} bytes
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>

        <div align="center" className="p-col-12 p-md-12">
          <Button type="submit" className="btn btn-primary mr-2">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default AddCompanyCFA;
