import React from "react";
import classNames from "classnames";
import { InputNumber } from "primereact/inputnumber";

function LaInputNumber({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  ...rest
}) {
  return (
    <>
      <InputNumber
        id={keyName}
        name={keyName}
        value={values[keyName]}
        onValueChange={onChange}
        {...rest}
        className={classNames({
          "p-invalid": isFormFieldValid(keyName.toString()),
        })}
      />
      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default LaInputNumber;
