import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FiChevronDown, FiChevronUp, FiEye, FiEyeOff } from "react-icons/fi";
import { useCampaignDuplicateCounts } from "./useCampaignDuplicateCounts";
import { useUserCategory } from "../../useUserCategory";

function CampaignDuplicateCounts({ viewDetailLink, api, code, caseId }) {
  const navigate = useNavigate();
  const duplicateCount = useCampaignDuplicateCounts(api, code);

  const userCategoryName = useUserCategory();

  const [isExpanded, setIsExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const displayedDuplicates = isExpanded
    ? duplicateCount
    : duplicateCount?.slice(0, 10);

  if (duplicateCount === null || userCategoryName !== "LegalInternal") {
    return null;
  }

  const handleRowClick = (code) => {
    navigate(`/${viewDetailLink}/${code}`);
  };

  return (
    <div
      style={{
        width: "80%",
        margin: "auto",
        maxHeight: "300px",
        overflowY: "auto",
      }}
      className="mt-4 mb-4"
    >
      <ViewButton onClick={() => setIsVisible(!isVisible)}>
        {isVisible ? "Hide Duplicates" : "Show Duplicates"}
        {isVisible ? <FiEyeOff /> : <FiEye />}
      </ViewButton>
      {isVisible && (
        <>
          <StyledTable>
            <thead>
              <tr>
                <th>Duplicate Type</th>
                <th>Full Name</th>
                <th>Case Number</th>
              </tr>
            </thead>
            <tbody>
              {displayedDuplicates.map((rowData) => (
                <StyledTableRow
                  onClick={() => handleRowClick(rowData?.[caseId])}
                  key={
                    rowData.caseNumber +
                    rowData?.[caseId] +
                    rowData?.fullName +
                    rowData?.duplicateType
                  }
                >
                  <td>{rowData?.duplicateType}</td>
                  <td>{rowData?.fullName}</td>
                  <td>
                    <StyledLink>{rowData?.caseNumber}</StyledLink>
                  </td>
                </StyledTableRow>
              ))}
            </tbody>
          </StyledTable>
          {duplicateCount.length > 10 && (
            <ExpandButton onClick={() => setIsExpanded(!isExpanded)}>
              {isExpanded ? "Show less" : "Show more"}
              {isExpanded ? <FiChevronUp /> : <FiChevronDown />}
            </ExpandButton>
          )}
        </>
      )}
    </div>
  );
}

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th {
    text-align: left;
    padding: 10px;
  }

  td {
    text-align: left;
  }
`;

const StyledTableRow = styled.tr`
  &:hover {
    background-color: #f5f5f5;
    cursor: pointer;
  }
`;

const StyledLink = styled.a`
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

const ExpandButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;

  svg {
    margin-left: 5px;
  }
`;

const ViewButton = styled.button`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: #888;
  cursor: pointer;
  margin-bottom: 10px;

  svg {
    margin-left: 5px;
  }
`;

export default CampaignDuplicateCounts;
