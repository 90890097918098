import React from "react";
import { Dialog } from "primereact/dialog";
import AddCompanyProfile from "./AddCompanyProfile";

function AddProfileDialog({ showModal, onCloseModal }) {
  return (
    <Dialog
      header="Add Company Profile"
      position="top"
      draggable={false}
      visible={showModal}
      style={{ width: "90vw" }}
      onHide={onCloseModal}
    >
      <div className="mt-4">
        <AddCompanyProfile onCloseModal={onCloseModal} />
      </div>
    </Dialog>
  );
}

export default AddProfileDialog;
