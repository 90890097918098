import {
  GETRTASUCCESS,
  GETRTAERROR,
  GETRTASTATUSSUCCESS,
  GETRTASTATUSERROR,
  GETRTALISTSUCCESS,
  GETRTALISTERROR,
  CLEARRTADATA,
} from "../slices/rtaSlice";
import { SETLOADING } from "../slices/loadingSlice";
import { handleGetRequest } from "../../services/GetTemplate";
import { toast } from "react-toastify";
import { handlePostRequest } from "../../services/PostTemplate";

export const getList = (statusUrl) => async (dispatch) => {
  try {
    dispatch(SETLOADING(true));
    const res = await handleGetRequest(statusUrl);
    dispatch(GETRTALISTSUCCESS(res?.data));
    return res;
  } catch (error) {
    dispatch(GETRTALISTERROR(error?.response?.data?.data));
    dispatch(SETLOADING(false));
  } finally {
    dispatch(SETLOADING(false));
  }
};

export const getrtaDetails = (id) => async (dispatch) => {
  dispatch(SETLOADING(true));
  try {
    const res = await handleGetRequest(`rta/getAuthRtaCase/${id}`);
    if (res) {
      dispatch(GETRTASUCCESS(res?.data));
      dispatch(SETLOADING(false));
    }
  } catch (error) {
    toast.error(error.response.data.messages);
    dispatch(GETRTAERROR(error?.response?.data?.data));
    dispatch(SETLOADING(false));
  }
};
export const getStatus = () => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest("/rta/getAllRtaStatusCounts");
  if (res) {
    dispatch(GETRTASTATUSSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETRTASTATUSERROR(res));
    dispatch(SETLOADING(false));
  }
};

export const clearRtaData = () => async (dispatch) => {
  dispatch(CLEARRTADATA());
};

export const getListByFilter = (data, url) => async (dispatch) => {
  try {
    dispatch(SETLOADING(true));
    const res = await handlePostRequest(data, url);
    dispatch(GETRTALISTSUCCESS(res?.data));
    return res;
  } catch (error) {
    dispatch(GETRTALISTERROR(error?.response?.data?.data));
    dispatch(SETLOADING(false));
  } finally {
    dispatch(SETLOADING(false));
  }
};
