import React from "react";
import { useParams } from "react-router-dom";
import Navigation from "../Navigation";
import EditCompanyProfile from "./EditCompanyProfile";

function ViewCompanyProfile() {
  const { id } = useParams();
  return (
    <>
      <Navigation activeItem="profile" id={id} />
      <EditCompanyProfile id={id} />
    </>
  );
}

export default ViewCompanyProfile;
