/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { ImUpload2 } from "react-icons/im";
import FormMainBody from "../FormLayout/FormMainBody/index";
import UploadModal from "../UploadModal";
import Messages from "../Messages";
import { useDispatch, useSelector } from "react-redux";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Notes from "../Notes";
import { FormWrapper } from "../FormLayout/FormHeader";
import { LargeTab, LargeTabWrapper } from "../FormLayout/FormHeader/LargeTab";
import Accordion from "../Accordion";
import Multimedia from "../Multimedia";
import NotesLegalInternal from "../Notes/NotesLegalInternal";
import Logs from "../Logs";
import { campaignConfig } from "./campaignConfig";

function ViewCampaignForm({
  campaignDocuments,
  campaignCode,
  CampaignForm,
  messages,
  internalMessages,
  campaignName,
  onSave,
  disabled,
  setDisabled,
  onCancel,
  getCampaignDetails,
  editFlag,
}) {
  const [active, setActive] = useState(0);
  const [showUploadModal, setshowUploadModal] = useState(false);
  const dispatch = useDispatch();
  const signingSectionDocuments = campaignDocuments?.filter(
    (campaignDocument) => campaignDocument.tblTask !== null
  );
  const documents = campaignDocuments?.filter(
    (campaignDocument) => campaignDocument.tblTask === null
  );
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCat = user?.tblUsercategory?.categorycode;

  const {
    getApiForMessages,
    postApiForMessages,
    getApiForLogs,
    addFilesService,
    codeNameForUpload,
    deleteApi,
    documentType,
    getNotesService,
    addNotesService,
    caseTypeForNotes,
    campaignNameForMessages,
  } = campaignConfig[campaignName];

  const onDeleteDocument = () => {
    getDetails();
  };

  const uploadAction = (
    <>
      {userCat === "4" || userCat === "1" ? (
        <div
          className="text-semibold pointer"
          onClick={() => setshowUploadModal(true)}
        >
          <ImUpload2 /> Upload
        </div>
      ) : null}
    </>
  );

  const getDetails = async () => {
    dispatch(getCampaignDetails(campaignCode));
  };

  useEffect(() => {
    getDetails();
  }, [campaignCode]);

  const handlePrintForm = () => {
    const input = document.getElementById("printable");
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(`${campaignName}${campaignCode}.pdf`);
    });
  };

  return (
    <FormWrapper>
      <div id="printable">
        <LargeTabWrapper>
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(0)}
            active={active === 0}
            title="Main"
          />
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(1)}
            active={active === 1}
            title={`Images & Videos (${campaignDocuments?.length || 0})`}
          />
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(2)}
            active={active === 2}
            title="Notes"
          />
          {userCat === "4" ? (
            <>
              <LargeTab
                Icon="Icon"
                onClick={() => setActive(3)}
                active={active === 3}
                title="Messages"
              />
              <LargeTab
                Icon="Icon"
                onClick={() => setActive(4)}
                active={active === 4}
                title="Logs"
              />
            </>
          ) : null}
        </LargeTabWrapper>
        {active === 0 && (
          <FormMainBody
            isEdit={!disabled}
            setIsEdit={(edit) => {
              setDisabled(!edit);
            }}
            disabledForm={String(disabled)}
            title="Case Info"
            onSave={onSave}
            onCancel={onCancel}
            editFlag={editFlag}
          >
            {CampaignForm}
            <center className="mt-4">
              <button
                onClick={handlePrintForm}
                id="print-btn"
                className="btn btn-block btn-primary"
              >
                Print Claimant Form
              </button>
            </center>
          </FormMainBody>
        )}
        {active === 1 && (
          <>
            <Accordion title="Images" headerRightArea={uploadAction} open>
              <Multimedia
                documents={documents}
                onDeleteDocument={onDeleteDocument}
                deleteApi={deleteApi}
                documentType={documentType}
              />
            </Accordion>
            <Accordion title="Document Signing Section" open>
              <Multimedia
                documents={signingSectionDocuments}
                onDeleteDocument={onDeleteDocument}
                deleteApi={deleteApi}
                documentType={documentType}
              />
            </Accordion>
          </>
        )}
        <UploadModal
          show={showUploadModal}
          hide={(show) => setshowUploadModal(show)}
          addFilesService={addFilesService}
          codeName={codeNameForUpload}
          codeValue={campaignCode}
          returnSubmit={getDetails}
        />
        {active === 2 && (
          <Accordion title="Notes" open>
            <NotesLegalInternal internalMessages={internalMessages} />
            <Notes
              getNotesService={getNotesService}
              addNotesService={addNotesService}
              caseType={caseTypeForNotes}
              code={campaignCode}
              messages={messages}
            />
          </Accordion>
        )}
        {active === 3 && (
          <Accordion title="Messages" open>
            <Messages
              code={campaignCode}
              url={getApiForMessages}
              posturl={postApiForMessages}
              compainName={campaignNameForMessages}
            />
          </Accordion>
        )}
        {active === 4 && (
          <Accordion title="Logs" open>
            <Logs api={getApiForLogs} caseCode={campaignCode} />
          </Accordion>
        )}
      </div>
    </FormWrapper>
  );
}

export default ViewCampaignForm;
