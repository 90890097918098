import React from "react";
import "../FormLayout.css";

function FormWrapper(props) {
  return <div className="form-section">{props.children}</div>;
}

function MainWrapper(props) {
  return <div className="form-sideBox_section">{props.children}</div>;
}

export { FormWrapper, MainWrapper };
