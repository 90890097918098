import React from "react";
import SideBox from "../FormLayout/SideBoxSection";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function InvoiceCaseDetails({
  introducerInvoiceDate,
  solicitorInvoiceDate,
  introducerInvoiceHeadId = null,
  solicitorInvoiceHeadId = null,
}) {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const navigate = useNavigate();

  if (
    user?.tblUsercategory?.categorycode !== "4" ||
    introducerInvoiceHeadId === null ||
    solicitorInvoiceHeadId === null
  ) {
    return null;
  }

  const handleView = (invoiceheadid) => {
    const path = `../invoiceDetails?invoicecode=${invoiceheadid}`;
    navigate(path);
  };

  return (
    <SideBox title="Invoice Details" className="mt-4">
      <span
        className="pointer"
        style={{ color: "#0000EE" }}
        onClick={() => handleView(introducerInvoiceHeadId)}
      >
        <small>{introducerInvoiceDate}</small>
        <br />
        <strong>Introducer Invoice Date</strong>
      </span>
      <br></br>
      <hr />

      <div>
        <span
          className="pointer"
          style={{ color: "#0000EE" }}
          onClick={() => handleView(solicitorInvoiceHeadId)}
        >
          <small>{solicitorInvoiceDate}</small>
          <br />
          <strong>Solicitor Invoice Date</strong>
        </span>
        <br />
      </div>
    </SideBox>
  );
}

export default InvoiceCaseDetails;
