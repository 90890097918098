import { Dialog } from "primereact/dialog";
import React from "react";
import VehicleDetails from "./VehicleDetails";

function VehicleDetailsDialog({ showModal, onCloseModal, responseData }) {
  return (
    <Dialog
      header="Vehicle Details"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
    >
      <VehicleDetails data={responseData} />
    </Dialog>
  );
}

export default VehicleDetailsDialog;
