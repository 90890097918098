import styled from "styled-components";
import { AiFillCar } from "react-icons/ai";
import React, { useState, useEffect } from "react";

const Wrapper = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
`;

const Title = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHead = styled.thead`
  background-color: #f2f2f2;
`;

const TableRow = styled.tr`
  &:not(:first-child) {
    border-top: 1px solid #ddd;
  }
`;

const TableCell = styled.td`
  padding: 0.5rem 1rem;
  text-align: left;
`;

const TableBody = styled.tbody`
  background-color: #fff;
`;

const VehicleDetails = ({ data }) => {
  const [vehicleData, setVehicleData] = useState({});

  useEffect(() => {
    const {
      VehicleRegistration: {
        Make,
        Model,
        YearOfManufacture,
        Vrm,
        DateFirstRegistered,
        Colour,
        EngineCapacity,
      },
      SmmtDetails: {
        FuelType,
        Range,
        MarketSectorCode,
        CountryOfOrigin,
        ModelCode,
      },
    } = data.Response.DataItems;

    setVehicleData({
      Make,
      Model,
      Year: YearOfManufacture,
      Registration: Vrm,
      "First Registered": DateFirstRegistered,
      Colour,
      "Fuel Type": FuelType,
      "Engine Capacity (cc)": EngineCapacity,
      "SMMT Range": Range,
      "Market Sector Code": MarketSectorCode,
      "Country of Origin": CountryOfOrigin,
      "SMMT Model Code": ModelCode,
    });
  }, [data]);

  return (
    <Wrapper>
      <Title>
        <AiFillCar /> Vehicle Details
      </Title>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Detail</TableCell>
            <TableCell>Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(vehicleData).map(([key, value]) => (
            <TableRow key={key}>
              <TableCell>{key}</TableCell>
              <TableCell>{value}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default VehicleDetails;
