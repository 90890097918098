import React from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import styled from "styled-components";
import { FaFileDownload } from "react-icons/fa";

function DownloadAllCompanies() {
  const handleFetch = async () => {
    try {
      const response = await handleGetRequest("userManagement/getCompanyCsv");
      const formattedData = formatData(response.data);
      downloadExcel(formattedData);
    } catch (e) {
      console.log(e);
    }
  };

  const formatData = (data) => {
    const formattedData = [];

    data.forEach((company) => {
      const {
        name,
        introducerCategory,
        tag,
        addressline1,
        addressline2,
        city,
        region,
        postcode,
        email,
        contactperson,
        phone,
        phone2,
        billtoemail,
        billtoname,
        accountemail,
        secondaryaccountemail,
        companystatus,
        vat,
        tblCompanyjobs,
      } = company;

      const companyDetails = {
        Name: name,
        "Introducer Category": introducerCategory,
        Tag: tag,
        "Address Line 1": addressline1,
        "Address Line 2": addressline2,
        "Town/City": city,
        Region: region,
        Postcode: postcode,
        Email: email,
        "Contact person": contactperson,
        "Contact Number 1": phone,
        "Contact Number 2": phone2,
        "Bill to Email": billtoemail,
        "Bill to Name": billtoname,
        "Account Email": accountemail,
        "Secondary Account Email": secondaryaccountemail,
        Status: companystatus === "Y" ? "Active" : "Inactive",
        Vat: vat === "Y" ? "Yes" : "No",
      };

      if (tblCompanyjobs && tblCompanyjobs.length > 0) {
        tblCompanyjobs.forEach((job, index) => {
          if (index === 0) {
            formattedData.push({ ...companyDetails, ...job });
          } else {
            formattedData.push({
              Name: "",
              "Introducer Category": "",
              Tag: "",
              "Address Line 1": "",
              "Address Line 2": "",
              "Town/City": "",
              Region: "",
              Postcode: "",
              Email: "",
              "Contact person": "",
              "Contact Number 1": "",
              "Contact Number 2": "",
              "Bill to Email": "",
              "Bill to Name": "",
              "Account Email": "",
              "Secondary Account Email": "",
              Status: "",
              Vat: "",
              ...job,
            });
          }
        });
      } else {
        formattedData.push({ ...companyDetails });
      }
    });

    return formattedData;
  };

  const downloadExcel = (jsonData) => {
    const ws = XLSX.utils.json_to_sheet(jsonData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data1 = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    const name = "company_profiles";
    const date = new Date().toISOString().slice(0, 10);
    const fileName = `${name}_${date}.xlsx`;
    FileSaver.saveAs(data1, fileName);
  };

  return (
    <div>
      <Button onClick={handleFetch}>
        <FaFileDownload />
      </Button>
    </div>
  );
}

const Button = styled.button`
  background-color: #3ca2bb;
  color: white;
  border: none;
  padding: 0.5rem;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 2px;

  svg {
    margin-right: 0.5rem;
    transition: transform 0.3s ease-in-out;
  }

  &:hover {
    svg {
      transform: rotate3d(1, 1, 1, 45deg);
    }
  }
`;

export default DownloadAllCompanies;
