import { hdrService } from "../../services/Hdr";
import { handlePostRequest } from "../../services/PostTemplate";
import {
  GETHDRSUCCESS,
  GETHDRERROR,
  GETHDRSTATUSSUCCESS,
  GETHDRSTATUSERROR,
  GETHDRLISTSUCCESS,
  GETHDRLISTERROR,
} from "../slices/hdrSlice";
import { SETLOADING } from "../slices/loadingSlice";
import { handleGetRequest } from "../../services/GetTemplate";

export const getHdrList = (statusUrl) => async (dispatch) => {
  try {
    dispatch(SETLOADING(true));
    const res = await handleGetRequest(statusUrl);
    dispatch(GETHDRLISTSUCCESS(res?.data));
    return res;
  } catch (error) {
    dispatch(GETHDRLISTERROR(error?.response?.data?.data));
    dispatch(SETLOADING(false));
  } finally {
    dispatch(SETLOADING(false));
  }
};

export const getHdrDetails = (hdrCaseId) => async (dispatch) => {
  dispatch(SETLOADING(true));

  try {
    const res = await hdrService.getHdrDetails(hdrCaseId);
    if (res) {
      dispatch(GETHDRSUCCESS(res));
      dispatch(SETLOADING(false));
      return res;
    } else {
      dispatch(GETHDRERROR(res));
      dispatch(SETLOADING(false));
    }
  } catch (error) {
    console.error("Error fetching HDR details:", error);
    dispatch(GETHDRERROR(error));
    dispatch(SETLOADING(false));
  }
};

export const getHdrStatus = () => async (dispatch) => {
  dispatch(SETLOADING(true));
  try {
    const res = await hdrService.getHdrStatus();

    dispatch(GETHDRSTATUSSUCCESS(res));
    dispatch(SETLOADING(false));
    return res;
  } catch (error) {
    dispatch(GETHDRSTATUSERROR(null));
    dispatch(SETLOADING(false));
  }
};

export const postHdrDocs = (docs) => async (url) => {
  const res = await hdrService.postHdrDocs(docs, url);
  if (res) {
    return res;
  }
  return false;
};

export const addHdrCase = (data) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handlePostRequest(data, "hdr/addNewHdrCase");
  dispatch(SETLOADING(false));
  return res;
};

export const getFilerHdrList = (data, url) => async (dispatch) => {
  try {
    dispatch(SETLOADING(true));
    const res = await handlePostRequest(data, url);
    dispatch(GETHDRLISTSUCCESS(res?.data));
    return res;
  } catch (error) {
    dispatch(GETHDRLISTERROR(error?.response?.data?.data));
    dispatch(SETLOADING(false));
  } finally {
    dispatch(SETLOADING(false));
  }
};
