import React from "react";
import LaDropdown from "../../../components/LaDropdown";
import RtaJobFields from "./JobFields/RtaJobFields";
import HireJobFields from "./JobFields/HireJobFields";
import HdrJobFields from "./JobFields/HdrJobFields";
import LaStaticDropdown from "../../../components/LaStaticDropdown";

function JobForm({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  disabled,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    disabled,
  };

  const getCampaignFields = () => {
    switch (values?.compaigncode) {
      case "1": // RTA
        return <RtaJobFields {...props} />;
      case "2": // Hire
        return <HireJobFields {...props} />;
      case "3": // Hdr
        return <HdrJobFields {...props} />;
      default:
        return (
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Status</label>
            <LaStaticDropdown
              keyName="status"
              optionLabel="label"
              options={[
                { value: true, label: "Active" },
                { value: false, label: "Inactive" },
              ]}
              {...props}
            />
          </div>
        );
    }
  };

  return (
    <>
      <div className="p-fluid p-formgrid p-grid p-px-4">
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">Campaign</label>
          <LaDropdown
            keyName="compaigncode"
            placeholder="Select Campaign"
            url={"lovCompaign"}
            optionLabel="name"
            {...props}
          />
        </div>
        {values?.compaigncode && getCampaignFields()}
      </div>
    </>
  );
}

export default JobForm;
