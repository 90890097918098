import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

function Defendant({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  return (
    <div>
      <div className="p-mt-4 p-ml-4 p-mr-4">
        <div className="Panel__Heading">Defendent Details</div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Defendent Name</label>
            <InputText
              id="defendentName"
              name="defendentName"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("defendentName"),
                },
                "p-d-block Width__100"
              )}
              value={values.defendentName}
              onChange={onChange}
            />
            {getFormErrorMessage("defendentName")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Defendent Address</label>
            <InputTextarea
              rows={5}
              id="defendentAddress"
              name="defendentAddress"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("defendentAddress"),
                },
                "p-d-block Width__100"
              )}
              value={values.defendentAddress}
              onChange={onChange}
            />
            {getFormErrorMessage("defendentAddress")}
          </div>
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Contact Number</label>
            <InputText
              id="defendentContactno"
              name="defendentContactno"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("defendentContactno"),
                },
                "p-d-block Width__100"
              )}
              value={values.defendentContactno}
              onChange={onChange}
            />
            {getFormErrorMessage("defendentContactno")}
          </div>
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">Contact Email</label>
            <InputText
              type="email"
              id="defendentEmail"
              name="defendentEmail"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("defendentEmail"),
                },
                "p-d-block Width__100"
              )}
              value={values.defendentEmail}
              onChange={onChange}
            />
            {getFormErrorMessage("defendentEmail")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              How do you know the Defendant? How did you first come into contact
              with them?
            </label>
            <InputTextarea
              rows={5}
              id="howKnowDefendent"
              name="howKnowDefendent"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("howKnowDefendent"),
                },
                "p-d-block Width__100"
              )}
              value={values.howKnowDefendent}
              onChange={onChange}
            />
            {getFormErrorMessage("howKnowDefendent")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Have you had any previous/other problems with the Defendant?
            </label>
            <InputTextarea
              rows={5}
              id="otherprblmWithDefendent"
              name="otherprblmWithDefendent"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid(
                    "otherprblmWithDefendent"
                  ),
                },
                "p-d-block Width__100"
              )}
              value={values.otherprblmWithDefendent}
              onChange={onChange}
            />
            {getFormErrorMessage("otherprblmWithDefendent")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Defendant;
