import React, { useEffect } from "react";
import classNames from "classnames";
import { MultiSelect } from "primereact/multiselect";
import ReadOnlyList from "./ReadOnlyList";
import { useDispatch, useSelector } from "react-redux";
import { fetchDropdownOptions } from "../../redux/actions/dropdownAction";

function LaMultiSelect({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  optionLabel,
  url,
  showList = false,
  hardRefresh = false,
  ...rest
}) {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.dropdownSlice?.dropdowns[url]);

  useEffect(() => {
    const checkAndFetchOptions = async () => {
      // Check if options are already present in the Redux store
      if (!options || hardRefresh) {
        dispatch(fetchDropdownOptions(url, optionLabel));
      }
    };

    // Invoke the function to check and fetch options
    checkAndFetchOptions();
  }, [dispatch, options, url, optionLabel, hardRefresh]);
  return (
    <>
      <MultiSelect
        id={keyName}
        placeholder={placeholder}
        name={keyName}
        value={values[keyName]}
        options={options || []}
        onChange={onChange}
        optionLabel={optionLabel}
        {...rest}
        className={
          (classNames({
            "p-invalid": isFormFieldValid(keyName.toString()),
          }),
          "Width__100")
        }
      />
      {getFormErrorMessage(keyName.toString())}
      <ReadOnlyList
        showList={showList}
        values={values[keyName]}
        options={options}
      />
    </>
  );
}

export default LaMultiSelect;
