import React from "react";
import UpdatePassword from "../../components/ChangePassword";

function UserChangePassword() {
  return (
    <div>
      <UpdatePassword show={true} hide={() => console.log("hide")} />
    </div>
  );
}

export default UserChangePassword;
