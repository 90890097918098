import { Button } from "primereact/button";
import React, { useEffect } from "react";
import ClaimantDetails from "./ClaimantDetails";
import Defendant from "./Defendant";
import DataBreachInfo from "./DataBreachInfo";
import ComplaintDetails from "./ComplaintDetails";
import Miscellaneous from "./Miscellaneous";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { handlePostRequest } from "../../services/PostTemplate";
import classNames from "classnames";
import { initialValues, validationSchema } from "./DataBreachConstants";

function DataBreach({
  edit,
  onSave,
  isSubmitFormik,
  handleReturnEdit,
  setIsSubmitForm,
}) {
  const { db } = useSelector((state) => state.dbSlice);

  useEffect(() => {
    if (isSubmitFormik && edit) {
      formik.handleSubmit();
    }
  }, [isSubmitFormik]);

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (body) => {
      if (edit) {
        body["tenancyclaimcode"] = db.dbclaimcode;
        delete body["dbActionButton"];
        delete body["tblTenancydocuments"];
        delete body["tblTenancylogs"];
        delete body["tblTenancymessages"];
        delete body["tblTenancynotes"];
        delete body["tblTenancysolicitors"];
        await handlePostRequest(body, "/db/updateDbCase");
        handleReturnEdit();
      } else {
        await handlePostRequest(body, "/db/addNewDbCase");
      }
    },
  });

  useEffect(() => {
    if (edit) {
      fillFormFields();
    }
  }, [edit, db]);

  const fillFormFields = () => {
    Object.keys(db).forEach((key) => {
      formik.setFieldValue(key, db[key] || "");
    });
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
  };

  //show formik errorst
  useEffect(() => {
    if (formik.errors && setIsSubmitForm) {
      setIsSubmitForm(false);
    }
  }, [formik.errors, setIsSubmitForm]);

  return (
    <div>
      {!edit ? (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            <h6
              style={{
                color: "#428BCA",
                fontWeight: "600",
                lineHeight: "1.42857143",
              }}
            >
              Main Info
            </h6>
            <small style={{ color: "#999" }}>Enter main information</small>
          </div>
          <div>
            <Button
              type="button"
              icon="pi pi-arrow-circle-right"
              iconPos="right"
              className="btn btn-primary"
              label="Next"
              onClick={formik.handleSubmit}
            ></Button>
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-col-6 p-mt-2">
          <ClaimantDetails {...props} />
        </div>
        <div className="p-col-6 p-mt-2">
          <Defendant {...props} />
        </div>
        <div className="p-col-6 p-mt-2">
          <DataBreachInfo {...props} />
        </div>
        <div className="p-col-6 p-mt-2">
          <ComplaintDetails {...props} />
        </div>
        <div className="p-col-6 p-mt-2">
          <Miscellaneous {...props} />
        </div>
      </div>
    </div>
  );
}

export default DataBreach;
