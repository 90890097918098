import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

function Miscellaneous({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  return (
    <div>
      <div className="p-mt-4 p-ml-4 p-mr-4">
        <div className="Panel__Heading">Miscellaneous Details</div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Do you know if your data has been breached by any other companies,
              businesses or individuals? *If yes get details and ask if Claimant
              wants for us to investigate this for him and pursue a claim
              against them as well*
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="dbByCompBusns"
              name="dbByCompBusns"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("dbByCompBusns"),
                },
                "p-d-block Width__100"
              )}
              value={values.dbByCompBusns}
              onChange={onChange}
            />
            {getFormErrorMessage("dbByCompBusns")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Do you know if any of your friends or family members have suffered
              from any data breaches? *If yes ask Claimant to get them to
              contact us directly to enquire about making a claim. Advise him we
              will email him our phone number and email address so that any
              referrals can contact us directly about making a claim*
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="anyFfmSufferedDb"
              name="anyFfmSufferedDb"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("anyFfmSufferedDb"),
                },
                "p-d-block Width__100"
              )}
              value={values.anyFfmSufferedDb}
              onChange={onChange}
            />
            {getFormErrorMessage("anyFfmSufferedDb")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Miscellaneous;
