import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import AddLinesForm from "./AddLinesForm";

function AddLines({ invoiceheadid, getInvoiceDetails }) {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className="p-pl-4">
      <button onClick={() => setIsShow(true)} className="btn btn-primary">
        Add Lines
      </button>
      <Dialog
        header={"Add Lines"}
        visible={isShow}
        style={{ width: "50vw" }}
        onHide={() => setIsShow(false)}
      >
        <AddLinesForm
          invoiceheadid={invoiceheadid}
          setIsShow={setIsShow}
          getInvoiceDetails={getInvoiceDetails}
        />
      </Dialog>
    </div>
  );
}

export default AddLines;
