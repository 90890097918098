import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import LaDropdown from "../../../../components/LaDropdown";
import LaInput from "../../../../components/LaInput";
import LaInputTextArea from "../../../../components/LaInputTextArea";

function MainInfo({
  classNames,
  isFormFieldValid,
  getFormErrorMessage,
  onChange,
  values,
  formik,
}) {
  const [noOfAccDaysTime, setNoOfAccDaysTime] = useState(false);
  const props = {
    onChange,
    values,
    isFormFieldValid,
    getFormErrorMessage,
  };

  useEffect(() => {
    if (values.accdate && values.acctime) {
      const currentDate = new Date();
      const selectedDate = new Date(values.accdate + "T" + values.acctime);

      // Check if selected date is in the future
      if (selectedDate > currentDate) {
        setNoOfAccDaysTime(false);
        return;
      }

      // Calculate time difference
      const diffTime = Math.abs(currentDate - selectedDate);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);

      // Set the time difference object
      const diffTimeObj = {
        days: diffDays,
        hours: diffHours,
      };
      setNoOfAccDaysTime(diffTimeObj);
    } else {
      setNoOfAccDaysTime(false);
    }
  }, [values.accdate, values.acctime]);

  const handleMaxDate = (e, key) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in ISO format

    if (e.target.value > today) {
      formik.setFieldValue(key, today);
    } else {
      formik.setFieldValue(key, e.target.value);
    }
  };

  return (
    <>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">
          Date <span className="text-danger">*</span>
        </label>
        <InputText
          type="date"
          id="accdate"
          name="accdate"
          value={values.accdate}
          onChange={(e) => {
            handleMaxDate(e, "accdate");
          }}
          className={classNames({
            "p-invalid": isFormFieldValid("accdate"),
          })}
        />
        {getFormErrorMessage("accdate")}
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">
          Time <span className="text-danger">*</span>
        </label>
        <InputText
          type="time"
          id="acctime"
          name="acctime"
          value={values.acctime}
          onChange={(e) => {
            onChange(e);
          }}
          className={classNames({
            "p-invalid": isFormFieldValid("acctime"),
          })}
        />
        {getFormErrorMessage("acctime")}
      </div>
      {noOfAccDaysTime && (
        <div className="p-col-12">
          <span className="label-info">
            {noOfAccDaysTime.days === 1
              ? `${noOfAccDaysTime.days} day `
              : noOfAccDaysTime.days > 1
              ? `${noOfAccDaysTime.days} days `
              : ""}
            {noOfAccDaysTime.hours} hours ago
          </span>
        </div>
      )}

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Circumstances <span className="text-danger">*</span>
        </label>
        <LaDropdown
          keyName="circumcode"
          optionLabel="name"
          placeholder=""
          url={"/lovCircumstances"}
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Location <span className="text-danger">*</span>
        </label>
        <LaInput keyName="location" placeholder="Location" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Description <span className="text-danger">*</span>
        </label>
        <LaInputTextArea
          keyName="description"
          placeholder="if other please provide a detailed description"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Road Weather Conditions</label>
        <LaInputTextArea
          keyName="rdweathercond"
          placeholder="enter accident road and weather conditions here"
          {...props}
        />
      </div>
      {/* <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Injury Sustained</label>
        <LaInputTextArea
          keyName="injurySustained"
          placeholder="enter injury sustained here"
          {...props}
        />
      </div> */}
    </>
  );
}

export default MainInfo;
