import { baseURL } from "../Config";
import axios from "axios";
import { SETLOADING } from "../redux/slices/loadingSlice";
import store from "../redux/store";
import { SETTOKENEXPIRY } from "../redux/slices/TokenExpirySlice";
import { refreshToken } from "./Auth";
import { toast } from "react-toastify";

export const handleGetRequest = async (url, isShowLoad = true) => {
  if (isShowLoad) {
    store.dispatch(SETLOADING(true));
  }
  // let callRefreshtoken = false;
  try {
    const response = await axios.get(`${baseURL + url}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    if (isShowLoad) {
      store.dispatch(SETLOADING(false));
    }
    return response.data;
  } catch (error) {
    console.log("error :::", error?.response);
    // toast.warn(error?.response?.data?.messages || "Something went wrong !!");
    if (isShowLoad) {
      store.dispatch(SETLOADING(false));
    }

    if (error?.response?.status === 401) {
      toast.warn("Session expired. Please login again");
      localStorage.clear();
      window.location.href = "../";
    } else if (error?.response?.status === 404) {
    } else {
      toast.warn(
        error?.response?.data?.messages ||
          "Session expired. Please login again!"
      );
    }
    throw error;
  }
};

export const handleGetRequestForImages = async (url) => {
  store.dispatch(SETLOADING(true));
  try {
    const response = await axios.get(`${url}`, {
      headers: {
        Authorization: localStorage.getItem("token"),
      },
    });
    store.dispatch(SETLOADING(false));
    return response.data;
  } catch (error) {
    // toast.warn(error?.response?.data?.messages || "Something went wrong !!");
    store.dispatch(SETLOADING(false));
    if (error?.response?.status === 401 || error?.response?.status === 500) {
      localStorage.clear();
      window.location.href = "../";
    }
    throw error;
  }
};
