import { Dialog } from "primereact/dialog";
import React from "react";
import Captcha from "./Captcha";

function CaptchaDialog({ showModal, onCloseModal }) {
  return (
    <Dialog
      header="CAPTCHA Verification"
      position="top"
      draggable={false}
      visible={showModal}
      style={{ width: "80vw" }}
      onHide={onCloseModal}
    >
      <Captcha onCloseModal={onCloseModal} />
    </Dialog>
  );
}

export default CaptchaDialog;
