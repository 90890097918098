import React from "react";
import LaInputNumber from "../../../../components/LaInputNumber/LaInputNumber";
import LaStaticDropdown from "../../../../components/LaStaticDropdown";

function HdrJobFields({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  disabled,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    disabled,
  };

  const inputNumberProps = {
    ...props,
    mode: "currency",
    currency: "GBP",
  };

  return (
    <>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Fee</label>
        <LaInputNumber
          keyName="solicitorsfees"
          placeholder="Fee"
          {...inputNumberProps}
        />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Status</label>
        <LaStaticDropdown
          keyName="status"
          optionLabel="label"
          options={[
            { value: true, label: "Active" },
            { value: false, label: "Inactive" },
          ]}
          {...props}
        />
      </div>
    </>
  );
}

export default HdrJobFields;
