import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authenticationSlice",
  initialState: {
    pl: {},
    plList: [],
    plStatus: [],
  },
  reducers: {
    GETPLLISTSUCCESS: (state, action) => {
      return {
        ...state,
        plList: action.payload,
      };
    },
    GETPLLISTERROR: (state) => {
      return {
        ...state,
        plList: [],
      };
    },
    GETPLSUCCESS: (state, action) => {
      return {
        ...state,
        pl: action.payload,
      };
    },
    GETPLERROR: (state) => {
      return {
        ...state,
        pl: {},
      };
    },
    GETPLSTATUSSUCCESS: (state, action) => {
      return { ...state, plStatus: action.payload };
    },
    GETPLSTATUSERROR: (state) => {
      return { plStatus: [] };
    },
  },
});

export const {
  GETPLLISTSUCCESS,
  GETPLLISTERROR,
  GETPLSUCCESS,
  GETPLERROR,
  GETPLSTATUSERROR,
  GETPLSTATUSSUCCESS,
} = slice.actions;
export default slice.reducer;
