import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authenticationSlice",
  initialState: {
    ol: {},
    olList: [],
    olStatus: [],
  },
  reducers: {
    GETOLLISTSUCCESS: (state, action) => {
      return {
        ...state,
        olList: action.payload,
      };
    },
    GETOLLISTERROR: (state) => {
      return {
        ...state,
        olList: [],
      };
    },
    GETOLSUCCESS: (state, action) => {
      return {
        ...state,
        ol: action.payload,
      };
    },
    GETOLERROR: (state) => {
      return {
        ...state,
        ol: {},
      };
    },
    GETOLSTATUSSUCCESS: (state, action) => {
      return { ...state, olStatus: action.payload };
    },
    GETOLSTATUSERROR: (state) => {
      return { ...state, olStatus: {} };
    },
  },
});

export const {
  GETOLLISTSUCCESS,
  GETOLLISTERROR,
  GETOLSUCCESS,
  GETOLERROR,
  GETOLSTATUSERROR,
  GETOLSTATUSSUCCESS,
} = slice.actions;
export default slice.reducer;
