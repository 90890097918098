import React from "react";
import { InputTextarea } from "primereact/inputtextarea";
import classNames from "classnames";

function LaInputTextArea({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  ...rest
}) {
  return (
    <>
      <InputTextarea
        id={keyName}
        rows={5}
        cols={30}
        placeholder={placeholder}
        name={keyName}
        value={values[keyName]}
        onChange={onChange}
        {...rest}
        className={classNames({
          "p-invalid": isFormFieldValid(keyName.toString()),
        })}
      />
      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default LaInputTextArea;
