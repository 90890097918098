/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { ImUpload2 } from "react-icons/im";
import Accordion from "../../components/Accordion";
import { FormWrapper } from "../../components/FormLayout/FormHeader";
import {
  LargeTab,
  LargeTabWrapper,
} from "../../components/FormLayout/FormHeader/LargeTab";
import FormMainBody from "../../components/FormLayout/FormMainBody/index";
import UploadModal from "../../components/UploadModal";
import Messages from "../../components/Messages";
import Notes from "../../components/Notes";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getrtaDetails } from "../../redux/actions/rtaAction";
import Multimedia from "../../components/Multimedia";
import RTA from "./RTA";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useEffect } from "react";
import NotesLegalInternal from "../../components/Notes/NotesLegalInternal";
import Logs from "../../components/Logs";

function ViewRtaForm({ formikRef }) {
  const [active, setActive] = useState(0);
  const [showUploadModal, setshowUploadModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isSubmitFormik, setisSubmitFormik] = useState(false);
  const { rtacode } = useParams();
  const dispatch = useDispatch();
  const { rta } = useSelector((state) => state.rtaSlice);
  const signingSecitonDocuments = rta?.tblRtadocuments?.filter(
    (tblRtadocument) => tblRtadocument.tblTask !== null
  );
  const documents = rta?.tblRtadocuments?.filter(
    (tblRtadocument) => tblRtadocument.tblTask === null
  );
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCat = user?.tblUsercategory?.categorycode;
  const messages = useSelector((state) => state.notesSlice?.notes?.tblRtanotes);
  const internalMessages = useSelector(
    (state) => state.notesSlice?.notes?.tblRtanotesLegalOnly
  );

  const onDeleteDocument = () => {
    getDetails();
  };

  const uploadAction = (
    <>
      {userCat === "4" || userCat === "1" ? (
        <div
          className="text-semibold pointer"
          onClick={() => setshowUploadModal(true)}
        >
          <ImUpload2 /> Upload
        </div>
      ) : null}
    </>
  );

  const getDetails = async () => {
    await dispatch(getrtaDetails(rtacode));
  };

  useEffect(() => {
    getDetails();
  }, [rtacode]);

  const onSave = async () => {
    setisSubmitFormik(true);
  };

  const handleReturnEdit = async () => {
    setDisabled(true);
  };

  const onSetIsSubmitFormikFalse = () => {
    setisSubmitFormik(false);
  };

  const onCancel = () => {
    setDisabled(true);
  };

  const handlePrintForm = () => {
    const input = document.getElementById("printable");
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(`rta${rtacode}.pdf`);
    });
  };

  return (
    <FormWrapper>
      <div id="printable">
        <LargeTabWrapper>
          <LargeTab
            onClick={() => setActive(0)}
            active={active === 0}
            title="Main"
          />
          <LargeTab
            onClick={() => setActive(1)}
            active={active === 1}
            title={`Images & Videos (${rta?.tblRtadocuments?.length || 0})`}
          />
          <LargeTab
            onClick={() => setActive(2)}
            active={active === 2}
            title="Notes"
          />
          {userCat === "4" ? (
            <>
              <LargeTab
                onClick={() => setActive(3)}
                active={active === 3}
                title="Messages"
              />
              <LargeTab
                onClick={() => setActive(4)}
                active={active === 4}
                title="Logs"
              />
            </>
          ) : null}
        </LargeTabWrapper>
        {active === 0 && (
          <FormMainBody
            isEdit={!disabled}
            setIsEdit={(edit) => {
              setDisabled(!edit);
            }}
            disabledForm={String(disabled)}
            title="Case Info"
            onSave={onSave}
            onCancel={onCancel}
            editFlag={rta?.editFlag}
          >
            <RTA
              edit={true}
              onSave={onSave}
              isSubmitFormik={isSubmitFormik}
              handleReturnEdit={handleReturnEdit}
              onSetIsSubmitFormikFalse={onSetIsSubmitFormikFalse}
              showAccSubmissionCriteria={true}
              formikRef={formikRef}
            />
            <center className="mt-4">
              <button
                onClick={handlePrintForm}
                id="print-btn"
                className="btn btn-block btn-primary"
              >
                Print Claimant Form
              </button>
            </center>
          </FormMainBody>
        )}
        {active === 1 && (
          <>
            <Accordion title="Images" headerRightArea={uploadAction} open>
              {/* <DownloadImages documents={rta?.tblRtadocuments} /> */}
              <Multimedia
                documents={documents}
                onDeleteDocument={onDeleteDocument}
                deleteApi="rta/deleteRtaDocument"
                documentType="rtadoccode"
              />
            </Accordion>
            <Accordion title="Document Signing Section" open>
              <Multimedia
                documents={signingSecitonDocuments}
                onDeleteDocument={onDeleteDocument}
                deleteApi="rta/deleteRtaDocument"
                documentType="rtadoccode"
              />
            </Accordion>
          </>
        )}
        <UploadModal
          show={showUploadModal}
          hide={(show) => setshowUploadModal(show)}
          addFilesService={"rta/addRtaDocument"}
          codeName="rtaClaimCode"
          codeValue={rtacode}
          returnSubmit={getDetails}
        />
        {active === 2 && (
          <Accordion title="Notes" open>
            <NotesLegalInternal internalMessages={internalMessages} />
            <Notes
              getNotesService={"rta/getAuthRtaCaseNotes"}
              addNotesService={"rta/addNoteToRta"}
              caseType={"rtaCode"}
              code={rtacode}
              messages={messages}
            />
          </Accordion>
        )}
        {active === 3 && (
          <Accordion title="Messages" open>
            <Messages
              code={rtacode}
              url={"rta/getAuthRtaCaseMessages/"}
              posturl={"rta/resendRtaEmail"}
              compainName={"rtamessagecode"}
            />
          </Accordion>
        )}
        {active === 4 && (
          <Accordion title="Logs" open>
            <Logs api="rta/getRtaAuditLogs" caseCode={rta?.rtacode} />
          </Accordion>
        )}
      </div>
    </FormWrapper>
  );
}

export default ViewRtaForm;
