import { InfoButton, InfoWrapper } from "../../components/InfoButton";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab, TabWrapper } from "../../components/Tab";

import { AiOutlineBars } from "react-icons/ai";
import CaseHeaderInvoice from "./CaseHeaderInvoice";
import { Chip } from "primereact/chip";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { FiSettings } from "react-icons/fi";
import { InputText } from "primereact/inputtext";
import { getInvoiceList } from "../../redux/actions/invoiceAction";
import { handleGetRequest } from "../../services/GetTemplate";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { FaCalendarAlt } from "react-icons/fa";
import { formatAmount } from "./invoiceDetails";
import { FaUserTie, FaBriefcase } from "react-icons/fa";

const StyledDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);

  span {
    margin-bottom: 5px;
    display: flex;
    align-items: center;
  }

  .icon {
    font-size: 1.2em;
    margin-right: 5px;
  }

  .redText {
    color: red;
  }

  .greenText {
    color: green;
  }
`;

function ViewInvoiceList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState("All");
  const [invoiceStatus, setInvoiceStatus] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const invoiceList = useSelector((state) => state.invoiceSlice.invoiceList);

  const getInvoice = async (status) => {
    await dispatch(getInvoiceList(status));
  };

  const getInvoiceStatuses = async () => {
    const res = await handleGetRequest(`invoicing/getAllInvoicesStatusCount`);
    setInvoiceStatus(res.data);
  };

  const totalStatus = () => {
    let total = 0;
    invoiceStatus &&
      invoiceStatus.forEach(({ statusCount }) => {
        total += statusCount;
      });
    return total;
  };

  const actionsTemplate = ({ invoiceheadid }) => {
    return (
      <div
        title="Details"
        className="pointer"
        onClick={() => {
          navigate(`../invoiceDetails?invoicecode=${invoiceheadid}`);
        }}
      >
        <FiSettings />
      </div>
    );
  };

  // Parse custom date format
  const parseCustomDate = (dateString) => {
    const [day, month, year, time] = dateString.split(/[\s-]/);
    const [hour, minute, second] = time.split(":");
    return new Date(year, month - 1, day, hour, minute, second);
  };

  const descriptionTemplate = ({ createdon, duedate }) => {
    const currectDate = new Date();
    const dueDate = parseCustomDate(duedate);

    // Check if parsing was successful
    if (isNaN(currectDate.getTime()) || isNaN(dueDate.getTime())) {
      console.error("Invalid date strings");
      return <>Invalid dates</>;
    }

    const isDueDateEqual = currectDate.getTime() >= dueDate.getTime();
    return (
      <StyledDescription>
        <span>
          <FaCalendarAlt className="icon" />
          <strong>Issued On :</strong>&nbsp;{createdon}
        </span>
        <span className={isDueDateEqual ? "redText" : "greenText"}>
          <FaCalendarAlt className="icon" />
          <strong>Due On :</strong>&nbsp; {duedate}
        </span>
      </StyledDescription>
    );
  };

  useEffect(() => {
    onGlobalFilterChange();
  }, [invoiceList]);

  const onGlobalFilterChange = (e = false) => {
    const keys = [
      "compaigntype",
      "invoicecode",
      "username",
      "createdon",
      "amount",
      "statuscode",
    ];
    const value = e?.target?.value;
    if (value) {
      const filteredList = invoiceList.filter((item) => {
        let flag = false;
        keys.forEach((key) => {
          if (
            key &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
            flag = true;
        });
        if (flag) return item;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(invoiceList);
    }
  };

  useEffect(() => {
    getInvoiceStatuses();
  }, []);

  useEffect(() => {
    getInvoice(currentStatus);
  }, [currentStatus]);

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </span>
      </div>
    );
  };

  const statusbody = (rowData) => {
    return <Chip label={rowData?.statuscode} className="p-px-4 custom-chip" />;
  };

  const amountTemplate = (rowData) => {
    return <span>{formatAmount(rowData?.amount + rowData?.vatAmount)}</span>;
  };

  const codeTemplate = (rowData) => {
    const currectDate = new Date();
    const dueDate = rowData?.duedate;

    const date1 = currectDate;
    const date2 = parseCustomDate(dueDate);

    // Check if parsing was successful
    if (isNaN(date1.getTime()) || isNaN(date2.getTime())) {
      console.error("Invalid date strings");
      return <>Invalid dates</>;
    }

    // Calculate the difference in days
    const diffTime = date1 - date2;
    const isDatePassed = diffTime < 0;
    const diffDays = Math.ceil(Math.abs(diffTime) / (1000 * 60 * 60 * 24));

    // Define styles based on condition
    const textStyle = {
      color: isDatePassed ? "green" : "red", // Change red to your desired color
    };

    return (
      <>
        <span>
          {rowData?.invoicecode}
          <br />
        </span>
        <span style={textStyle}>
          {isDatePassed ? `${diffDays} days left` : `${diffDays} days ago`}
        </span>
      </>
    );
  };

  const header2 = renderHeader2();
  return (
    <>
      <CaseHeaderInvoice
        title={"Invoice List"}
        subTitle={"Table with invoices and invoice details"}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeaderInvoice>
      <TabWrapper>
        <Tab
          title={"All"}
          count={String(totalStatus())}
          active={currentStatus === "All"}
          icon={<AiOutlineBars />}
          onClick={() => setCurrentStatus("All")}
        />
        {invoiceStatus &&
          invoiceStatus.map(({ statusName, statusCount, statusCode }) => (
            <Tab
              key={statusCode}
              title={statusName}
              count={String(statusCount)}
              onClick={() => setCurrentStatus(statusCode)}
              active={currentStatus === statusCode}
              icon={<AiOutlineBars />}
            />
          ))}
      </TabWrapper>

      <div className="card">
        <StyledInvoiceDataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header={header2}
        >
          <Column
            field="invoiceType"
            sortable
            header="Comapny Type"
            filter
            filterMatchMode="contains"
            body={(rowData) => {
              return rowData?.invoiceType === "I" ? (
                <IntroducerChip>
                  <FaUserTie style={{ marginRight: "8px" }} /> Introducer
                </IntroducerChip>
              ) : (
                <SolicitorChip>
                  <FaBriefcase style={{ marginRight: "8px" }} /> Solicitor
                </SolicitorChip>
              );
            }}
          />
          <Column
            field="invoicecode"
            sortable
            body={codeTemplate}
            filter
            filterMatchMode="contains"
            header="Invoice Number"
          ></Column>
          <Column
            field="companyname"
            sortable
            header="Bill to"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="createdon"
            body={descriptionTemplate}
            filter
            filterMatchMode="contains"
            sortable
            header="Description"
          ></Column>
          <Column
            field="amount"
            body={amountTemplate}
            filter
            filterMatchMode="contains"
            sortable
            header="Amount"
          ></Column>
          <Column
            field="statuscode"
            body={statusbody}
            filter
            filterMatchMode="contains"
            sortable
            header="Status"
          ></Column>
          <Column header="Acts" body={actionsTemplate}></Column>
        </StyledInvoiceDataTable>
      </div>
    </>
  );
}

const StyledInvoiceDataTable = styled(DataTable)`
  td:first-child {
    width: 10%;
  }
  td:nth-child(2) {
    width: 10%;
  }
  td:nth-child(3) {
    width: 15%;
  }
  td:nth-child(4) {
    width: 40%;
  }
`;

const ChipBase = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 8px 16px;
  border-radius: 16px;
  font-weight: bold;
`;

const IntroducerChip = styled(ChipBase)`
  background-color: lightblue; /* Change to your desired color */
`;

const SolicitorChip = styled(ChipBase)`
  background-color: lightgreen; /* Change to your desired color */
`;

export default ViewInvoiceList;
