import React from "react";
import SideBox from "../FormLayout/SideBoxSection";
import { useSelector } from "react-redux";
import ChangeIntroducerButton from "../ChangeIntroducer/ChangeIntroducerButton";
import { campaignTaskKeys } from "../CampaignGeneric/campaignConfig";

const CaseAccountInfo = ({
  introducerName,
  advisorName,
  campaign,
  campaignName,
  getCampaignDetails,
}) => {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const { compaignCodeKeyPostApi } = campaignTaskKeys[campaignName];

  const user = userStore && JSON.parse(userStore);
  if (user?.tblUsercategory?.categorycode === "2") {
    return null;
  }

  return (
    <SideBox title="Case Accounts" className="mt-4">
      <strong>{introducerName}</strong>
      <br />
      <small>{advisorName}</small>
      {campaign?.solicitorcompany && (
        <>
          <br />
          <hr />
          <strong>{campaign?.solicitorcompany || ""}</strong>
          <br />
          <small>{campaign?.solicitorusername || ""}</small>
        </>
      )}
      <ChangeIntroducerButton
        campaignName={campaignName}
        getCampaignDetails={getCampaignDetails}
        campaignCaseCode={campaign?.[compaignCodeKeyPostApi]}
      />
    </SideBox>
  );
};

export default CaseAccountInfo;
