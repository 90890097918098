import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "hireSlice",
  initialState: {
    hire: {},
    hireList: [],
    hireStatus: [],
  },
  reducers: {
    GETHIRELISTSUCCESS: (state, action) => {
      return {
        ...state,
        hireList: action.payload,
      };
    },
    GETHIRELISTERROR: (state) => {
      return {
        ...state,
        hireList: [],
      };
    },
    GETHIRESUCCESS: (state, action) => {
      return {
        ...state,
        hire: action.payload,
      };
    },
    GETHIREERROR: (state) => {
      return {
        ...state,
        hire: {},
      };
    },
    GETHIRESTATUSSUCCESS: (state, action) => {
      return { ...state, hireStatus: action.payload };
    },
    GETHIRESTATUSERROR: (state) => {
      return { ...state, hireStatus: {} };
    },
    PERFORMACTIONSUCCESS: (state, action) => {
      return {
        ...state,
        hire: action.payload,
      };
    },
  },
});

export const {
  GETHIRELISTSUCCESS,
  GETHIRELISTERROR,
  GETHIRESUCCESS,
  GETHIREERROR,
  GETHIRESTATUSERROR,
  GETHIRESTATUSSUCCESS,
  PERFORMACTIONSUCCESS,
  PERFORMACTIONERROR,
} = slice.actions;
export default slice.reducer;
