import { Dialog } from "primereact/dialog";
import React from "react";
import AddCompanyJob from "./AddCompanyJob";

function AddJobDialog({ showModal, onCloseModal, getjobList, id }) {
  return (
    <Dialog
      header="Add Job"
      position="top"
      draggable={false}
      visible={showModal}
      style={{ width: "80vw" }}
      onHide={onCloseModal}
    >
      <div className="mt-4">
        <AddCompanyJob
          getjobList={getjobList}
          onCloseModal={onCloseModal}
          id={id}
        />
      </div>
    </Dialog>
  );
}

export default AddJobDialog;
