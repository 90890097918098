import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { useSelector, useDispatch } from "react-redux";
import { getLovRole } from "../../services/UsersRegister";
import {
  PostUsersAction,
  PostUsersEditAction,
} from "../.../../../redux/actions/profileAction";
import EditViewUsersData from "./EditViewUsersData";
import { handleGetRequest } from "../../services/GetTemplate";

function EditViewUsers({ name, tag, userCat }) {
  const [loginid, setloginid] = useState("");
  const [password, setpassword] = useState("");
  const [userRole, setuserRole] = useState([]);
  const [userRoleValue, setuserRoleValue] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [editflag, seteditflag] = useState(true);
  const [username, setusername] = useState("");
  const [usercode, setusercode] = useState(null);
  let states = [
    {
      code: "Y",
      name: "Active",
      type: null,
    },
    {
      code: "N",
      name: "Inactive",
      type: null,
    },
  ];

  async function funcgetLovRole() {
    const res = await handleGetRequest("/lovRole");
    setuserRole(res.data);
  }

  const generatePass = () => {
    //GENERATE random password
    var length = 8,
      charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
      retVal = "";
    for (var i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    setpassword(retVal);
  };

  const onStateChange = (e) => {
    setSelectedState(e.value);
  };

  const companyUsersData = useSelector((state) => state.profileSlice.usersData);

  const CompanyCode = useSelector(
    (state) => state.profileSlice.singleCompanyData?.companycode
  );
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    const userRolevalues = userRoleValue.map((role) => {
      return role.code;
    });
    const data = {
      companycode: CompanyCode,
      loginid: loginid,
      status: selectedState.code,
      pwd: password,
      rolecodes: userRolevalues,
      username: username,
    };
    if (editflag) {
      data.pwd = password;
      data["usercode"] = usercode;
      try {
        dispatch(PostUsersEditAction(data));
        setDisplayBasic(!displayBasic);
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        dispatch(PostUsersAction(data));
        setDisplayBasic(!displayBasic);
      } catch (error) {
        console.log(error);
      }
    }
  };

  const editRow = async (rowData) => {
    seteditflag(true);
    setDisplayBasic(!displayBasic);
    setuserRoleValue(rowData.lovResponse);
    setusername(rowData.username);
    setloginid(rowData.loginid);
    setusercode(rowData.usercode);
    if (rowData.status === "Y") {
      const state = {
        code: "Y",
        name: "Active",
        type: null,
      };
      setSelectedState(state);
    }
    if (rowData.status === "N") {
      const state = {
        code: "N",
        name: "Inactive",
        type: null,
      };
      setSelectedState(state);
    }
  };

  const addRow = async () => {
    seteditflag(false);
    setDisplayBasic(!displayBasic);
    setloginid("");
    setuserRoleValue([]);
    setSelectedState([]);
    setpassword("");
    setusername("");
  };

  useEffect(() => {
    funcgetLovRole();
  }, []);

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "baseline",
        }}
      >
        <label>
          {" "}
          <b> Name : {name}</b>
        </label>
        <label>
          <b>Tag : {tag}</b>{" "}
        </label>
        <label>
          <b>Category : {userCat?.name}</b>{" "}
        </label>
        <div className="mb-2" align="right">
          <Button
            className="btn btn-block btn-primary"
            label="Add"
            icon="pi pi-external-link"
            onClick={addRow}
          />
        </div>
      </div>

      <EditViewUsersData
        companyUsersData={companyUsersData}
        editRow={editRow}
      />

      <Dialog
        header={editflag ? "Add User" : "Edit User"}
        visible={displayBasic}
        style={{ width: "80%" }}
        onHide={() => setDisplayBasic(!displayBasic)}
      >
        {!editflag ? (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-4">
              <label htmlFor="locale-us">Login Id</label>
              <InputText
                value={loginid}
                onChange={(e) => setloginid(e.target.value)}
              />
            </div>
            <div className="p-field p-col-12 p-md-4">
              <label htmlFor="locale-us">Password</label>
              <InputText
                value={password}
                onChange={(e) => setpassword(e.target.value)}
              />
            </div>
            <div className="p-field p-col-12 p-md-4">
              <label htmlFor="locale-us">Generate Password</label>
              <Button
                label="Generate Random Pass"
                className="p-button-secondary"
                onClick={generatePass}
              />
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="locale-us">Login Id</label>
            <InputText
              value={loginid}
              onChange={(e) => setloginid(e.target.value)}
            />
          </div>
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="userrole">User Role</label>
            <MultiSelect
              inputId="userrole"
              value={userRoleValue}
              options={userRole}
              onChange={(e) => {
                setuserRoleValue(e.value);
              }}
              placeholder="Select"
              optionLabel="name"
            />
          </div>
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="locale-us">Email</label>
            <InputText
              value={username}
              onChange={(e) => setusername(e.target.value)}
            />
          </div>
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="Status">Status</label>
            <Dropdown
              className="dropdown__width__100"
              inputId="Status"
              value={selectedState}
              options={states}
              onChange={onStateChange}
              placeholder="Select"
              optionLabel="name"
            />
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "15%" }}>
          <Button
            className="btn btn-block btn-primary"
            onClick={handleSubmit}
            type="submit"
            label="Submit"
          />
        </div>
      </Dialog>
    </div>
  );
}

export default EditViewUsers;
