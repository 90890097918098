import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FiClock } from "react-icons/fi";
import LaInput from "../../../components/LaInput";

const ChipWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  padding: 4px 8px;
  background-color: var(--category-internal-background);
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s, transform 0.3s;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transform: scale(1.05);
  }
`;

const DateText = styled.p`
  font-size: 16px;
  color: #fff;
  margin: 0;
  margin-left: 12px;
  font-weight: bold;
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.2);
`;

export const formatClawBackDate = (date) => {
  // date would be in this form 10-04-2024 and return 2024-04-10
  if (!date) return null;
  else {
    const dateArr = date.split("-");
    const year = dateArr[2];
    const month = dateArr[1];
    const day = dateArr[0];
    return `${year}-${month}-${day}`;
  }
};

function ClawbackDate({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  clawbackDate,
}) {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore ? JSON.parse(userStore) : null;
  const userCat = user?.tblUsercategory?.categorycode;

  if (!clawbackDate || userCat !== "4") return null;

  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  return (
    <>
      <ChipWrapper>
        <IconWrapper>
          <FiClock size={16} color="#fff" />
        </IconWrapper>
        <DateText>Clawback Date: {clawbackDate}</DateText>
      </ChipWrapper>
      <div className="p-col-12 mb-4">
        <label className="p-d-block">Clawback Date</label>
        <LaInput
          keyName="clawbackDate"
          type="date"
          placeholder="clawbackDate"
          {...props}
        />
      </div>
    </>
  );
}

export default ClawbackDate;
