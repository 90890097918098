import { Dialog } from "primereact/dialog";
import React from "react";
import ViewCompanyJob from "./ViewCompanyJob";

function ViewJobDialog({ showModal, onCloseModal, data }) {
  return (
    <Dialog
      header="View Job"
      position="top"
      draggable={false}
      visible={showModal}
      style={{ width: "80vw" }}
      onHide={onCloseModal}
    >
      <div className="mt-4">
        <ViewCompanyJob rowData={data} />
      </div>
    </Dialog>
  );
}

export default ViewJobDialog;
