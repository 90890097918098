/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AiOutlineBars } from "react-icons/ai";
import { Tab, TabWrapper } from "../../components/Tab";
import CaseHeader from "../../components/CaseHeader";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { useDispatch, useSelector } from "react-redux";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import {
  getTenancyList,
  getHdrStatus,
} from "../../redux/actions/tenancyAction";
import { InputText } from "primereact/inputtext";

function ViewTenancyList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState("All");
  const [filtered, setFiltered] = useState([]);
  const { tenancyList, tenancyStatus } = useSelector(
    (state) => state.tenancySlice
  );

  const getHdrStatuses = async () => {
    await dispatch(getHdrStatus());
  };

  const getHdrs = async (status) => {
    await dispatch(
      getTenancyList(
        status !== "All"
          ? `tenancy/getTenancyCasesByStatus/${status}`
          : `tenancy/getTenancyCases`
      )
    );
  };

  const totalStatus = () => {
    let total = 0;
    tenancyStatus &&
      tenancyStatus.forEach(({ statusCount }) => {
        total += statusCount;
      });
    return total;
  };

  const actionsTemplate = ({ tenancyClaimCode }) => {
    return (
      <div
        title="Details"
        className="pointer"
        onClick={() => {
          navigate(`../tenancyDetails?tenancycode=${tenancyClaimCode}`);
        }}
      >
        <FiSettings />
      </div>
    );
  };

  useEffect(() => {
    getHdrStatuses();
  }, []);

  useEffect(() => {
    getHdrs(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    onGlobalFilterChange();
  }, [tenancyList]);

  const onGlobalFilterChange = (e = false) => {
    const keys = ["code", "client", "taskName", "taskDue", "created", "status"];
    const value = e?.target?.value;
    if (value) {
      const filteredList = tenancyList.filter((item) => {
        let flag = false;
        keys.forEach((key) => {
          if (
            key &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
            flag = true;
        });
        if (flag) return item;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(tenancyList);
    }
  };

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </span>
      </div>
    );
  };

  const header2 = renderHeader2();

  return (
    <>
      <CaseHeader
        title={"Tenancy Case List"}
        subTitle={"All claims, status: All"}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeader>
      <TabWrapper>
        <Tab
          title={"All"}
          count={String(totalStatus())}
          active={currentStatus === "All"}
          icon={<AiOutlineBars />}
          onClick={() => setCurrentStatus("All")}
        />
        {tenancyStatus.map(({ statusName, statusCount, statusCode }) => (
          <Tab
            key={statusCode}
            title={statusName}
            count={String(statusCount)}
            onClick={() => setCurrentStatus(statusCode)}
            active={currentStatus === statusCode}
            icon={<AiOutlineBars />}
          />
        ))}
      </TabWrapper>

      <div className="card">
        <DataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header={header2}
        >
          <Column field="code" sortable header="Code"></Column>
          <Column field="client" sortable header="Client"></Column>
          <Column field="taskName" sortable header="Task Name"></Column>
          <Column field="taskDue" sortable header="Task Due"></Column>
          <Column field="created" sortable header="Created"></Column>
          <Column field="status" sortable header="Status"></Column>
          <Column header="Acts" body={actionsTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
}

export default ViewTenancyList;
