import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { getHireDetails } from "../../../redux/actions/hireAction";
import "./CaseHeaderHire.css";
import { Divider } from "primereact/divider";
import { handlePostRequest } from "../../../services/PostTemplate";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import DuplicateCaseToHire from "./DuplicateCaseToHire";
import { SplitButton } from "primereact/splitbutton";
import HireExcel from "../HireExcel";
import CampaignDuplicateCounts from "../../../components/CampaignDuplicateCounts/CampaignDuplicateCounts";
import HireActionButtons from "./HireActionButtons/HireActionButtons";
import { InputTextarea } from "primereact/inputtextarea";

function CaseHeaderHire({ title, subTitle }) {
  const hireActionButtons = useSelector(
    (state) => state.hireSlice?.hire?.hireActionButtons
  );
  const createdon = useSelector((state) => state?.hireSlice?.hire?.createdon);
  const [copyCaseModal, setcopyCaseModal] = useState(false);
  const [copyCaseNumber, setcopyCaseNumber] = useState("");
  const [rtacode, setrtacode] = useState("");
  const [rejectModal, setrejectModal] = useState(false);
  const [reason, setreason] = useState("");
  const [rejectBtnValue, setrejectBtnValue] = useState("");
  const [loading, setloading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState("");

  const navigate = useNavigate();
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);

  const dispatch = useDispatch();
  const hireclaimcode = useSelector(
    (state) => state?.hireSlice?.hire?.hirecode
  );

  const breakpoints = { "960px": "75vw", "640px": "100vw" };
  const { hire } = useSelector((state) => state.hireSlice);

  const copyCase = async () => {
    const data = {
      hireCode: hireclaimcode,
    };
    try {
      const res = await handlePostRequest(data, "hire/copyHireToRta");
      // show modal with case number
      setcopyCaseModal(true);
      setcopyCaseNumber(res?.data?.rtanumber);
      setrtacode(res?.data?.rtacode);
    } catch (err) {
      toast.error("Error in Copying Case");
    }
  };

  const handleActionButton = async (value) => {
    if (value.caserejectdialog === "Y" || value?.caseacceptdialog === "Y") {
      // send reason on accept case as well
      setrejectModal(true);
      setrejectBtnValue(value.buttonvalue);
    } else {
      const data = {
        hireclaimcode,
        toStatus: value.buttonvalue,
      };
      try {
        await handlePostRequest(data, "hire/performActionOnHire");
        dispatch(getHireDetails(hireclaimcode));
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const copyCaseFooter = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          onClick={() => {
            navigate(`/rtadetails/${rtacode}`);
          }}
          label="Go to Rta"
          className="btn btn-info"
        />
        <Button
          onClick={() => {
            setcopyCaseModal(false);
            setcopyCaseNumber("");
          }}
          label="Close"
          className="btn btn-primary"
        />
      </center>
    </div>
  );

  const hireActionButtonForLA = useSelector(
    (state) => state?.hireSlice?.hire?.hireActionButtonForLA
  );
  let hireActionBtnitems = [];
  if (hireActionButtonForLA !== null && hireActionButtonForLA !== undefined) {
    hireActionButtonForLA.map((item) => {
      hireActionBtnitems.push({
        label: item?.buttonname,
        command: () => {
          handleActionButton(item);
        },
      });
    });
  }

  const handlRejectActionButton = async () => {
    setloading(true);
    setloadingIcon("pi pi-spin pi-spinner");
    const data = {
      hireclaimcode,
      toStatus: rejectBtnValue,
      reason,
    };
    await handlePostRequest(data, "hire/performActionOnHire");
    setrejectModal(false);
    setrejectBtnValue("");
    setloading(false);
    setloadingIcon("");
    dispatch(getHireDetails(hireclaimcode));
  };

  const Rejectfooter = (
    <div>
      <center className="p-mt-2 p-button-outlined">
        <Button
          icon={loadingIcon || ""}
          disabled={loading}
          onClick={handlRejectActionButton}
          label="Submit"
          className="btn btn-primary"
        />
      </center>
    </div>
  );

  const userCatInternal = user?.tblUsercategory?.categorycode;

  return (
    <>
      <div className="case-header">
        <div className="col-lg-4">
          <h6>{title}</h6>
        </div>
        <div style={{ display: "flex" }}>
          <div className="mt-2 p-mr-2">{createdon} </div>
          <Divider style={{ padding: "0px" }} layout="vertical" />
          <div>
            <Button
              onClick={(e) => {
                e.preventDefault();
              }}
              label={subTitle}
              style={{ cursor: "text" }}
              className="btn-info btn-sm p-mr-2 p-mb-2"
            />
          </div>
          <Divider style={{ padding: "0px" }} layout="vertical" />
          {/* <div>{actionButtons}</div> */}
          <HireActionButtons
            hireActionButtons={hireActionButtons}
            hireclaimcode={hireclaimcode}
          />
          <DuplicateCaseToHire />
          {user?.tblUsercategory?.categorycode === "4" ||
          user?.tblUsercategory?.categorycode === "1" ? (
            <Button
              onClick={(e) => {
                e.preventDefault();
                copyCase();
              }}
              label={"Copy Case To Rta"}
              className="btn-info btn-sm p-mr-2 p-mb-2"
            />
          ) : null}

          {hireActionButtonForLA !== null &&
          hireActionButtonForLA !== undefined ? (
            <div>
              <SplitButton
                icon="pi pi-sliders-h"
                model={hireActionBtnitems}
              ></SplitButton>
            </div>
          ) : (
            ""
          )}
        </div>

        <Dialog
          header="Action"
          visible={rejectModal}
          footer={Rejectfooter}
          onHide={() => setrejectModal(false)}
          breakpoints={breakpoints}
          style={{ width: "50vw" }}
        >
          <div className="p-mt-4">
            <InputTextarea
              className="Width__100"
              rows={5}
              cols={60}
              placeholder="Enter Reason "
              value={reason}
              onChange={(e) => setreason(e.target.value)}
            />
          </div>
        </Dialog>

        <Dialog
          header="Copy Case Success"
          visible={copyCaseModal}
          footer={copyCaseFooter}
          onHide={() => setcopyCaseModal(false)}
          breakpoints={breakpoints}
          style={{ width: "50vw" }}
        >
          <div className="p-mt-4">
            <h4>Case Number : {copyCaseNumber}</h4>
          </div>
        </Dialog>
      </div>
      {userCatInternal === "4" || userCatInternal === "2" ? (
        <HireExcel hire={hire} />
      ) : null}
      <CampaignDuplicateCounts
        code={hireclaimcode}
        viewDetailLink="hireDetails"
        caseId="hirecode"
        api="hire/getHireDuplicates"
      />
    </>
  );
}

export default CaseHeaderHire;
