import React from "react";
import { Button } from "primereact/button";
import "./JointAgreementTable.css";
import { calculate_age } from "../../../utilities/commonFunctions";

function JointAgreementTable({ data, onRemove, onEdit, onUpdate }) {
  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Age</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index}>
              <td>
                {item.firstname !== null ? item.firstname : " "}{" "}
                {item.middlename !== null ? item.middlename : ""}{" "}
                {item.lastname !== null ? item.lastname : ""}
              </td>
              <td>{calculate_age(item.dob)}</td>
              <td>
                <Button
                  onClick={() => onRemove(index)}
                  icon="pi pi-times"
                  disabled={onUpdate}
                  className="p-button-rounded p-button-danger p-button-text"
                  aria-label="Submit"
                />
              </td>
              <td>
                {onUpdate ? (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      onEdit(index);
                    }}
                    icon="pi pi-eye"
                    className="p-button-rounded p-button-primary p-button-text"
                    aria-label="Submit"
                  />
                ) : (
                  <Button
                    onClick={(e) => {
                      e.preventDefault();
                      onEdit(index);
                    }}
                    icon="pi pi-pencil"
                    className="p-button-rounded p-button-primary p-button-text"
                    aria-label="Submit"
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default JointAgreementTable;
