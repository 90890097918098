import { handleGetRequest } from "../../services/GetTemplate";
import {
    GETIMMIGRATIONSUCCESS,
    GETIMMIGRATIONERROR,
    GETIMMIGRATIONSTATUSSUCCESS,
    GETIMMIGRATIONSTATUSERROR,
    GETIMMIGRATIONLISTSUCCESS,
    GETIMMIGRATIONLISTERROR,
} from "../slices/immigrationSlice";
import { SETLOADING } from "../slices/loadingSlice";

export const getList = (statusUrl) => async (dispatch) => {
    dispatch(SETLOADING(true));
    const res = await handleGetRequest(statusUrl);
    if (res) {
        dispatch(GETIMMIGRATIONLISTSUCCESS(res?.data));
        dispatch(SETLOADING(false));
        return res;
    } else {
        dispatch(GETIMMIGRATIONLISTERROR(res?.data?.data));
        dispatch(SETLOADING(false));
    }
};

export const getImmigrationDetails = (id) => async (dispatch) => {
    dispatch(SETLOADING(true));
    const res = await handleGetRequest(`immigration/getImmigrationCaseById/${id}`);
    if (res) {
        dispatch(GETIMMIGRATIONSUCCESS(res?.data));
        dispatch(SETLOADING(false));
    } else {
        dispatch(GETIMMIGRATIONERROR(res?.data));
        dispatch(SETLOADING(false));
    }
};

export const getStatus = () => async (dispatch) => {
    dispatch(SETLOADING(true));
    const res = await handleGetRequest("immigration/getAllImmigrationStatusCounts");
    if (res) {
        dispatch(GETIMMIGRATIONSTATUSSUCCESS(res?.data));
        dispatch(SETLOADING(false));
        return res;
    } else {
        dispatch(GETIMMIGRATIONSTATUSERROR(res));
        dispatch(SETLOADING(false));
    }
};
