import React, { useState } from "react";
import { useParams } from "react-router-dom";
import CampaignDetails from "../../components/CampaignGeneric/CampaignDetails";
import { campaignsNames } from "../../components/CampaignGeneric/campaignConfig";
import { useDispatch, useSelector } from "react-redux";
import CaseHeaderPl from "./CaseHeaderPl";
import Pl from "./pl";
import { getPlDetails } from "../../redux/actions/plAction";

export default function OLDetails() {
  const { pl } = useSelector((state) => state.plSlice);
  const [isSubmitFormik, setisSubmitFormik] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { code } = useParams();
  const dispatch = useDispatch();

  const onSave = async () => {
    setisSubmitFormik(true);
  };

  const handleReturnEdit = async () => {
    setDisabled(true);
    dispatch(getPlDetails(code));
  };

  const onSetIsSubmitFormikFalse = () => {
    setisSubmitFormik(false);
  };

  const onCancel = () => {
    setDisabled(true);
  };

  return (
    <CampaignDetails
      campaign={pl}
      campaignName={campaignsNames.pl}
      campaignCode={code}
      onSave={onSave}
      onCancel={onCancel}
      getCampaignDetails={getPlDetails}
      disabled={disabled}
      setDisabled={setDisabled}
      CampaignForm={
        <Pl
          edit={true}
          onSave={onSave}
          isSubmitFormik={isSubmitFormik}
          handleReturnEdit={handleReturnEdit}
          onSetIsSubmitFormikFalse={onSetIsSubmitFormikFalse}
          showAccSubmissionCriteria={true}
        />
      }
      CaseHeader={
        <CaseHeaderPl title={pl?.olcode} subTitle={pl?.statusDescr} />
      }
    />
  );
}
