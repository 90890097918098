import { Button } from "primereact/button";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import EmailDetailDialog from "./EmailDetailDialog";
import { GiEnvelope } from "react-icons/gi";

function EmailDetailButton({ data }) {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };

  if (!data) {
    return null;
  }
  return (
    <>
      <button
        className="btn btn-primary mr-2"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <GiEnvelope /> Email Status
      </button>
      <EmailDetailDialog
        showModal={showModal}
        onCloseModal={onCloseModal}
        data={data}
      />
    </>
  );
}

export default EmailDetailButton;
