import { Dialog } from "primereact/dialog";
import React from "react";
import EditCompanyUsers from "./EditCompanyUsers";

function EditUserDialog({ showModal, onCloseModal, data, getuserList }) {
  return (
    <Dialog
      header="Edit User"
      position="top"
      draggable={false}
      visible={showModal}
      style={{ width: "80vw" }}
      onHide={onCloseModal}
    >
      <div className="mt-4">
        <EditCompanyUsers
          rowData={data}
          getuserList={getuserList}
          onCloseModal={onCloseModal}
        />
      </div>
    </Dialog>
  );
}

export default EditUserDialog;
