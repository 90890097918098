import React from "react";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";

function RtaExcel({ rta }) {
  const exportRtaToExcel = async () => {
    try {
      const res = await handleGetRequest(
        "report/getSingleRtaCaseReportXlsx/" + rta.rtacode
      );
      window.open(res?.data, "_blank");
    } catch (error) {
      console.error("Failed to fetch the report:", error);
    } finally {
    }
  };

  return (
    <Button
      onClick={exportRtaToExcel}
      className="btn btn-primary"
      style={{
        color: "white",
        fontWeight: "bold",
      }}
    >
      Export to Excel
    </Button>
  );
}

export default RtaExcel;
