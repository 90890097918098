import React from "react";
import { Button } from "primereact/button";

function MainInfo({ edit, formik }) {
  if (!edit) {
    return (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <h6
            style={{
              color: "#428BCA",
              fontWeight: "600",
              lineHeight: "1.42857143",
            }}
          >
            Main Info
          </h6>
          <small style={{ color: "#999" }}>Enter main information</small>
        </div>
        <div>
          <Button
            type="submit"
            icon="pi pi-arrow-circle-right"
            iconPos="right"
            className="btn btn-primary"
            label="Next"
            onClick={formik.handleSubmit}
          ></Button>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

export default MainInfo;
