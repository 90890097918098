import React from "react";
import WorkFlowData from "./WorkFlowData";

function WorkFlow() {
    return (
        <div>
            <WorkFlowData />
        </div>
    );
}

export default WorkFlow;
