import React, { useState } from "react";
import { RadioButton } from "primereact/radiobutton";
import LaInput from "../../../../../components/LaInput";
import { useFormik } from "formik";
import { handlePostRequest } from "../../../../../services/PostTemplate";
import { Button } from "primereact/button";
import * as Yup from "yup";
import LaInputTextArea from "../../../../../components/LaInputTextArea";
import { useDispatch } from "react-redux";
import { getHireDetails } from "../../../../../redux/actions/hireAction";
import LaDropdown from "../../../../../components/LaDropdown";
import LaStaticDropdown from "../../../../../components/LaStaticDropdown";

function HireAcceptForm({ buttonDetails, onCloseModal, hireclaimcode }) {
  const initialValues = {
    companyCode: "",
    service: "",
    vehicleType: "",
    bookingmode: "",
    bookingdate: "",
    hirestartdate: "",
    hireenddate: "",
    outsourced: "",
    outsourcedto: "",
    reason: "",
    message: "",
  };
  const [loading, setLoading] = useState(false);
  const [loadingIcon, setLoadingIcon] = useState("");
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object().shape({
      companyCode: Yup.string().required("required"),
      service: Yup.string().required("required"),
      vehicleType: Yup.string().required("required"),
      bookingmode: Yup.string().when("service", {
        is: (val) => ["HR", "HO"].includes(val),
        then: Yup.string().required("required"),
      }),
      // bookingdate: Yup.string().when("service", {
      //   is: (val) => ["HR", "HO", "RO"].includes(val),
      //   then: Yup.string().required("required"),
      // }),
      // hirestartdate: Yup.string().when("service", {
      //   is: (val) => ["HR", "HO", "RO"].includes(val),
      //   then: Yup.string().required("required"),
      // }),
      // hireenddate: Yup.string().when("service", {
      //   is: (val) => ["HR", "HO", "RO"].includes(val),
      //   then: Yup.string().required("required"),
      // }),
      outsourcedto: Yup.string().when("outsourced", {
        is: "Y",
        then: Yup.string().required("required"),
      }),
      reason: Yup.string().when("outsourced", {
        is: "Y",
        then: Yup.string().required("required"),
      }),
      message: Yup.string().when("service", {
        is: (val) => ["HR", "HO"].includes(val),
        then: Yup.string().required("required"),
      }),
    }),
    onSubmit: async (data) => {
      try {
        setLoading(true);
        setLoadingIcon("pi pi-spin pi-spinner");

        const note = data.message;
        const companyCode = data.companyCode;
        delete data["message"];
        delete data["companyCode"];
        const hireAcceptData = {
          hireclaimcode: hireclaimcode,
          note,
          companyCode,
          userCode: "",
          status: buttonDetails.buttonvalue,
          bussinessStatus: "A",
          hireBusinessDetailRequest: { ...data },
        };
        await handlePostRequest(
          hireAcceptData,
          "hire/changeHireBusinessStatus"
        );
        setLoadingIcon("");
        onCloseModal();
        dispatch(getHireDetails(hireclaimcode));
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setLoadingIcon("");
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };
  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  return (
    <>
      <div className="p-fluid p-formgrid p-grid">
        <div className="p-field p-col-12 p-md-12 p-mt-4">
          <label>Hire company Accept</label>
          <LaDropdown
            keyName="companyCode"
            optionLabel="name"
            placeholder="Select Hire Company"
            url={`lovHireBusinessAccept/${hireclaimcode}`}
            {...props}
          />
        </div>
        <div className="p-col-6 p-mt-2">
          <label className="p-d-block">Vehicle Type</label>
          <LaStaticDropdown
            keyName="vehicleType"
            optionLabel="label"
            options={[
              { value: "S", label: "Standard" },
              { value: "P", label: "Prestige" },
              { value: "T", label: "Taxi" },
            ]}
            {...props}
          />
        </div>
        <div className="p-field p-col-12 p-md-12 mt-4">
          <label>Is this for</label>
          <div className="p-field-radiobutton p-d-flex">
            <RadioButton
              inputId="service"
              name="service"
              value="HR"
              onChange={formik.handleChange}
              checked={formik.values.service === "HR"}
            />
            <label>Hire & Repair</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <RadioButton
              inputId="service"
              name="service"
              value="HO"
              onChange={formik.handleChange}
              checked={formik.values.service === "HO"}
            />
            <label>Hire Only </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <RadioButton
              inputId="service"
              name="service"
              value="RO"
              onChange={formik.handleChange}
              checked={formik.values.service === "RO"}
            />
            <label>Repair Only </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <RadioButton
              inputId="service"
              name="service"
              value="TL"
              onChange={formik.handleChange}
              checked={formik.values.service === "TL"}
            />
            <label>Total Loss</label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <RadioButton
              inputId="service"
              name="service"
              value="VD"
              onChange={formik.handleChange}
              checked={formik.values.service === "VD"}
            />
            <label>Only VD </label>
          </div>
        </div>

        {formik.values.service === "HR" ||
        formik.values.service === "HO" ||
        formik.values.service === "TL" ? (
          <div className="p-field p-col-12 p-md-12">
            <label>Mode</label>
            <div className="p-field-radiobutton p-d-flex">
              <RadioButton
                inputId="bookingmode"
                name="bookingmode"
                value="D"
                onChange={formik.handleChange}
                checked={formik.values.bookingmode === "D"}
              />
              <label>Driveable</label>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <RadioButton
                InputId="bookingmode"
                name="bookingmode"
                value="N"
                onChange={formik.handleChange}
                checked={formik.values.bookingmode === "N"}
              />
              <label>Non-Driveable</label>
            </div>
            {getFormErrorMessage("bookingmode")}
          </div>
        ) : null}

        {formik.values.service === "HR" ||
        formik.values.service === "HO" ||
        formik.values.service === "RO" ? (
          <>
            <div className="p-field p-col-12 p-md-4">
              <label>Booking in date</label>

              <LaInput
                keyName="bookingdate"
                {...props}
                type="date"
                placeholder="Date"
              />
            </div>
            <div className="p-field p-col-12 p-md-4">
              <label>Hire start date</label>
              <LaInput
                keyName="hirestartdate"
                {...props}
                type="date"
                placeholder="Date"
              />
            </div>
            <div className="p-field p-col-12 p-md-4">
              <label>Hire end date</label>
              <LaInput
                keyName="hireenddate"
                type="date"
                placeholder="Date"
                {...props}
              />
            </div>
          </>
        ) : null}

        {formik.values.service === "HR" || formik.values.service === "HO" ? (
          <>
            <div className="p-field p-col-12 p-md-12">
              <label>Was this Case Outsourced?</label>
              <div className="p-field-radiobutton p-d-flex">
                <RadioButton
                  inputId="outsourced"
                  name="outsourced"
                  value="Y"
                  onChange={formik.handleChange}
                  checked={formik.values.outsourced === "Y"}
                />
                <label>Yes</label>
                &nbsp;&nbsp;&nbsp;&nbsp;
                <RadioButton
                  inputId="outsourced"
                  name="outsourced"
                  value="N"
                  onChange={formik.handleChange}
                  checked={formik.values.outsourced === "N"}
                />
                <label>No</label>
              </div>
            </div>
            {formik.values.outsourced === "Y" ? (
              <>
                <div className="p-field p-col-12 p-md-6">
                  <label>Outsourced to</label>
                  <LaInput keyName="outsourcedto" {...props} />
                </div>
                <div className="p-field p-col-12 p-md-6">
                  <label>Outsourced reason</label>
                  <LaInput keyName="reason" {...props} />
                </div>
              </>
            ) : null}
          </>
        ) : null}

        {formik.values.service === "HR" || formik.values.service === "HO" ? (
          <div className="p-field p-col-12 p-md-12">
            <label>Notes</label>
            <LaInputTextArea keyName="message" {...props} rows={5} cols={30} />
          </div>
        ) : null}
      </div>
      <div className="p-d-flex p-jc-center p-mt-4">
        <Button
          icon={loadingIcon || ""}
          onClick={formik.handleSubmit}
          disabled={loading}
          label="Submit"
          className="btn-info btn-sm p-mr-2 p-mb-2"
        />
      </div>
    </>
  );
}

export default HireAcceptForm;
