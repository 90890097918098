import React from "react";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { nameTitleOptions } from "../../utilities/constants";

function TitleDropdown({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
}) {
  return (
    <>
      <Dropdown
        id={keyName}
        placeholder={placeholder ?? "Select Title"}
        name={keyName}
        value={values[keyName]}
        options={nameTitleOptions}
        optionLabel="name"
        onChange={onChange}
        className={classNames({
          "p-invalid": isFormFieldValid(keyName.toString()),
        })}
      />
      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default TitleDropdown;
