import React, { useState } from "react";
import { FiMaximize2, FiMinimize2 } from "react-icons/fi";
import styled from "styled-components";

export const ChatboxContainer = styled.div`
  width: 100%;
  height: 750px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  transition: height 0.3s ease;
`;

const ToggleButton = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
`;

function ResizeNotes({ setChatboxHeight }) {
  const [isResized, setIsResized] = useState(false);

  const handleToggleResize = () => {
    setIsResized(!isResized);
    setChatboxHeight(isResized ? 460 : 600);
  };

  return (
    <div>
      <ToggleButton onClick={handleToggleResize}>
        {isResized ? <FiMinimize2 /> : <FiMaximize2 />}
      </ToggleButton>
    </div>
  );
}

export default ResizeNotes;
