import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "invoiceSlice",
  initialState: {
    invoiveList: [],
  },
  reducers: {
    GETINVOICELISTSUCCESS: (state, action) => {
      return {
        ...state,
        invoiceList: action.payload,
      };
    },
    GETINVOICELISTERROR: (state) => {
      return {
        ...state,
        invoiceList: [],
      };
    },
  },
});

export const { GETINVOICELISTSUCCESS, GETINVOICELISTERROR } = slice.actions;
export default slice.reducer;
