import React, { useState } from "react";
import { ImUpload2 } from "react-icons/im";
import Accordion from "../../components/Accordion";
import { FormWrapper } from "../../components/FormLayout/FormHeader";
import {
  LargeTab,
  LargeTabWrapper,
} from "../../components/FormLayout/FormHeader/LargeTab";
import FormMainBody from "../../components/FormLayout/FormMainBody/index";
import UploadModal from "../../components/UploadModal";
import Messages from "../../components/Messages";
import Notes from "../../components/Notes";
import { useDispatch, useSelector } from "react-redux";
import Multimedia from "../../components/Multimedia";
import HireEdit from "./HireEdit";
import { getHireDetails } from "../../redux/actions/hireAction";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import NotesLegalInternal from "../../components/Notes/NotesLegalInternal";
import Logs from "../../components/Logs";

function ViewHireForm({ hrCode }) {
  const [active, setActive] = useState(0);
  const [showUploadModal, setshowUploadModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [isSubmitFormik, setisSubmitFormik] = useState(false);
  const dispatch = useDispatch();
  const hire = useSelector((state) => state?.hireSlice?.hire);

  const messages = useSelector(
    (state) => state.notesSlice?.notes?.tblHirenotes
  );
  const internalMessages = useSelector(
    (state) => state.notesSlice?.notes?.tblHirenotesLegalOnly
  );

  const uploadAction = (
    <div
      className="text-semibold pointer"
      onClick={() => setshowUploadModal(true)}
    >
      <ImUpload2 /> &nbsp; Upload
    </div>
  );

  const getDetails = async () => {
    await dispatch(getHireDetails(hrCode));
  };

  const onSave = async () => {
    setisSubmitFormik(true);
  };

  const onCancel = () => {
    setDisabled(true);
  };

  const handleReturnEdit = async () => {
    setDisabled(true);
  };

  const onSetIsSubmitFormikFalse = () => {
    setisSubmitFormik(false);
  };

  React.useEffect(() => {
    getDetails();
  }, [hrCode]);

  const handlePrintForm = () => {
    const input = document.getElementById("printable");
    html2canvas(input).then((canvas) => {
      var imgData = canvas.toDataURL("image/png");
      var imgWidth = 210;
      var pageHeight = 295;
      var imgHeight = (canvas.height * imgWidth) / canvas.width;
      var heightLeft = imgHeight;
      var doc = new jsPDF("p", "mm");
      var position = 0;
      doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;
      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        doc.addPage();
        doc.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      doc.save(`hire${hrCode}.pdf`);
    });
  };

  return (
    <FormWrapper>
      <div id="printable">
        <LargeTabWrapper>
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(0)}
            active={active === 0}
            title="Main"
          />
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(1)}
            active={active === 1}
            title={`Images & Videos (${hire?.tblHiredocuments?.length || 0})`}
          />
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(2)}
            active={active === 2}
            title="Notes"
          />
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(3)}
            active={active === 3}
            title="Messages"
          />
          <LargeTab
            Icon="Icon"
            onClick={() => setActive(4)}
            active={active === 4}
            title="Logs"
          />
        </LargeTabWrapper>
        {active === 0 && (
          <FormMainBody
            isEdit={!disabled}
            setIsEdit={(edit) => {
              setDisabled(!edit);
            }}
            disabledForm={String(disabled)}
            title="Case Info"
            onSave={onSave}
            onCancel={onCancel}
            editFlag={hire?.editFlag}
          >
            <HireEdit
              edit={true}
              onSave={onSave}
              isSubmitFormik={isSubmitFormik}
              handleReturnEdit={handleReturnEdit}
              onSetIsSubmitFormikFalse={onSetIsSubmitFormikFalse}
            />

            <center className="mt-4">
              <button
                onClick={handlePrintForm}
                id="print-btn"
                className="btn btn-block btn-primary "
              >
                Print Claimant Form
              </button>
            </center>
          </FormMainBody>
        )}
        {active === 1 && (
          <>
            <Accordion title="Images" headerRightArea={uploadAction} open>
              <Multimedia documents={hire?.tblHiredocuments || []} />
            </Accordion>
          </>
        )}
        <UploadModal
          show={showUploadModal}
          hide={(show) => setshowUploadModal(show)}
          addFilesService={"/hire/addHireDocument"}
          codeName="hireClaimCode"
          codeValue={hrCode}
          returnSubmit={getDetails}
        />
        {active === 2 && (
          <Accordion title="Notes" open>
            <NotesLegalInternal internalMessages={internalMessages} />
            <Notes
              getNotesService={"/hire/getHireCaseNotes"}
              addNotesService={"/hire/addHireNotes"}
              caseType={"hire"}
              code={hrCode}
              messages={messages}
            />
          </Accordion>
        )}
        {active === 3 && (
          <Accordion title="Messages" open>
            <Messages
              code={hrCode}
              url={"hire/getHireCaseMessages/"}
              posturl={"hire/resendHireEmail"}
              compainName={"hire"}
            />
          </Accordion>
        )}
        {active === 4 && (
          <Accordion title="Logs" open>
            <Logs api="hire/getHireAuditLogs" caseCode={hrCode} />
          </Accordion>
        )}
      </div>
    </FormWrapper>
  );
}

export default ViewHireForm;
