import { handleGetRequest } from "../../services/GetTemplate";
import { handlePostRequest } from "../../services/PostTemplate";
import {
  GETINVOICELISTERROR,
  GETINVOICELISTSUCCESS,
} from "../slices/invoiceSlice";

export const getInvoiceList = (status) => async (dispatch) => {
  try {
    const res = await handleGetRequest(
      status !== "All"
        ? `invoicing/getInvoicesByStatus/${status}`
        : `invoicing/getInvoices`
    );
    dispatch(GETINVOICELISTSUCCESS(res?.data));
  } catch (err) {
    dispatch(GETINVOICELISTERROR(err));
  }
};

export const getFilteredInvoiceList = (data) => async (dispatch) => {
  try {
    const res = await handlePostRequest(data, "invoicing/invoiceSearch");
    dispatch(GETINVOICELISTSUCCESS(res?.data));
  } catch (err) {
    dispatch(GETINVOICELISTERROR(err));
  }
};
