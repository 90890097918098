import React from "react";
import "./SideBoxSection.css";
import PropTypes from "prop-types";
import { FaArrowAltCircleDown, FaArrowAltCircleUp } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";

export default function SideBox({ title, children, className }) {
  const [isOpen, setIsOpen] = React.useState(true);
  return (
    <div className={`side-box ${className}`}>
      <header className="sideBox-header">
        <div>{title ? title : null}</div>
        <div
          className="pointer"
          onClick={() => {
            setIsOpen((active) => !active);
          }}
        >
          {isOpen ? <FaArrowAltCircleUp /> : <FaArrowAltCircleDown />}
        </div>
      </header>
      <AnimatePresence>
        {isOpen && (
          <motion.main
            // // key={isOpen}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: "100%" }}
            exit={{
              opacity: 0,
              height: 0,
              transition: {
                duration: 0.25,
                ease: "easeIn",
                type: "tween",
                stiffness: 100,
                damping: 15,
              },
            }}
            transition={{
              ease: "easeOut",
              type: "spring",
              stiffness: 100,
              damping: 15,
            }}
            className="sideBox-Body"
          >
            {children}
          </motion.main>
        )}
      </AnimatePresence>
    </div>
  );
}

function SideBoxWrapper({ children , className})  {
  return <div className={`side-box_section ${className}`}>{children}</div>;
}

export { SideBox, SideBoxWrapper };

React.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
};
