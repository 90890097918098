import { notesService } from "../../services/Notes";
import { SETLOADING } from "../slices/loadingSlice";
import { GETNOTESSUCCESS, GETNOTESERROR } from "../slices/notesSlice";
import store from "../store";

export const getNotes = (getNotesService, code) => async (dispatch) => {
  try {
    dispatch(GETNOTESERROR({})); // Reset notes
    store.dispatch(SETLOADING(true));
    const res = await notesService.getNotes(getNotesService, code);
    dispatch(GETNOTESSUCCESS(res));
  } catch (err) {
    dispatch(GETNOTESERROR(err));
  } finally {
    store.dispatch(SETLOADING(false));
  }
};

export const addNotes = (addNotesService, note) => async (dispatch) => {
  const res = await notesService.addNotes(addNotesService, note);
  return res;
};
