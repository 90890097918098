import React, { useRef, useState, useEffect } from "react";
import { Button } from "primereact/button";
import SignaturePad from "react-signature-canvas";
import styled from "styled-components";

function SignatureDraw({ onSubmit }) {
  const sigPad = useRef();
  const [loading, setLoading] = useState(false);
  const [canvasWidth, setCanvasWidth] = useState("300rem"); // Increased canvas width
  const [canvasHeight, setCanvasHeight] = useState("300px"); // Increased canvas height
  const loadingIcon = "pi pi-spin pi-spinner";

  const clear = () => {
    sigPad.current.clear();
  };

  const handleDrawSubmit = async () => {
    setLoading(true);
    const signatureDataURL = sigPad.current
      .getTrimmedCanvas()
      .toDataURL("image/png")
      .replace("data:image/png;base64,", "");

    await onSubmit(signatureDataURL);
    setLoading(false);
  };

  useEffect(() => {
    const updateCanvasSize = () => {
      setCanvasWidth(`${window.innerWidth / 2}rem`); // Adjusted canvas width
      setCanvasHeight(`${window.innerHeight / 2}px`); // Adjusted canvas height
    };

    // Set initial canvas size
    updateCanvasSize();

    // Update canvas size when the window is resized
    window.addEventListener("resize", updateCanvasSize);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateCanvasSize);
    };
  }, []);

  return (
    <div align="center" className="mt-4">
      <SignaturePadArea style={{ width: "min-content", height: "min-content" }}>
        <SignaturePad
          disable={loading}
          penColor="black"
          penWidth={0.25} // Reduced pen width (less bold)
          canvasProps={{
            className: "sigCanvas",
            width: canvasWidth,
            height: canvasHeight,
          }}
          ref={sigPad}
        />
      </SignaturePadArea>
      <div className="d-flex justify-content-center mt-4">
        <Button
          label="Clear"
          disabled={loading}
          className="btn btn-primary"
          onClick={clear}
        />
        <Button
          label="Submit"
          className="p-ml-2 btn btn-secondary"
          onClick={handleDrawSubmit}
          disabled={loading}
          icon={loading && loadingIcon}
        />
      </div>
    </div>
  );
}

const SignaturePadArea = styled.div`
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
`;

export default SignatureDraw;
