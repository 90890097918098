import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AiOutlineBars } from "react-icons/ai";
import { Tab, TabWrapper } from "../../components/Tab";
import CaseHeader from "../../components/CaseHeader";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHdrList, getHdrStatus } from "../../redux/actions/hdrAction";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import { AiOutlineExport, AiOutlineRight } from "react-icons/ai";
import { Chip } from "primereact/chip";
import HdrFilterCaseListButton from "./HdrFilterCaseList/HdrFilterCaseListButton";

function ViewHdr() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState("All");
  const [filtered, setFiltered] = useState([]);
  const { hdrList, hdrStatus } = useSelector((state) => state.hdrSlice);
  const [expandedRows, setExpandedRows] = useState();
  const [loading, setLoading] = useState(false);

  const sortDate = (e) => {
    let hdrData = [...hdrList];
    return hdrData.sort((a, b) => {
      if (e.order === 1) return new Date(b.created) - new Date(a.created);
      return new Date(a.created) - new Date(b.created);
    });
  };

  const getHdrStatuses = async () => {
    await dispatch(getHdrStatus());
  };

  const getHdrs = async (status) => {
    setLoading(true);
    await dispatch(
      getHdrList(
        status !== "All"
          ? `hdr/getHdrCasesByStatus/${status}`
          : `hdr/getHdrCases`
      )
    );

    setLoading(false);
  };

  const totalStatus = () => {
    let total = 0;
    hdrStatus &&
      hdrStatus.forEach(({ statusCount }) => {
        total += statusCount;
      });
    return total;
  };

  const actionsTemplate = ({ hdrClaimCode }) => {
    const newTablink = `../#/hdrDetails/${hdrClaimCode}`;
    const link = `../hdrDetails/${hdrClaimCode}`;

    return (
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigate(link);
          }}
        >
          <StyledIcon>
            <AiOutlineRight />
          </StyledIcon>
          Open
        </Button>
        <Button
          onClick={() => {
            window.open(newTablink, "_blank");
          }}
        >
          <StyledIcon>
            <AiOutlineExport />
          </StyledIcon>
          Open in a new Tab
        </Button>
      </ButtonsContainer>
    );
  };

  const rowExpansionTemplate = (data) => {
    return (
      <div className="expanded-card">
        <div className="p-grid">
          <div className="p-col-6">
            <div className="box">
              <strong>Address : </strong>
              <p>{data?.address}</p>
            </div>
            <div className="box">
              <strong>Tel : </strong>
              <p>{data?.contactNo}</p>
            </div>
            <div className="box">
              <strong>Email : </strong>
              <p>{data?.email}</p>
            </div>
          </div>
          <div className="p-col-6">
            <div className="box">
              <strong>Introducer : </strong>
              <p>{data?.introducer}</p>
            </div>
            <div className="box">
              <strong>Last Note : </strong>
              <p>{data?.lastNote}</p>
            </div>
            <div className="box">
              <strong>Last Updated : </strong>
              <p>{data?.lastUpdated}</p>
              <p></p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getHdrStatuses();
  }, []);

  useEffect(() => {
    getHdrs(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    onGlobalFilterChange();
  }, [hdrList]);

  const onGlobalFilterChange = (e = false) => {
    const keys = [
      "hdrnumber",
      "client",
      "taskName",
      "contactdue",
      "created",
      "status",
      "hdrnumber",
    ];
    const value = e?.target?.value;
    if (value) {
      const filteredList = hdrList.filter((item) => {
        let flag = false;
        keys.forEach((key) => {
          if (
            key &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
            flag = true;
        });
        if (flag) return item;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(hdrList);
    }
  };

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText onChange={onGlobalFilterChange} placeholder="Search" />
        </span>
      </div>
    );
  };

  const header2 = renderHeader2();

  const statusTemplate = (rowData) => {
    if (rowData?.status === "Rejected" || rowData?.status === "New") {
      return (
        <center>
          <StatusChip status={rowData?.status}>{rowData?.status}</StatusChip>
        </center>
      );
    } else {
      return (
        <center>
          <Chip label={rowData?.status} className="p-px-4 custom-chip" />
        </center>
      );
    }
  };

  return (
    <>
      <CaseHeader
        title={"HDR Case List"}
        subTitle={"All claims, status: All"}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeader>
      <HdrFilterCaseListButton />
      <TabWrapper>
        <Tab
          title={"All"}
          count={String(totalStatus())}
          active={currentStatus === "All"}
          icon={<AiOutlineBars />}
          onClick={() => setCurrentStatus("All")}
        />
        {hdrStatus &&
          hdrStatus.map(({ statusName, statusCount, statusCode }) => (
            <Tab
              key={statusCode}
              title={statusName}
              count={String(statusCount)}
              onClick={() => setCurrentStatus(statusCode)}
              active={currentStatus === statusCode}
              icon={<AiOutlineBars />}
            />
          ))}
      </TabWrapper>

      <div className="card">
        <DataTable
          value={filtered || []}
          expandedRows={expandedRows}
          dataKey="hdrClaimCode"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
          showGridlines
          responsiveLayout="scroll"
          header={header2}
          paginator
          rows={10}
          emptyMessage
          loading={loading}
        >
          <Column
            expander
            style={{ width: "2.5rem" }}
            filterMatchMode="contains"
          ></Column>
          <Column
            field="created"
            sortable
            sortFunction={sortDate}
            header="Created On"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="code"
            sortable
            header="Code"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="client"
            sortable
            header="Client Name"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="taskDue"
            sortable
            header="Contact Due"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            field="taskName"
            sortable
            header="Current Task"
            filter
            filterMatchMode="contains"
          ></Column>

          <Column
            field="status"
            body={statusTemplate}
            sortable
            header="Status"
            filter
            filterMatchMode="contains"
          ></Column>
          <Column
            header="Acts"
            body={actionsTemplate}
            filter
            filterMatchMode="contains"
          ></Column>
        </DataTable>
      </div>
    </>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const StyledIcon = styled.span`
  margin-right: 5px;
`;

const StatusChip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px; // This is shorthand for padding-top, padding-right, padding-bottom, padding-left
  font-size: 12px;
  width: 100px;
  color: white;
  background-color: ${({ status }) => getStatusColor(status)};
`;

const getStatusColor = (status) => {
  switch (status) {
    case "New":
      return "#2b9f2b"; // Green for 'New'
    case "Rejected":
      return "#df4848"; // Original Red for 'Rejected'
    default:
      return "#777777"; // Default gray for other statuses
  }
};

export default ViewHdr;
