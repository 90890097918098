import { hdrService } from "../../services/Hdr";
import { handlePostRequest } from "../../services/PostTemplate";
import {
  GETDBSUCCESS,
  GETDBERROR,
  GETDBSTATUSSUCCESS,
  GETDBSTATUSERROR,
  GETDBLISTSUCCESS,
  GETDBLISTERROR,
} from "../slices/dbSlice";
import { handleGetRequest } from "../../services/GetTemplate";

export const getDbList = (statusUrl) => async (dispatch) => {
  const res = await handleGetRequest(statusUrl);
  if (res) {
    dispatch(GETDBLISTSUCCESS(res?.data));
    return res;
  } else {
    dispatch(GETDBLISTERROR(res?.data?.data));
  }
};

export const getDbDetails = (dbCaseId) => async (dispatch) => {
  const res = await handleGetRequest(`db/getDbCaseById/${dbCaseId}`);
  if (res) {
    dispatch(GETDBSUCCESS(res?.data));
  } else {
    dispatch(GETDBERROR(res?.data));
  }
};
export const getDbStatus = () => async (dispatch) => {
  const res = await handleGetRequest("db/getAllDbStatusCounts");
  if (res) {
    dispatch(GETDBSTATUSSUCCESS(res?.data));
    return res;
  } else {
    dispatch(GETDBSTATUSERROR(res));
  }
};

export const postDbDocs = (docs) => async (url) => {
  const res = await hdrService.postHdrDocs(docs, url);
  if (res) {
    return res;
  }
  return false;
};

export const addDbCase = (data) => async (dispatch) => {
  const res = await handlePostRequest(data, "hdr/addNewHdrCase");
  return res;
};
