import React from "react";
import LaInput from "../../../components/LaInput";
import LaStaticDropdown from "../../../components/LaStaticDropdown";

function OicInjuryDetails({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };
  return (
    <>
      <div style={{ marginLeft: "30px", marginTop: "20px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>Injury Details</h6>
        <hr className="border__hr" />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Client's Circumstances Severe?</label>
        <LaStaticDropdown
          keyName="clientCircumstancesSevereYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Reason</label>
        <LaInput keyName="explanationReason" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Time Off Work?</label>
        <LaStaticDropdown
          keyName="timeOffWorkYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Still Off Work?</label>
        <LaStaticDropdown
          keyName="stillOffWorkYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Total Days Off Work</label>
        <LaInput keyName="totalDaysOffWork" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Physiotherapy?</label>
        <LaStaticDropdown
          keyName="physiotherapyYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Did Client Attend Hospital?</label>
        <LaStaticDropdown
          keyName="clientAttendHospitalYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Hospital Name</label>
        <LaInput keyName="hospitalName" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Hospital Nights</label>
        <LaInput keyName="hospitalNights" {...props} />
      </div>
    </>
  );
}

export default OicInjuryDetails;
