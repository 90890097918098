import React from "react";
import { AiOutlineFileText } from "react-icons/ai";
import { FaDownload } from "react-icons/fa";
import styled from "styled-components";

const DownloadButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  color: #007bff;
  cursor: pointer;
`;

const StyledDocName = styled.span`
  padding-right: 5px;
`;

const StyledEsignContainer = styled.div`
  background-color: yellow; /* Add your desired highlight color here */
  padding: 5px;
`;

function EsignMedia({ docurl }) {
  return (
    <DownloadButton
      onClick={() =>
        window.open(docurl?.replaceAll("\\", "//"), "_blank", "download")
      }
    >
      <StyledEsignContainer>
        <StyledDocName>
          <AiOutlineFileText />
          {docurl?.replaceAll("\\", "//").split("/").pop()}
        </StyledDocName>
      </StyledEsignContainer>
      <FaDownload color="#007bff" />
    </DownloadButton>
  );
}

export default EsignMedia;
