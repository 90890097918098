const minordetails = {
  gtitle: "",
  gfirstname: "",
  gmiddlename: "",
  glastname: "",
  gdob: "",
  gpostalcode: "",
  gemail: "",
  gaddress1: "",
  gaddress2: "",
  gaddress3: "",
  gcity: "",
  gregion: "",
};

const mainClaimantConstants = {
  caddress1: "",
  caddress2: "",
  caddress3: "",
  ccity: "",
  ccontacttime: "",
  cdob: "",
  cemail: "",
  cfname: "",
  clandline: "",
  cmobileno: "",
  cninumber: "",
  cpostcode: "",
  cregion: "",
  csname: "",
  cmname: "",
  ctitle: "",
  postalcode: "",
};

const jointTenancyConstants = {
  taddress1: "",
  taddress2: "",
  taddress3: "",
  tcity: "",
  tcontacttime: "",
  tdob: "",
  temail: "",
  tfname: "",
  tlandline: "",
  tmname: "",
  tmobileno: "",
  tninumber: "",
  tpostcode: "",
  tregion: "",
  tsname: "",
  ttitle: "",
  postalcode: "",
};

const hdrClaimConstants = {
  aprxReporteddate: "",
  defectLastreportedon: "",
  defectFirstreportedon: "",
  defectReported: "",
  healthAffected: "",
  healthRelDetails: "",
  llContactno: "",
  llName: "",
  llRespondDetail: "",
  llResponded: "",
  reportedToLl: "",
  reportedToLlBy: "",
};

const hdrTenancy = {
  arrearsAmount: "",
  benefitsDetail: "",
  contributePm: "",
  contributePw: "",
  inArrears: "",
  inPaymentPlan: "",
  noOfOccupants: "",
  paymentPlanPm: "",
  paymentPlanPw: "",
  propertyType: "",
  receivingBenefits: "",
  rentContribute: "",
  rentPm: "",
  rentPw: "",
  startDate: "",
};

const hdraffectedperList = {
  evidenceDetail: "",
  medicalEvidence: "",
  persondob: "",
  personname: "",
  sufferingFrom: "",
};

const hdrAffectedRoomList = {
  damageList: "",
  disrepairDetail: "",
  lastReported: "",
  personalPropertydamage: "",
  reportDetails: "",
  roomName: "",
};

const nameTitleOptions = [
  { name: "Mr", value: "Mr" },
  { name: "Mrs", value: "Mrs" },
  { name: "Miss", value: "Miss" },
  { name: "Ms", value: "Ms" },
  { name: "Mstr", value: "Mstr" },
  { name: "DR", value: "DR" },
  { name: "Prof", value: "Prof" },
  { name: "Rev", value: "Rev" },
];

export {
  mainClaimantConstants,
  jointTenancyConstants,
  hdrTenancy,
  hdrClaimConstants,
  hdraffectedperList,
  hdrAffectedRoomList,
  minordetails,
  nameTitleOptions,
};
