import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { handleGetRequest } from "../../services/GetTemplate";

function LaDropdownLink({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  optionLabel,
  valueOption,
  url,
  linkId,
}) {
  const [options, setOptions] = useState([]);
  const [loading, setloading] = useState(false);
  const placeholderMgs = loading ? "Loading..." : placeholder || "Select";

  const getDropdownOptions = async () => {
    setloading(true);
    try {
      const res = await handleGetRequest(url + "/" + linkId, false);
      const options = res.data.map((item) => {
        return {
          label: item[optionLabel],
          value: item[valueOption],
        };
      });
      setOptions(options);
    } catch (err) {
      setOptions([]);
      console.log(err);
    } finally {
      setloading(false);
    }
  };

  useEffect(() => {
    if (linkId !== undefined && linkId !== null && linkId !== "") {
      getDropdownOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [linkId]);

  return (
    <>
      <Dropdown
        disabled={loading}
        id={keyName}
        placeholder={placeholderMgs}
        name={keyName}
        value={values[keyName]}
        options={options}
        onChange={onChange}
        className={classNames({
          "p-invalid": isFormFieldValid(keyName.toString()),
          Width__100: true,
        })}
      />
      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default LaDropdownLink;
