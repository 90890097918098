import React, { useEffect } from "react";
import LaVehicleReg from "../../../components/LaVehicleReg";

function ClaimantVehicle({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    classNames,
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };

  useEffect(() => {
    if (values.vehicleType === "P") {
      formik.setFieldValue("registerationno", "");
      formik.setFieldValue("makemodel", "");
      formik.setFieldValue("yearofmanufacture", "");
      formik.setFieldValue("insurer", "");
      formik.setFieldValue("policyno", "");
      formik.setFieldValue("refno", "");
    }
  }, [values.vehicleType]);

  const disabled = values.vehicleType === "P" ? true : false;

  const vehicleRegProps = {
    ...props,
    keyNameRegNo: "registerationno",
    keyNameMakeModel: "makemodel",
    keyNameYearOfManufacture: "yearofmanufacture",
    keyNameInsurer: "insurer",
    keyNamePolicyNo: "policyno",
    keyNameRefNo: "refno",
    disabled,
  };

  return (
    <>
      <div style={{ marginLeft: "30px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
          Claimant Vehicle
        </h6>
      </div>
      <LaVehicleReg {...vehicleRegProps} setVehicleType />
    </>
  );
}

export default ClaimantVehicle;
