import React, { useState, useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import { MultiSelect } from "primereact/multiselect";
import { useSelector, useDispatch } from "react-redux";
import { getLovRole } from "../../services/UsersRegister";
import { PostUsersFreshAction } from "../.../../../redux/actions/profileAction";
import UsersData from "./UsersData";

function Users() {
  const [userName, setuserName] = useState("");
  const [userRole, setuserRole] = useState([]);
  const [userRoleValue, setuserRoleValue] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState("");
  const [username, setusername] = useState("");
  let states = [
    {
      code: "Y",
      name: "Active",
      type: null,
    },
    {
      code: "N",
      name: "Inactive",
      type: null,
    },
  ];

  async function funcgetLovRole() {
    const res = await getLovRole();
    setuserRole(res.data);
  }

  const onStateChange = (e) => {
    setSelectedState(e.value);
  };

  const CompanyCode = useSelector(
    (state) => state.profileSlice.profileData?.companycode
  );
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    setloading(true);
    setloadingIcon("pi pi-spin pi-spinner");
    const userRolevalues = userRoleValue.map((role) => {
      return role.code;
    });
    console.log(userRolevalues, "userRolevalues");
    console.log(userRoleValue, "userRoleValue");
    const data = {
      companycode: CompanyCode,
      loginid: userName,
      status: selectedState.code,
      rolecodes: userRolevalues,
      username: username,
    };
    try {
      // await dispatch(PostUsersFreshAction(data));
      // setDisplayBasic(!displayBasic);
      // setInitialValues();
      // setloading(false);
      // setloadingIcon("");
    } catch (error) {
      setloading(false);
      setloadingIcon("");
    }
  };
  const setInitialValues = () => {
    setuserName("");
    setuserRoleValue("");
    setSelectedState("");
  };

  useEffect(() => {
    funcgetLovRole();
  }, []);

  return (
    <div>
      <div className="mb-2" align="right">
        <Button
          className="btn btn-block btn-primary"
          label="Add"
          icon="pi pi-external-link"
          onClick={() => setDisplayBasic(!displayBasic)}
        />
      </div>
      <UsersData />

      <Dialog
        header="Add User"
        visible={displayBasic}
        style={{ width: "80%" }}
        onHide={() => setDisplayBasic(!displayBasic)}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="locale-us">Login Id</label>
            <InputText
              value={userName}
              onChange={(e) => setuserName(e.target.value)}
            />
          </div>
        </div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="userrole">User Role</label>
            <MultiSelect
              inputId="userrole"
              value={userRoleValue}
              options={userRole}
              onChange={(e) => {
                setuserRoleValue(e.value);
              }}
              placeholder="Select"
              optionLabel="name"
            />
          </div>
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="locale-us">Email</label>
            <InputText
              value={username}
              onChange={(e) => setusername(e.target.value)}
            />
          </div>
          <div className="p-field p-col-12 p-md-4">
            <label htmlFor="Status">Status</label>
            <Dropdown
              className="dropdown__width__100"
              inputId="Status"
              value={selectedState}
              options={states}
              onChange={onStateChange}
              placeholder="Select"
              optionLabel="name"
            />
          </div>
        </div>
        <div style={{ textAlign: "center", marginTop: "15%" }}>
          <Button
            icon={loadingIcon || ""}
            disabled={loading}
            onClick={handleSubmit}
            type="submit"
            label="Submit"
            className="btn btn-block btn-primary"
          />
        </div>
      </Dialog>
    </div>
  );
}

export default Users;
