import React from "react";
import "./CategoryColors.css";
import { useSelector } from "react-redux";

function CategoryColors() {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCat = user?.tblUsercategory?.categorycode;

  if (!userCat || userCat === "1") {
    return null;
  }

  return (
    <div className="category-colors">
      <div className="category">
        <div className="color category-internal"></div>
        <div className="category-info">
          <p>Internal</p>
        </div>
      </div>

      <div className="category">
        <div className="color category-introducer"></div>
        <div className="category-info">
          <p>Introducer</p>
        </div>
      </div>

      <div className="category">
        <div className="color category-solicitor"></div>
        <div className="category-info">
          <p>Solicitor</p>
        </div>
      </div>

      <div className="category">
        <div className="color category-thirdparty"></div>
        <div className="category-info">
          <p>Third Party</p>
        </div>
      </div>
    </div>
  );
}

export default CategoryColors;
