import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { calculate_age } from "../../../utilities/commonFunctions";
import LaInput from "../../../components/LaInput";
import LaAddress from "../../../components/LaAddress";
import LaInputTextArea from "../../../components/LaInputTextArea";
import { Checkbox } from "primereact/checkbox";
import OptionButton from "../../../components/LaVehicleReg/OptionButton";
import { useSelector } from "react-redux";
import ClawbackDate from "./ClawbackDate";
import LaMobile from "../../../components/LaMobile/LaMobile";
import TitleDropdown from "../../../components/TitleDropDown/TitleDropdown";

function ClaimantDetails({
  handleLitigationFriend,
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const [isMinor, setisMinor] = useState(false);
  const [currentAge, setCurrentAge] = useState(false);
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCat = user?.tblUsercategory?.categorycode;
  const rtaStatusCode = useSelector((state) => state.rtaSlice.rta.statuscode);
  const clawbackDate = useSelector((state) => state.rtaSlice.rta?.clawbackDate);
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  const ninumberOptions = ["Will be provided to the solicitor", "Minor"];

  if (
    userCat &&
    userCat === "4" &&
    (!rtaStatusCode ||
      (rtaStatusCode !== "1" &&
        rtaStatusCode !== "2" &&
        rtaStatusCode !== "3" &&
        rtaStatusCode !== "4"))
  ) {
    ninumberOptions.push("Solicitor obtained Number");
  }

  const englishlevelOptions = ["Fluent", "Good", "Average", "Poor"];

  const onChangeOptionNinumber = (option) => {
    formik.setFieldValue("ninumber", option);
  };

  const onChangeOptionEnglishlevel = (option) => {
    formik.setFieldValue("englishlevel", option);
  };

  const addressProps = {
    ...props,
    keyNameCity: "city",
    keyNameRegion: "region",
    keyNamePostcode: "postalcode",
    keyNameAddress1: "address1",
    keyNameAddress2: "address2",
    keyNameAddress3: "address3",
    placeholder: "Enter Postcode",
    formik,
  };

  useEffect(() => {
    let age_limit = 18;
    let age = calculate_age(values.dob);
    setCurrentAge(age);
    if (values.scotland === true) {
      age_limit = 16;
    }
    if (age < age_limit) {
      setisMinor(true);
      handleLitigationFriend(true);
    } else {
      setisMinor(false);
      handleLitigationFriend(false);
    }
  }, [values.scotland, values.dob]);

  const handleMaxDate = (e, key) => {
    // max date should be yesterday
    let today = new Date();
    let yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);
    if (e.target.value > yesterday.toISOString().split("T")[0]) {
      formik.setFieldValue(key, yesterday.toISOString().split("T")[0]);
    } else {
      formik.setFieldValue(key, e.target.value);
    }
  };

  return (
    <>
      <div>
        <div className="Panel__Heading">Main Info</div>
        <ClawbackDate {...props} clawbackDate={clawbackDate} />
      </div>
      <div className="p-col-12">
        <label className="p-d-block">
          Name<span className="text-danger">*</span>
        </label>
        <TitleDropdown {...props} keyName="title" />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput
          keyName="firstname"
          placeholder="First Name"
          masking
          {...props}
        />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="middlename" placeholder="Middle Name" {...props} />
      </div>
      <div className="p-col-4 p-mt-2">
        <LaInput keyName="lastname" placeholder="Surname" masking {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Did accident occur in scotland?</label>
        <Checkbox
          inputId="scotland"
          name="scotland"
          checked={values.scotland}
          onChange={(e) => {
            onChange(e);
          }}
        />
      </div>

      {/* dob */}
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Date of Birth<span className="text-danger">*</span>
        </label>
        <InputText
          type="date"
          id="dob"
          placeholder="Date of Birth"
          name="dob"
          value={values.dob}
          onChange={(e) => {
            handleMaxDate(e, "dob");
          }}
          className={classNames(
            {
              "p-invalid": isFormFieldValid("dob"),
            },
            "Width__50"
          )}
        />
        {getFormErrorMessage("dob")}
      </div>
      {formik.values.dob && (
        <>
          {isMinor ? (
            <div className="p-col-12">
              <span className="label-danger">Minor {currentAge}</span>
            </div>
          ) : (
            <div className="p-col-12">
              <span className="label-info">Age {currentAge}</span>
            </div>
          )}
        </>
      )}

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          National Insurance Number<span className="text-danger">*</span>
        </label>
        <div className="p-inputgroup la__inputGroup">
          <InputText
            id="ninumber"
            placeholder="National Insurance Number"
            name="ninumber"
            value={values.ninumber}
            onChange={onChange}
            className={classNames({
              "p-invalid": isFormFieldValid("ninumber"),
            })}
          />
          <div>
            <OptionButton
              options={ninumberOptions}
              onChangeOption={onChangeOptionNinumber}
            />
          </div>
        </div>
        {getFormErrorMessage("ninumber")}
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Standard of english<span className="text-danger">*</span>
        </label>
        <div className="p-inputgroup la__inputGroup">
          <InputText
            id="englishlevel"
            placeholder="Standard of english"
            name="englishlevel"
            value={values.englishlevel}
            onChange={onChange}
            className={classNames({
              "p-invalid": isFormFieldValid("englishlevel"),
            })}
          />
          <div>
            <OptionButton
              options={englishlevelOptions}
              onChangeOption={onChangeOptionEnglishlevel}
            />
          </div>
        </div>
        {getFormErrorMessage("englishlevel")}
      </div>
      {values.englishlevel === "Poor" && (
        <>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Other Languages</label>
            <LaInput
              keyName="otherlanguages"
              placeholder="Other languages"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Translator details</label>
            <LaInputTextArea
              keyName="translatordetail"
              placeholder="Translator details"
              {...props}
            />
          </div>
        </>
      )}

      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">
          Mobile<span className="text-danger">*</span>
        </label>
        <LaMobile keyName="mobile" masking {...props} />
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">Landline</label>
        <LaInput keyName="landline" placeholder="Landline" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Alternative number</label>
        <LaInput
          keyName="alternativenumber"
          placeholder="Alternative Number"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Email</label>
        <LaInput keyName="email" placeholder="Email" masking {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Occupation</label>
        <LaInput keyName="occupation" placeholder="Occupation" {...props} />
      </div>
      <LaAddress {...addressProps} />
    </>
  );
}

export default ClaimantDetails;
