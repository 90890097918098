import {
  SETCAPTCHASUCCESS,
  SETCAPTCHAFALSE,
} from "../slices/captchaVerifiedSlice";

export const verifyCaptchaSuccessAction = () => async (dispatch) => {
  dispatch(SETCAPTCHASUCCESS());
};

export const verifyCaptchaFailureAction = () => async (dispatch) => {
  dispatch(SETCAPTCHAFALSE());
};
