/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AiOutlineBars, AiOutlineExport, AiOutlineRight } from "react-icons/ai";
import { Tab, TabWrapper } from "../../components/Tab";
import CaseHeader from "../../components/CaseHeader";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getHdrStatus, getPcpList } from "../../redux/actions/pcpAction";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import { Chip } from "primereact/chip";

function ViewPCPList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState("All");
  const [filtered, setFiltered] = useState([]);
  const { pcpList, pcpStatus } = useSelector((state) => state.pcpSlice);
  const [expandedRows, setExpandedRows] = useState();

  // Fetch statuses
  const getStatuses = async () => {
    await dispatch(getHdrStatus());
  };

  // Fetch PCP cases
  const getCases = async (status) => {
    await dispatch(
      getPcpList(
        status !== "All"
          ? `Pcp/getPcpCasesByStatus/${status}`
          : `Pcp/getPcpCases`
      )
    );
  };

  // Calculate total statuses
  const totalStatus = () =>
    pcpStatus?.reduce((total, { statusCount }) => total + statusCount, 0) || 0;

  // Actions column template
  const actionsTemplate = ({ pcpClaimCode }) => {
    const newTabLink = `../#/pcpdetails/${pcpClaimCode}`;
    const link = `../pcpdetails/${pcpClaimCode}`;

    return (
      <ButtonsContainer>
        <Button onClick={() => navigate(link)}>
          <StyledIcon>
            <AiOutlineRight />
          </StyledIcon>
          Open
        </Button>
        <Button onClick={() => window.open(newTabLink, "_blank")}>
          <StyledIcon>
            <AiOutlineExport />
          </StyledIcon>
          Open in a New Tab
        </Button>
      </ButtonsContainer>
    );
  };

  // Handle global filtering
  const onGlobalFilterChange = (e = false) => {
    const keys = ["code", "client", "taskName", "taskDue", "created", "status"];
    const value = e?.target?.value || "";
    setFiltered(
      value
        ? pcpList.filter((item) =>
            keys.some((key) =>
              item[key]?.toString().toLowerCase().includes(value.toLowerCase())
            )
          )
        : pcpList
    );
  };

  // Render header with search
  const renderHeader = () => (
    <div className="flex justify-content-end">
      <span className="p-input-icon-left">
        <InputText onChange={onGlobalFilterChange} placeholder="Search" />
      </span>
    </div>
  );

  // Expandable row template
  const rowExpansionTemplate = (data) => (
    <div className="expanded-card">
      <div className="p-grid">
        <div className="p-col-12">
          <div className="box">
            <strong>Last Updated:</strong>
            <p>{data?.lastUpdated}</p>
          </div>
          <div className="box">
            <strong>Introducer:</strong>
            <p>{data?.introducer}</p>
          </div>
          <div className="box">
            <strong>Last Notes:</strong>
            <p>{data?.lastNote}</p>
          </div>
        </div>
      </div>
    </div>
  );

  // Status column template
  const statusTemplate = (rowData) => (
    <center>
      {["Rejected", "New"].includes(rowData?.status) ? (
        <StatusChip status={rowData?.status}>{rowData?.status}</StatusChip>
      ) : (
        <Chip label={rowData?.status} className="p-px-4 custom-chip" />
      )}
    </center>
  );

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    getCases(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    setFiltered(pcpList);
  }, [pcpList]);

  return (
    <>
      <CaseHeader
        title="PCP Case List"
        subTitle={`All claims, status: ${currentStatus}`}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton title="All" count={53} active icon={<AiOutlineBars />} />
          <InfoButton title="New" count={53} icon={<AiOutlineBars />} />
        </InfoWrapper>
      </CaseHeader>

      <TabWrapper>
        <Tab
          title="All"
          count={String(totalStatus())}
          active={currentStatus === "All"}
          icon={<AiOutlineBars />}
          onClick={() => setCurrentStatus("All")}
        />
        {pcpStatus?.map(({ statusName, statusCount, statusCode }) => (
          <Tab
            key={statusCode}
            title={statusName}
            count={String(statusCount)}
            active={currentStatus === statusCode}
            icon={<AiOutlineBars />}
            onClick={() => setCurrentStatus(statusCode)}
          />
        ))}
      </TabWrapper>

      <div className="card">
        <DataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header={renderHeader()}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "2.5rem" }} />
          <Column field="created" sortable header="Created" />
          <Column field="code" sortable header="Code" />
          <Column field="client" sortable header="Client" />
          <Column field="taskName" sortable header="Task Name" />
          <Column
            field="status"
            body={statusTemplate}
            sortable
            header="Status"
          />
          <Column header="Acts" body={actionsTemplate} />
        </DataTable>
      </div>
    </>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const StyledIcon = styled.span`
  margin-right: 5px;
`;

const StatusChip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  font-size: 12px;
  width: 100px;
  color: white;
  background-color: ${({ status }) => getStatusColor(status)};
`;

const getStatusColor = (status) => {
  switch (status) {
    case "New":
      return "#2b9f2b";
    case "Rejected":
      return "#df4848";
    default:
      return "#777777";
  }
};

export default ViewPCPList;
