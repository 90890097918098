import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { handlePostRequest } from "../../services/PostTemplate";
import { useSelector } from "react-redux";
import { initialValues, validationSchema } from "./MedicalNegligenceConstants";
import AccSubmissionCriteria from "../../components/AccSubmissionCriteria";
import { useNavigate } from "react-router-dom";
import MainInfo from "../../components/MainInfo/MainInfo";
import ClientInformation from "./MedNegFormComponents/ClientInformation";
import AccidentCircumstances from "./MedNegFormComponents/AccidentCircumstances";
import MedicalAttention from "./MedNegFormComponents/MedicalAttention";
import ThirdPartyDetails from "./MedNegFormComponents/ThirdPartyDetails";
import CallDetails from "./MedNegFormComponents/CallDetails";
import FurtherDetails from "./MedNegFormComponents/FurtherDetails";
import { campaignCodes } from "../../components/CampaignGeneric/campaignConfig";

function MedicalNegligence({
  edit,
  isSubmitFormik,
  handleReturnEdit,
  onSetIsSubmitFormikFalse,
  showAccSubmissionCriteria,
}) {
  const { medicalNegligence } = useSelector(
    (state) => state.medicalNegligenceSlice
  );
  const [isAccSubmissionCriteria, setisAccSubmissionCriteria] = useState(
    showAccSubmissionCriteria || false
  );
  const [accSubmissionCriteria, setaccSubmissionCriteria] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    if (edit) {
      fillFormFields();
    }
  }, [edit, medicalNegligence]);

  useEffect(() => {
    if (isSubmitFormik) {
      formik.handleSubmit();
      onSetIsSubmitFormikFalse();
    }
  }, [isSubmitFormik]);

  const fillFormFields = () => {
    const data = { ...medicalNegligence };

    // To convert Y/N to true/false for radio buttons and checkboxes
    Object.keys(data).forEach((key) => {
      if (data[key] === "Y") {
        data[key] = true;
      }
      if (data[key] === "N") {
        data[key] = false;
      }
      if (data[key] === null) {
        data[key] = "";
      }
    });

    Object.keys(data).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, data[key]);
      }
    });

    formik.setFieldValue("clawbackDate", data?.clawbackDate);
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      Object.keys(data).forEach((key) => {
        if (data[key] === true || data[key] === "true") {
          data[key] = "Y";
        }
        if (data[key] === false) {
          data[key] = "N";
        }
        if (data[key] === "") {
          data[key] = null;
        }
      });
      if (edit) {
        data["mnclaimcode"] = medicalNegligence?.mnclaimcode;
        await handlePostRequest(data, "/medneg/updateMnCase");
        await handleReturnEdit();
      } else {
        data["introducer"] = accSubmissionCriteria?.introducer?.companycode;
        data["advisor"] = accSubmissionCriteria?.advisor?.usercode;
        const res = await handlePostRequest(data, "/medneg/addNewMnCase");
        navigate(`/medicalNegligenceDetails/${res.data?.mnclaimcode}`);
      }
    },
  });
  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const handleAccSum = (data) => {
    setisAccSubmissionCriteria(true);
    setaccSubmissionCriteria(data);
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  return (
    <>
      {!isAccSubmissionCriteria ? (
        <AccSubmissionCriteria
          handleAccSum={handleAccSum}
          campaignCode={campaignCodes.MN}
        />
      ) : (
        <div className="p-ml-4">
          <MainInfo edit={edit} formik={formik} />

          <div>
            <hr className="border__hr" />
            <div className="row">
              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <ClientInformation {...props} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Panel__Heading">Accident Circumstances</div>
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <AccidentCircumstances {...props} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Panel__Heading mt-4">Medical Attention</div>
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <MedicalAttention {...props} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Panel__Heading mt-4">Third Party Details</div>
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <ThirdPartyDetails {...props} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="Panel__Heading mt-4">Further Details</div>
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <FurtherDetails {...props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default MedicalNegligence;
