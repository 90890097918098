import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import styled from "styled-components";
import { RiInformationLine } from "react-icons/ri";
import { Button } from "primereact/button";

const DetailsContainer = styled.div`
  margin: 20px;
  padding: 20px;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableRow = styled.tr`
  border-bottom: 1px solid #ddd; /* Maintains bottom gridline */
`;

const TableCell = styled.td`
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
  &:last-child {
    border-right: none;
  }
`;

const PhoneNumberDetails = ({ details }) => {
  const [showDetails, setShowDetails] = useState(false);

  const handleShowDetails = (e) => {
    e.preventDefault();
    setShowDetails(true);
  };

  if (!details) {
    return null;
  }

  return (
    <>
      <Button
        onClick={handleShowDetails}
        className="btn-info btn-sm p-mr-2 p-mb-2 p-mt-2"
      >
        <RiInformationLine style={{ marginRight: "5px" }} />
        View Details
      </Button>
      <Dialog
        header={"Phone Number Details"}
        visible={showDetails}
        style={{ width: "60%" }}
        onHide={() => setShowDetails(false)}
      >
        <DetailsContainer>
          <Table>
            <tbody>
              <TableRow>
                <TableCell>Phone Number:</TableCell>
                <TableCell>{details.PhoneNumber}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Network Name:</TableCell>
                <TableCell>{details.NetworkName}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Network Country:</TableCell>
                <TableCell>
                  {details.NetworkCountry === "GB"
                    ? "United Kingdom"
                    : details.NetworkCountry}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>National Format:</TableCell>
                <TableCell>{details.NationalFormat}</TableCell>
              </TableRow>
            </tbody>
          </Table>
        </DetailsContainer>
      </Dialog>
    </>
  );
};

export default PhoneNumberDetails;
