import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "pcpSlice",
  initialState: {
    pcp: {},
    pcpList: [],
    pcpStatus: [],
  },
  reducers: {
    GETPCPLISTSUCCESS: (state, action) => {
      return {
        ...state,
        pcpList: action.payload,
      };
    },
    GETPCPLISTERROR: (state) => {
      return {
        ...state,
        pcpList: [],
      };
    },
    GETPCPSUCCESS: (state, action) => {
      return {
        ...state,
        pcp: action.payload,
      };
    },
    GETPCPERROR: (state) => {
      return {
        ...state,
        pcp: {},
      };
    },
    GETPCPSTATUSSUCCESS: (state, action) => {
      return { ...state, pcpStatus: action.payload };
    },
    GETPCPSTATUSERROR: (state) => {
      return { ...state, pcpStatus: {} };
    },
  },
});

export const {
  GETPCPLISTSUCCESS,
  GETPCPLISTERROR,
  GETPCPSUCCESS,
  GETPCPERROR,
  GETPCPSTATUSERROR,
  GETPCPSTATUSSUCCESS,
} = slice.actions;
export default slice.reducer;
