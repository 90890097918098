import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

function ComplaintDetails({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  return (
    <div>
      <div className="p-mt-4 p-ml-4 p-mr-4">
        <div className="Panel__Heading">Complaint Details</div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Have you complained to Defendant (or the ICO) about the breach?
              *if yes ask Claimant for evidence of this if available* *If yes
              what was the outcome of the complaint? Did Claimant receive
              compensation?*
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="complaintDefendent"
              name="complaintDefendent"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("complaintDefendent"),
                },
                "p-d-block Width__100"
              )}
              value={values.complaintDefendent}
              onChange={onChange}
            />
            {getFormErrorMessage("complaintDefendent")}
          </div>
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">
              Has the breach been rectified or deleted, as far as possible,
              within the Defendant's power? (I.e. information removed from
              website, publications recalled etc?)
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="dbRectified"
              name="dbRectified"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("dbRectified"),
                },
                "p-d-block Width__100"
              )}
              value={values.dbRectified}
              onChange={onChange}
            />
            {getFormErrorMessage("dbRectified")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              How do you feel the Defendant has handled the breach?
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="defendentHandleDb"
              name="defendentHandleDb"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("defendentHandleDb"),
                },
                "p-d-block Width__100"
              )}
              value={values.defendentHandleDb}
              onChange={onChange}
            />
            {getFormErrorMessage("defendentHandleDb")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              How has the breach affected you? (E.g. Did you experience
              distress, shame, embarrassment, paranoia etc.? Include non-medical
              impacts, i.e. loss of friendships, diminished employment
              prospects, impacted credit rating etc.) *It is crucial to get as
              much detail from the Claimant on this point*
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="hasDbAffectedYou"
              name="hasDbAffectedYou"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("hasDbAffectedYou"),
                },
                "p-d-block Width__100"
              )}
              value={values.hasDbAffectedYou}
              onChange={onChange}
            />
            {getFormErrorMessage("hasDbAffectedYou")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Have you sought medical advice/assistance for how the breach has
              affected you? (I.e. have you attended GP or hospital or
              self-medicated for the effects of the breach such as depression,
              anxiety, trouble-sleeping etc.) *If the Claimant hasn’t sought
              medical attention or advice for the impact ask them do they intend
              to – and if so they need to give us details of when they go*
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="soughtMedicalAvdnce"
              name="soughtMedicalAvdnce"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("soughtMedicalAvdnce"),
                },
                "p-d-block Width__100"
              )}
              value={values.soughtMedicalAvdnce}
              onChange={onChange}
            />
            {getFormErrorMessage("soughtMedicalAvdnce")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Has the breach exasperated a preexisting condition? (I.e.
              depression, anxiety, addictions, anger issues?)
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="dbPreexistingCond"
              name="dbPreexistingCond"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("dbPreexistingCond"),
                },
                "p-d-block Width__100"
              )}
              value={values.dbPreexistingCond}
              onChange={onChange}
            />
            {getFormErrorMessage("dbPreexistingCond")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Have you ever instructed a previous Solicitor to act for you in a
              claim for this data protection breach? *If yes find out when, what
              was the outcome and did they receive compensation*
            </label>
            <InputTextarea
              rows={5}
              type="date"
              id="instructedPrvntDb"
              name="instructedPrvntDb"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("instructedPrvntDb"),
                },
                "p-d-block Width__100"
              )}
              value={values.instructedPrvntDb}
              onChange={onChange}
            />
            {getFormErrorMessage("instructedPrvntDb")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ComplaintDetails;
