import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import LaInputTextArea from "../../../../components/LaInputTextArea";
import { Dialog } from "primereact/dialog";
import PassengerClaimantDetails from "./PassengerClaimantDetails";
import { TabView, TabPanel } from "primereact/tabview";
import PassengerLitigationFriend from "./PassengerLitigationFriend";
import PassengerInjuryInfo from "./PassengerInjuryInfo";
import classNames from "classnames";
import { passengerKeys, passengerValidationSchema } from "../../RtaConstants";

function Passengers({
  handlePassengers,
  claimantAdress,
  singlePassenger,
  isEdit,
  setEditFalse,
  isDriver,
  onUpdate,
  claimantInfo,
}) {
  const [showPassengerModal, setShowPassengerModal] = useState(false);
  const [isLitigationFriend, setIsLitigationFriend] = useState(false);
  const footer = (
    <div>
      <button
        type="submit"
        className="btn btn-primary"
        onClick={() => formik.handleSubmit()}
      >
        Submit
      </button>
    </div>
  );

  const formik = useFormik({
    validationSchema: passengerValidationSchema,
    initialValues: passengerKeys,
    onSubmit: async (data) => {
      handlePassengers(data, isEdit);
      setShowPassengerModal(false);
      formik.resetForm();
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  const handleLitigationFriend = (isShow) => {
    formik.setFieldValue("minor", isShow);
    setIsLitigationFriend(isShow);
  };

  useEffect(() => {
    if (isEdit) {
      setShowPassengerModal(true);
      console.log("singlePassenger", singlePassenger);
      Object.keys(singlePassenger).forEach((key) => {
        if (formik.initialValues.hasOwnProperty(key)) {
          formik.setFieldValue(key, singlePassenger[key]);
        }
      });
    }
  }, [isEdit]);

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <hr className="border__hr" />
        {!onUpdate && (
          <div
            className="p-mt-4"
            onClick={() => setShowPassengerModal((display) => !display)}
          >
            <p style={{ cursor: "pointer", color: "#555" }}>
              --- passengers: click to add ---
            </p>
          </div>
        )}
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">Passengers Info</label>
          <LaInputTextArea
            keyName="partycontactno"
            placeholder="enter all passengers related info here"
            {...props}
          />
        </div>
        <div>
          <Dialog
            header="Passengers Form"
            footer={footer}
            visible={showPassengerModal}
            style={{ width: "65%" }}
            onHide={() => {
              setShowPassengerModal(false);
              formik.resetForm();
              setEditFalse();
            }}
          >
            <TabView className="mt-2">
              <TabPanel
                style={{ fontSize: "12px" }}
                renderActiveOnly={false}
                header="Personal Details"
              >
                <div className="row" disabledForm={String(onUpdate)}>
                  <div className="col-md-6">
                    <div className="p-fluid p-formgrid p-grid p-pr-4">
                      <PassengerClaimantDetails
                        claimantAdress={claimantAdress}
                        handleLitigationFriend={handleLitigationFriend}
                        {...props}
                      />
                    </div>
                  </div>
                  {isLitigationFriend && (
                    <div className="col-md-6" disabledForm={String(onUpdate)}>
                      <div className="p-fluid p-formgrid p-grid p-pr-4">
                        <PassengerLitigationFriend
                          claimantInfo={claimantInfo}
                          {...props}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <hr className="border__hr" />
              </TabPanel>
              <TabPanel
                style={{ fontSize: "12px" }}
                renderActiveOnly={false}
                header="Injury Info"
              >
                <div className="row" disabledForm={String(onUpdate)}>
                  <div className="col-md-6">
                    <div className="p-fluid p-formgrid p-grid p-pr-4">
                      <PassengerInjuryInfo isDriver={isDriver} {...props} />
                    </div>
                  </div>
                </div>
                <hr className="border__hr" />
              </TabPanel>
            </TabView>
          </Dialog>
        </div>
      </form>
    </>
  );
}

export default Passengers;
