import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "authenticationSlice",
  initialState: {
    el: {},
    elList: [],
    elStatus: [],
  },
  reducers: {
    GETELLISTSUCCESS: (state, action) => {
      return {
        ...state,
        elList: action.payload,
      };
    },
    GETELLISTERROR: (state) => {
      return {
        ...state,
        elList: [],
      };
    },
    GETELSUCCESS: (state, action) => {
      return {
        ...state,
        el: action.payload,
      };
    },
    GETELERROR: (state) => {
      return {
        ...state,
        el: {},
      };
    },
    GETELSTATUSSUCCESS: (state, action) => {
      return { ...state, elStatus: action.payload };
    },
    GETELSTATUSERROR: (state) => {
      return { ...state, elStatus: {} };
    },
    CLEARELDATA: (state) => {
      return { ...state, el: {}, elList: [], elStatus: [] };
    },
  },
});

export const {
  GETELLISTSUCCESS,
  GETELLISTERROR,
  GETELSUCCESS,
  GETELERROR,
  GETELSTATUSERROR,
  GETELSTATUSSUCCESS,
  CLEARELDATA,
} = slice.actions;
export default slice.reducer;
