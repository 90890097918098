import React from "react";
import LaCheckbox from "../../../components/LaCheckbox";
import LaInput from "../../../components/LaInput";
import LaInputTextArea from "../../../components/LaInputTextArea";

function OtherFinancialLossesInformation({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  return (
    <>
      <div>
        <div className="Panel__Heading">Other Financial Losses Information</div>
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Loss of earnings?</label>
        <LaCheckbox keyName="lossofearnings" {...props} />
      </div>
      {values.lossofearnings && (
        <>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Period of absence</label>
            <LaInput
              keyName="periodofabsence"
              placeholder="Period of Absence"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Estimated net weekly wage</label>
            <LaInput
              keyName="estimatednetweeklywage"
              placeholder="Estimated Net Weekly Wage"
              {...props}
            />
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Statutory/company sick pay?</label>
            <LaInput
              keyName="companysickpay"
              placeholder="Sick Pay Details"
              {...props}
            />
          </div>
        </>
      )}

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Any other losses?</label>
        <LaCheckbox keyName="anyotherlosses" {...props} />
      </div>
      {values.anyotherlosses && (
        <>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">Specify additional losses</label>
            <LaInputTextArea
              keyName="otherlossesdetail"
              placeholder="Additional Losses Details"
              {...props}
            />
          </div>
        </>
      )}

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Is the client still working for the employer?
        </label>
        <LaCheckbox keyName="clientstillwork" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Additional claim information?</label>
        <LaInputTextArea
          keyName="additionalclaiminfo"
          placeholder="Additional Claim Information"
          {...props}
        />
      </div>
    </>
  );
}

export default OtherFinancialLossesInformation;
