import React from "react";
import "./404.css";
import { useNavigate } from "react-router-dom";
import { Button } from "primereact/button";

function NotFound() {
  const navigate = useNavigate();
  return (
    <section className="page_404">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 ">
            <center>
              <div className="four_zero_four_bg">
                <h1 className="text-center ">404</h1>
              </div>

              <div className="contant_box_404">
                <h3 className="h2">Look like you're lost</h3>
                <p>the page you are looking for not available!</p>
                <Button className="btn" onClick={() => navigate("../")}>
                  Go to Home
                </Button>
              </div>
            </center>
          </div>
        </div>
      </div>
    </section>
  );
}

export default NotFound;
