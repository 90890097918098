import React, { useState, useEffect } from "react";
import LaCheckbox from "../../../components/LaCheckbox";
import LaInput from "../../../components/LaInput";

function EmployementDetails({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  return (
    <>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          How long has the client been employed with their employer?
        </label>
        <LaInput
          keyName="employmentduration"
          placeholder="Employment Duration"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Job Title</label>
        <LaInput keyName="jobtitle" placeholder="Job Title" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          What are their day to day activities?
        </label>
        <LaInput
          keyName="dailyactivities"
          placeholder="Day to Day Activities"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          What training has the client received?
        </label>
        <LaInput
          keyName="trainingreceived"
          placeholder="Training Received"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          What protective equipment clothing has the client been provided with
          by their employer?
        </label>
        <LaInput
          keyName="userofprocequip"
          placeholder="Protective Equipment"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2" style={{ display: "flex" }}>
        <LaCheckbox keyName="wasclientwearing" {...props} />
        <label className="p-d-block" style={{ marginLeft: "10px" }}>
          Was the client wearing it at the time of the accident?
        </label>
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Employer's Name</label>
        <LaInput
          keyName="employername"
          placeholder="Employer's Name"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Employer's Address</label>
        <LaInput
          keyName="employeraddress"
          placeholder="Employer's Address"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Employer's Telephone Number</label>
        <LaInput
          keyName="employercontactno"
          placeholder="Employer's Telephone Number"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Agency Name</label>
        <LaInput keyName="agencyname" placeholder="Agency Name" {...props} />
      </div>
    </>
  );
}

export default EmployementDetails;
