import React, { useEffect, useState } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import styled from "styled-components";
import { Badge } from "primereact/badge";
import {
  AiOutlineCheck,
  AiOutlineWarning,
  AiOutlineClose,
} from "react-icons/ai";

const getStatusIcon = (statusCode) => {
  switch (statusCode) {
    case "success":
      return <AiOutlineCheck size={20} color="#28a745" />;
    case "warning":
      return <AiOutlineWarning size={20} color="#ffc107" />;
    case "error":
      return <AiOutlineClose size={20} color="#dc3545" />;
    default:
      return null;
  }
};

function StatusCounts({ compaign, heading }) {
  const [campaignStatusCounts, setCampaignStatusCounts] = useState([]);
  const [showAllItems, setShowAllItems] = useState(false);

  useEffect(() => {
    const getCampaignStatusCounts = async () => {
      const res = await handleGetRequest(compaign);
      setCampaignStatusCounts(res?.data);
    };
    getCampaignStatusCounts();
  }, [compaign]);

  const filteredCounts = showAllItems
    ? campaignStatusCounts
    : campaignStatusCounts?.filter((count) => count.statusCount > 0);

  if (
    !campaignStatusCounts ||
    campaignStatusCounts.length === 0 ||
    !compaign ||
    filteredCounts.length === 0
  ) {
    return null;
  }

  return (
    <Container>
      {heading && <Heading>{heading}</Heading>}
      <ToggleContainer>
        <ToggleBtn onClick={() => setShowAllItems(!showAllItems)}>
          {showAllItems ? "Hide Empty" : "Show All"}
        </ToggleBtn>
      </ToggleContainer>
      <Counts>
        {filteredCounts.length > 0 ? (
          filteredCounts.map((rtaStatusCount) => (
            <Card key={rtaStatusCount.statusCode}>
              <Content>
                <Icon>{getStatusIcon(rtaStatusCount.statusCode)}</Icon>
                <Title>{rtaStatusCount.statusName}</Title>
                <BadgeContainer>
                  <Badge value={rtaStatusCount.statusCount}></Badge>
                </BadgeContainer>
              </Content>
            </Card>
          ))
        ) : (
          <NoRecordsContainer>
            <NoRecords>No Records</NoRecords>
          </NoRecordsContainer>
        )}
      </Counts>
    </Container>
  );
}

const Container = styled.div`
  margin-top: 20px;
  padding: 0 1rem;
`;

const Heading = styled.h2`
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  font-weight: 600;
`;

const ToggleContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
`;

const ToggleBtn = styled.button`
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 14px;
`;

const Counts = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  margin: 0 -5px;
  padding-top: 1rem;
`;

const Card = styled.div`
  flex: 0 0 calc(12.5% - 10px);
  max-width: calc(12.5% - 10px);
  background-color: #f8f9fa;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 20px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-5px);
  }
`;

const Content = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Icon = styled.div`
  margin-bottom: 10px;
`;

const Title = styled.h3`
  font-size: 14px;
  margin-bottom: 5px;
`;

const BadgeContainer = styled.div`
  margin-top: 10px;
`;

const NoRecordsContainer = styled.div`
  width: 100%;
  text-align: center;
`;

const NoRecords = styled.p`
  color: #868686;
  margin-top: 10px;
`;

export default StatusCounts;
