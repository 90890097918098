import React, { useState } from "react";
import { getMedicalNegligenceDetails } from "../../redux/actions/medicalNegligenceAction";
import { useParams } from "react-router-dom";
import CampaignDetails from "../../components/CampaignGeneric/CampaignDetails";
import { campaignsNames } from "../../components/CampaignGeneric/campaignConfig";
import { useDispatch, useSelector } from "react-redux";
import CaseHeaderMN from "./CaseHeaderMN";
import MedicalNegligence from "./MedicalNegligence";

export default function MedicalNegligenceDetails() {
  const { medicalNegligence } = useSelector(
    (state) => state.medicalNegligenceSlice
  );
  const [isSubmitFormik, setisSubmitFormik] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const { code } = useParams();
  const dispatch = useDispatch();

  const onSave = async () => {
    setisSubmitFormik(true);
  };

  const handleReturnEdit = async () => {
    setDisabled(true);
    dispatch(getMedicalNegligenceDetails(code));
  };

  const onSetIsSubmitFormikFalse = () => {
    setisSubmitFormik(false);
  };

  const onCancel = () => {
    setDisabled(true);
  };

  return (
    <CampaignDetails
      campaign={medicalNegligence}
      campaignName={campaignsNames.medicalNegligence}
      campaignCode={code}
      onSave={onSave}
      onCancel={onCancel}
      getCampaignDetails={getMedicalNegligenceDetails}
      disabled={disabled}
      setDisabled={setDisabled}
      CampaignForm={
        <MedicalNegligence
          edit={true}
          onSave={onSave}
          isSubmitFormik={isSubmitFormik}
          handleReturnEdit={handleReturnEdit}
          onSetIsSubmitFormikFalse={onSetIsSubmitFormikFalse}
          showAccSubmissionCriteria={true}
        />
      }
      CaseHeader={
        <CaseHeaderMN
          title={medicalNegligence?.mncode}
          subTitle={medicalNegligence?.statusDescr}
        />
      }
    />
  );
}
