import React, { useState, useEffect } from "react";
import LaCheckbox from "../../../components/LaCheckbox";
import EmployementDetails from "./EmployementDetails";

function EmployementHistory({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  console.log("isEmployementHistory", values.isEmployementHistory);

  return (
    <>
      <div>
        <div className="Panel__Heading">Employement Info</div>
      </div>

      <div className="p-col-12 p-mb-2" style={{ display: "flex" }}>
        <LaCheckbox keyName="isEmployementHistory" {...props} />
        <label className="p-d-block" style={{ marginLeft: "10px" }}>
          Employement history?
        </label>
      </div>
      {values.isEmployementHistory === true && (
        <EmployementDetails {...props} />
      )}
    </>
  );
}

export default EmployementHistory;
