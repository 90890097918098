import React from "react";
import { MainWrapper } from "../FormLayout/FormHeader/index";
import { SideBoxWrapper } from "../FormLayout/SideBoxSection/index";
import { useSelector } from "react-redux";
import InvoiceCaseDetails from "../InvoiceCaseDetails.js/InvoiceCaseDetails";
import ESignAuditButton from "../ESignAudit/ESignAuditButton";
import ViewCampaignForm from "./ViewCampaignForm";
import { campaignConfig, campaignsNotesKeys } from "./campaignConfig";
import Timeline from "../Timeline/Timeline";
import CaseAccountInfo from "../CaseAccountInfo/CaseAccountInfo";
import CampaignTasks from "../CampaignTasks/CampaignTasks";

export default function CampaignDetails({
  campaign,
  campaignName,
  campaignCode, // here campaignCode is basically case code
  CampaignForm,
  onSave,
  onCancel,
  getCampaignDetails,
  disabled,
  setDisabled,
  CaseHeader,
  sideComponent,
}) {
  const advisorname = campaign?.advisorname || "";
  const introducername = campaign?.introducername || "";
  const campaignsNotesKey = campaignsNotesKeys[campaignName];
  const messages = useSelector(
    (state) => state.notesSlice?.notes?.[campaignsNotesKey.external]
  );
  const internalMessages = useSelector(
    (state) => state.notesSlice?.notes?.[campaignsNotesKey.internal]
  );
  const { getTimelineService, campaignLogCode, tbldocuments } =
    campaignConfig[campaignName];

  if (!campaign) {
    return null;
  }

  return (
    <>
      {CaseHeader}
      <MainWrapper>
        <ViewCampaignForm
          campaignDocuments={campaign?.[tbldocuments]}
          campaignCode={campaignCode}
          messages={messages}
          internalMessages={internalMessages}
          onSave={onSave}
          disabled={disabled}
          setDisabled={setDisabled}
          onCancel={onCancel}
          campaignName={campaignName}
          getCampaignDetails={getCampaignDetails}
          CampaignForm={CampaignForm}
          editFlag={campaign?.editFlag}
        />
        <SideBoxWrapper>
          <CampaignTasks
            campaignCode={campaignCode}
            campaignName={campaignName}
            campaign={campaign}
            getCampaignDetails={getCampaignDetails}
          />
          <CaseAccountInfo
            introducerName={introducername}
            advisorName={advisorname}
            campaign={campaign}
            campaignName={campaignName}
            getCampaignDetails={getCampaignDetails}
            campaignCaseCode={campaignCode}
          />
          <InvoiceCaseDetails
            introducerInvoiceDate={campaign?.introducerInvoiceDate}
            solicitorInvoiceDate={campaign?.solicitorInvoiceDate}
            introducerInvoiceHeadId={campaign?.introducerInvoiceHeadId}
            solicitorInvoiceHeadId={campaign?.solicitorInvoiceHeadId}
          />
          <ESignAuditButton data={campaign?.tblEsignStatus} />
          <Timeline
            getTimelineService={getTimelineService}
            campaignLogCode={campaignLogCode}
            campaignCode={campaignCode}
          />
          {sideComponent && React.Children.toArray(sideComponent)}
        </SideBoxWrapper>
      </MainWrapper>
    </>
  );
}
