import { Dialog } from "primereact/dialog";
import React from "react";
import ChangeInroForm from "./ChangeInroForm";

function ChangeIntroDialog({
  showModal,
  onCloseModal,
  campaignName,
  getCampaignDetails,
  campaignCaseCode,
}) {
  return (
    <Dialog
      header="Change Introducer"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
      draggable={false}
      resizable={false}
      position="top"
    >
      <ChangeInroForm
        campaignName={campaignName}
        getCampaignDetails={getCampaignDetails}
        campaignCaseCode={campaignCaseCode}
        onCloseModal={onCloseModal}
      />
    </Dialog>
  );
}

export default ChangeIntroDialog;
