import { Dialog } from "primereact/dialog";
import React from "react";
import HireAssignForm from "./HireAssignForm";

function HireAssignDialog({
  showModal,
  onCloseModal,
  buttonDetails,
  hireclaimcode,
}) {
  return (
    <Dialog
      header="Hire Assign"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
    >
      <HireAssignForm
        buttonDetails={buttonDetails}
        onCloseModal={onCloseModal}
        hireclaimcode={hireclaimcode}
      />
    </Dialog>
  );
}

export default HireAssignDialog;
