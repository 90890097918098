import React from "react";
import { Button } from "primereact/button";
import * as XLSX from "xlsx";

function HdrExcel({ hdr }) {
  const exportHdrToExcel = () => {
    const hdrDetails = {
      ...hdr,
      arrearsAmount: hdr?.tblHdrtenancies[0].reportDetails,
      benefitsDetail: hdr?.tblHdrtenancies[0].benefitsDetail,
      contributePm: hdr?.tblHdrtenancies[0].contributePm,
      contributePw: hdr?.tblHdrtenancies[0].contributePw,
      hdrtenancycode: hdr?.tblHdrtenancies[0].hdrtenancycode,
      inArrears: hdr?.tblHdrtenancies[0].inArrears,
      inPaymentPlan: hdr?.tblHdrtenancies[0].inPaymentPlan,
      HdrTenancieslastupdateuser: hdr?.tblHdrtenancies[0].lastupdateuser,
      noOfOccupants: hdr?.tblHdrtenancies[0].noOfOccupants,
      paymentPlanPm: hdr?.tblHdrtenancies[0].paymentPlanPm,
      paymentPlanPw: hdr?.tblHdrtenancies[0].paymentPlanPw,
      propertyType: hdr?.tblHdrtenancies[0].propertyType,
      receivingBenefits: hdr?.tblHdrtenancies[0].receivingBenefits,
      rentContribute: hdr?.tblHdrtenancies[0].rentContribute,
      rentPm: hdr?.tblHdrtenancies[0].rentPm,
      rentPw: hdr?.tblHdrtenancies[0].rentPw,
      startDate: hdr?.tblHdrtenancies[0].startDate,

      caddress1: hdr?.tblHdrclaimants[0].caddress1,
      caddress2: hdr?.tblHdrclaimants[0].caddress2,
      caddress3: hdr?.tblHdrclaimants[0].caddress3,
      ccontacttime: hdr?.tblHdrclaimants[0].ccontacttime,

      cdob: hdr?.tblHdrclaimants[0].cdob,
      cemail: hdr?.tblHdrclaimants[0].cemail,

      ccity: hdr?.tblHdrclaimants[0].ccity,
      cfname: hdr?.tblHdrclaimants[0].cfname,
      clandline: hdr?.tblHdrclaimants[0].clandline,
      cmname: hdr?.tblHdrclaimants[0].cmname,
      cmobileno: hdr?.tblHdrclaimants[0].cmobileno,
      cninumber: hdr?.tblHdrclaimants[0].cninumber,
      cpostalcode: hdr?.tblHdrclaimants[0].cpostalcode,
      cregion: hdr?.tblHdrclaimants[0].cregion,
      csname: hdr?.tblHdrclaimants[0].csname,
      ctitle: hdr?.tblHdrclaimants[0].ctitle,
      hrdclaimantcode: hdr?.tblHdrclaimants[0].hrdclaimantcode,
      isJointTenancy: hdr?.tblHdrclaimants[0].isJointTenancy,
      lastupdateuser: hdr?.tblHdrclaimants[0].lastupdateuser,
    };
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet([hdrDetails]);
    XLSX.utils.book_append_sheet(wb, ws, "hdr Data");
    XLSX.writeFile(wb, `${hdr?.claimcode}.xlsx`);
  };

  return (
    <Button
      onClick={exportHdrToExcel}
      className="btn btn-primary"
      style={{
        backgroundColor: "green",
        color: "white",
        fontWeight: "bold",
      }}
    >
      Export to Excel
    </Button>
  );
}

export default HdrExcel;
