/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { AiOutlineBars, AiOutlineExport, AiOutlineRight } from "react-icons/ai";
import { Tab, TabWrapper } from "../../components/Tab";
import CaseHeader from "../../components/CaseHeader";
import { InfoWrapper, InfoButton } from "../../components/InfoButton";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getList, getStatus } from "../../redux/actions/plAction";
import { InputText } from "primereact/inputtext";
import styled from "styled-components";
import { Chip } from "primereact/chip";

function ViewPlList() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [currentStatus, setCurrentStatus] = useState("All");
  const [filtered, setFiltered] = useState([]);
  const { plList, plStatus } = useSelector((state) => state.plSlice);
  const [expandedRows, setExpandedRows] = useState();

  const getStatuses = async () => {
    await dispatch(getStatus());
  };

  const getPl = async (status) => {
    await dispatch(
      getList(
        status !== "All" ? `Pl/getPlCasesByStatus/${status}` : `/Pl/getPlCases`
      )
    );
  };

  const totalStatus = () => {
    let total = 0;
    plStatus &&
      plStatus.forEach(({ statusCount }) => {
        total += statusCount;
      });
    return total;
  };

  const actionsTemplate = ({ plClaimCode }) => {
    const newTablink = `../#/pldetails/${plClaimCode}`;
    const link = `../pldetails/${plClaimCode}`;

    return (
      <ButtonsContainer>
        <Button
          onClick={() => {
            navigate(link);
          }}
        >
          <StyledIcon>
            <AiOutlineRight />
          </StyledIcon>
          Open
        </Button>
        <Button
          onClick={() => {
            window.open(newTablink, "_blank");
          }}
        >
          <StyledIcon>
            <AiOutlineExport />
          </StyledIcon>
          Open in a new Tab
        </Button>
      </ButtonsContainer>
    );
  };

  useEffect(() => {
    getStatuses();
  }, []);

  useEffect(() => {
    getPl(currentStatus);
  }, [currentStatus]);

  useEffect(() => {
    onGlobalFilterChange();
  }, [plList]);

  const onGlobalFilterChange = (e = false) => {
    const keys = ["code", "client", "taskName", "taskDue", "created", "status"];
    const value = e?.target?.value;
    if (value) {
      const filteredList = plList.filter((item) => {
        let flag = false;
        keys.forEach((key) => {
          if (
            key &&
            item[key] &&
            item[key]
              .toString()
              .toLowerCase()
              .includes(value.toString().toLowerCase())
          )
            flag = true;
        });
        if (flag) return item;
      });
      setFiltered(filteredList);
    } else {
      setFiltered(plList);
    }
  };

  const renderHeader2 = () => {
    return (
      <div className="flex justify-content-end">
        <span className="p-input-icon-left">
          <InputText onChange={onGlobalFilterChange} placepolder="Search" />
        </span>
      </div>
    );
  };

  const header2 = renderHeader2();

  const rowExpansionTemplate = (data) => {
    return (
      <div className="expanded-card">
        <div className="p-grid">
          <div className="p-col-12">
            <div className="box">
              <strong>Last Updated : </strong>
              <p>{data?.lastUpdated}</p>
            </div>
            <div className="box">
              <strong>Introducer : </strong>
              <p>{data?.introducer}</p>
            </div>
            <div className="box">
              <strong>Last Notes : </strong>
              <p>{data?.lastNote}</p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    if (rowData?.status === "Rejected" || rowData?.status === "New") {
      return (
        <center>
          <StatusChip status={rowData?.status}>{rowData?.status}</StatusChip>
        </center>
      );
    } else {
      return (
        <center>
          <Chip label={rowData?.status} className="p-px-4 custom-chip" />
        </center>
      );
    }
  };

  return (
    <>
      <CaseHeader
        title={"PL Case List"}
        subTitle={"All claims, status: All"}
        search
        onSearch={(val) => console.log(val)}
      >
        <InfoWrapper>
          <InfoButton
            title={"All"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
          <InfoButton
            title={"New"}
            count={53}
            active
            icon={<AiOutlineBars />}
          />
        </InfoWrapper>
      </CaseHeader>
      <TabWrapper>
        <Tab
          title={"All"}
          count={String(totalStatus())}
          active={currentStatus === "All"}
          icon={<AiOutlineBars />}
          onClick={() => setCurrentStatus("All")}
        />
        {plStatus?.map(({ statusName, statusCount, statusCode }) => (
          <Tab
            key={statusCode}
            title={statusName}
            count={String(statusCount)}
            onClick={() => setCurrentStatus(statusCode)}
            active={currentStatus === statusCode}
            icon={<AiOutlineBars />}
          />
        ))}
      </TabWrapper>

      <div className="card">
        <DataTable
          value={filtered || []}
          showGridlines
          responsiveLayout="scroll"
          header={header2}
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "2.5rem" }}></Column>
          <Column field="created" sortable header="Created"></Column>
          <Column field="code" sortable header="Code"></Column>
          <Column field="client" sortable header="Client"></Column>
          <Column field="currentTask" sortable header="Current Task"></Column>
          <Column
            field="status"
            body={statusTemplate}
            sortable
            header="Status"
          ></Column>
          <Column header="Acts" body={actionsTemplate}></Column>
        </DataTable>
      </div>
    </>
  );
}

const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
`;

const Button = styled.button`
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #f0f0f0;
  &:hover {
    background-color: #e0e0e0;
  }
`;

const StyledIcon = styled.span`
  margin-right: 5px;
`;

const StatusChip = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px; // This is shorthand for padding-top, padding-right, padding-bottom, padding-left
  font-size: 12px;
  width: 100px;
  color: white;
  background-color: ${({ status }) => getStatusColor(status)};
`;

const getStatusColor = (status) => {
  switch (status) {
    case "New":
      return "#2b9f2b"; // Green for 'New'
    case "Rejected":
      return "#df4848"; // Original Red for 'Rejected'
    default:
      return "#777777"; // Default gray for other statuses
  }
};

export default ViewPlList;
