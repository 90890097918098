import { hdrService } from "../../services/Hdr";
import { handlePostRequest } from "../../services/PostTemplate";
import {
  GETTENANCYSUCCESS,
  GETTENANCYERROR,
  GETTENANCYSTATUSSUCCESS,
  GETTENANCYSTATUSERROR,
  GETTENANCYLISTSUCCESS,
  GETTENANCYLISTERROR,
} from "../slices/tenancySlice";
import { SETLOADING } from "../slices/loadingSlice";
import { handleGetRequest } from "../../services/GetTemplate";

export const getTenancyList = (statusUrl) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(statusUrl);
  if (res) {
    dispatch(GETTENANCYLISTSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETTENANCYLISTERROR(res?.data?.data));
    dispatch(SETLOADING(false));
  }
};

export const getTenancyDetails = (tenancyCaseId) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest(
    `tenancy/getTenancyCaseById/${tenancyCaseId}`
  );
  if (res) {
    dispatch(GETTENANCYSUCCESS(res?.data));
    dispatch(SETLOADING(false));
  } else {
    dispatch(GETTENANCYERROR(res?.data));
    dispatch(SETLOADING(false));
  }
};
export const getHdrStatus = () => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handleGetRequest("/tenancy/getAllTenancyStatusCounts");
  if (res) {
    dispatch(GETTENANCYSTATUSSUCCESS(res?.data));
    dispatch(SETLOADING(false));
    return res;
  } else {
    dispatch(GETTENANCYSTATUSERROR(res));
    dispatch(SETLOADING(false));
  }
};

export const postHdrDocs = (docs) => async (url) => {
  const res = await hdrService.postHdrDocs(docs, url);
  if (res) {
    return res;
  }
  return false;
};

export const addHdrCase = (data) => async (dispatch) => {
  dispatch(SETLOADING(true));
  const res = await handlePostRequest(data, "hdr/addNewHdrCase");
  dispatch(SETLOADING(false));
  return res;
};
