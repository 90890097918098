import { useState, useEffect } from "react";
import { handleGetRequest } from "../../services/GetTemplate";

export const useCampaignDuplicateCounts = (api, code) => {
  const [duplicateCount, setDuplicateCount] = useState(null);

  useEffect(() => {
    const getDuplicateCount = async () => {
      try {
        const response = await handleGetRequest(`${api}/${code}`, false);
        setDuplicateCount(response?.data || null);
      } catch (error) {
        console.log(error);
      }
    };
    if (code && api) {
      getDuplicateCount();
    }
  }, [api, code]);

  return duplicateCount;
};
