import React, { useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Chip } from "primereact/chip";
import { compareAsc } from "date-fns";
import EditJobButton from "./EditJob/EditJobButton";
import ViewJobButton from "./ViewJob/ViewJobButton";

function CompanyJobDetails({ jobList, getjobList }) {
  const dt = useRef(null);

  const actionsTemplate = (data) => {
    return (
      <div className="d-flex justify-content-flex-start">
        <ViewJobButton data={data} />
        <EditJobButton data={data} getjobList={getjobList} />
      </div>
    );
  };

  const statusTemplate = (rowData) => {
    return (
      <Chip
        label={rowData?.status === "Y" ? "Active" : "Inactive"}
        className="custom-chip p-px-4"
      />
    );
  };

  const dateSortFunction = (event) => {
    const value = [...jobList];
    value.sort((data1, data2) => {
      let value1 = data1[event.field];
      let value2 = data2[event.field];
      let result = null;

      if (value1 === null && value2 !== null) result = -1;
      else if (value1 !== null && value2 === null) result = 1;
      else if (value1 === null && value2 === null) result = 0;
      else if (typeof value1 === "string" && typeof value2 === "string") {
        // Parse date strings in DD-MM-YYYY format to compare
        const [day1, month1, year1] = value1.split("-");
        const [day2, month2, year2] = value2.split("-");
        const date1 = new Date(`${year1}-${month1}-${day1}`);
        const date2 = new Date(`${year2}-${month2}-${day2}`);
        result = date1 - date2;
      } else result = compareAsc(new Date(value1), new Date(value2));

      return event.order * result;
    });

    return value;
  };

  return (
    <div className="card mt-2">
      <DataTable
        ref={dt}
        value={jobList || []}
        loading={jobList === null}
        paginator
        rows={10}
        emptyMessage="No Jobs found in this company. Please add a new job."
        header={"Company Jobs"}
      >
        <Column
          field="tblCompaign.compaignname"
          sortable
          header="Compaign"
          filter
          filterMatchMode="contains"
        />
        <Column
          field="createdon"
          sortable
          header="Added on"
          filter
          filterMatchMode="contains"
          sortFunction={dateSortFunction} // Custom sort function for dates
        />
        <Column
          field="companystatus"
          sortable
          body={statusTemplate}
          header="Status"
          filter
          filterMatchMode="contains"
        />
        <Column header="Action" body={actionsTemplate} />
      </DataTable>
    </div>
  );
}

export default CompanyJobDetails;
