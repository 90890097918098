import React from "react";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { useSelector } from "react-redux";
import ShowHiddenFields from "../Captcha/ShowHiddenFields";

function LaInput({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  type,
  disabled,
  masking = false, // show masking if captcha is required
  ...rest
}) {
  const captchaVerified = useSelector(
    (state) => state.captchaVerifiedSlice.captuaVerified
  );
  const displayedValue =
    masking && !captchaVerified
      ? values[keyName]?.replace(/./g, "*")
      : values[keyName];

  const handleChange = (e) => {
    // Propagate changes only if CAPTCHA is verified or masking is not enabled
    if (!masking || captchaVerified) {
      onChange(e);
    }
  };

  return (
    <>
      <InputText
        id={keyName}
        type={type || "text"}
        disabled={disabled || false}
        placeholder={placeholder}
        name={keyName}
        value={displayedValue}
        onChange={handleChange}
        {...rest}
        className={classNames({
          "p-invalid": isFormFieldValid(keyName.toString()),
        })}
      />
      {getFormErrorMessage(keyName.toString())}
      {masking && !captchaVerified && <ShowHiddenFields iconOnly />}
    </>
  );
}

export default LaInput;
