import React from "react";
import LaVehicleReg from "../../../components/LaVehicleReg";

function ClaimantVehicle({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    classNames,
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };

  const vehicleRegProps = {
    ...props,
    keyNameRegNo: "registerationno",
    keyNameMakeModel: "makemodel",
    keyNameYearOfManufacture: "yearofmanufacture",
    keyNameInsurer: "insurer",
    keyNamePolicyNo: "policyno",
    keyNameRefNo: "refno",
  };

  return (
    <>
      <div style={{ marginLeft: "30px", marginTop: "20px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
          Claimant Vehicle
        </h6>
      </div>
      <LaVehicleReg {...vehicleRegProps} setVehicleType />
    </>
  );
}

export default ClaimantVehicle;
