import React from "react";
import { Checkbox } from "primereact/checkbox";
import classNames from "classnames";

function LaCheckbox({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  handleChanges,
  ...rest
}) {
  return (
    <>
      {handleChanges ? (
        <Checkbox
          id={keyName}
          placeholder={placeholder}
          name={keyName}
          value={
            values[keyName] === true || values[keyName] === "Y" ? true : false
          }
          onChange={(e) => {
            onChange(e);
            handleChanges(e);
          }}
          checked={
            values[keyName] === true || values[keyName] === "Y" ? true : false
          }
          {...rest}
          className={classNames({
            "p-invalid": isFormFieldValid(keyName.toString()),
          })}
        />
      ) : (
        <Checkbox
          id={keyName}
          placeholder={placeholder}
          name={keyName}
          value={
            values[keyName] === true || values[keyName] === "Y" ? true : false
          }
          onChange={(e) => {
            onChange(e);
          }}
          checked={
            values[keyName] === true || values[keyName] === "Y" ? true : false
          }
          {...rest}
          className={classNames({
            "p-invalid": isFormFieldValid(keyName.toString()),
          })}
        />
      )}

      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default LaCheckbox;
