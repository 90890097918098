import React from "react";
import "./Dashboard.css";
import CaseHeader from "../../components/CaseHeader";
import StatusCounts from "./StatusCounts";
import PieChartDemo from "./PieChart";
import VerticalBarDemo from "./VerticalBar";
import LineDemo from "./LineChart";
import DashboardNav from "./DashboardNav";
import { useSelector } from "react-redux";

function Dashboard() {
  const user = JSON.parse(
    useSelector((state) => state.authenticationSlice.loggedIn)
  );
  return (
    <>
      <CaseHeader title="Dashboard" subTitle={user?.name} />
      <DashboardNav />
      {/* <div className="p-mt-4">
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-4 p-mt-4">
            <PieChartDemo />
          </div>
          <div className="p-col-4 p-mt-4">
            <LineDemo />
          </div>
          <div className="p-col-4 p-mt-4">
            <VerticalBarDemo />
          </div>
        </div>
      </div> */}
      <div>
        <StatusCounts
          compaign={"/rta/getAllRtaStatusCounts"}
          heading={"Rta Status Counts"}
        />
        <StatusCounts
          compaign={"/hdr/getAllHdrStatusCounts"}
          heading={"HDR Status Counts"}
        />
        <StatusCounts
          compaign={"/hire/getAllHireStatusCounts"}
          heading={"Hire Status Counts"}
        />
      </div>
    </>
  );
}

export default Dashboard;
