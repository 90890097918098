import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import React from "react";

function DataBreachInfo({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  return (
    <div>
      <div className="p-mt-4 p-ml-4 p-mr-4">
        <div className="Panel__Heading">Data Breach Details</div>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              DATE OF BREACH: When did the data breach occur? *Ideally an exact
              date but an approximate date will do for now - this is crucial in
              assessing limitation*
            </label>
            <InputText
              type="date"
              id="dateOfBreach"
              name="dateOfBreach"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("dateOfBreach"),
                },
                "p-d-block Width__100"
              )}
              value={values.dateOfBreach}
              onChange={onChange}
            />
            {getFormErrorMessage("dateOfBreach")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              DATE OF KNOWLEDGE: When were you made aware of the data breach?
              *This is important - but this does NOT determine the beginning of
              the limitation clock*
            </label>
            <InputTextarea
              rows={5}
              id="dateOfKnowledge"
              name="dateOfKnowledge"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("dateOfKnowledge"),
                },
                "p-d-block Width__100"
              )}
              value={values.dateOfKnowledge}
              onChange={onChange}
            />
            {getFormErrorMessage("dateOfKnowledge")}
          </div>
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">
              How do you know that your data was breached? Were you notified by
              the Defendant? If so, how?
            </label>
            <InputTextarea
              rows={5}
              id="howKnowAbtDb"
              name="howKnowAbtDb"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("howKnowAbtDb"),
                },
                "p-d-block Width__100"
              )}
              value={values.howKnowAbtDb}
              onChange={onChange}
            />
            {getFormErrorMessage("howKnowAbtDb")}
          </div>
          <div className="p-col-6 p-mt-2">
            <label className="p-d-block">
              Do you have evidence of the data breach (e.g. email or letter from
              the Defendant)
            </label>
            <InputText
              id="evidenceOfDb"
              name="evidenceOfDb"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("evidenceOfDb"),
                },
                "p-d-block Width__100"
              )}
              value={values.evidenceOfDb}
              onChange={onChange}
            />
            {getFormErrorMessage("evidenceOfDb")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Please give me a brief overview of how you feel your data has been
              breached.
            </label>
            <InputTextarea
              rows={5}
              id="howFeelYourDb"
              name="howFeelYourDb"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("howFeelYourDb"),
                },
                "p-d-block Width__100"
              )}
              value={values.howFeelYourDb}
              onChange={onChange}
            />
            {getFormErrorMessage("howFeelYourDb")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              How do you feel when you first found out about the Data Breach?
            </label>
            <InputTextarea
              rows={5}
              id="frstFoundAbtDb"
              name="frstFoundAbtDb"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("frstFoundAbtDb"),
                },
                "p-d-block Width__100"
              )}
              value={values.frstFoundAbtDb}
              onChange={onChange}
            />
            {getFormErrorMessage("frstFoundAbtDb")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              What personal information was disclosed in the breach? (e.g.
              personal details such as name, address, phone number, or things
              like contacts, photos, medical information, financial information
              etc.) *This will help to determine quantum*
            </label>
            <InputTextarea
              rows={5}
              id="piDisclosedInDb"
              name="piDisclosedInDb"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("piDisclosedInDb"),
                },
                "p-d-block Width__100"
              )}
              value={values.piDisclosedInDb}
              onChange={onChange}
            />
            {getFormErrorMessage("piDisclosedInDb")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Where was your personal information shared or published? (Public
              website, including social media sites? Printed publication?
              Another individuals email address or home address?) *Important as
              this could increase quantum*
            </label>
            <InputTextarea
              rows={5}
              id="piShared"
              name="piShared"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("piShared"),
                },
                "p-d-block Width__100"
              )}
              value={values.piShared}
              onChange={onChange}
            />
            {getFormErrorMessage("piShared")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Was the breach a single event or recurring/multiple breaches of
              personal information? *Again this could increase quantum*
            </label>
            <InputTextarea
              rows={5}
              id="recurringDbOfPi"
              name="recurringDbOfPi"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("recurringDbOfPi"),
                },
                "p-d-block Width__100"
              )}
              value={values.recurringDbOfPi}
              onChange={onChange}
            />
            {getFormErrorMessage("recurringDbOfPi")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Did the Defendant, at any point, request consent from you to share
              or disclose your personal information? *if yes ask Claimant for
              evidence of this if available*
            </label>
            <InputTextarea
              rows={5}
              id="defendentReqConsent"
              name="defendentReqConsent"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("defendentReqConsent"),
                },
                "p-d-block Width__100"
              )}
              value={values.defendentReqConsent}
              onChange={onChange}
            />
            {getFormErrorMessage("defendentReqConsent")}
          </div>
          <div className="p-col-12 p-mt-2">
            <label className="p-d-block">
              Did you give consent for the personal information to be shared or
              disclosed? *if yes ask Claimant for evidence of this if available*
            </label>
            <InputTextarea
              rows={5}
              id="consentPiShared"
              name="consentPiShared"
              className={classNames(
                {
                  "p-inputtext-error": isFormFieldValid("consentPiShared"),
                },
                "p-d-block Width__100"
              )}
              value={values.consentPiShared}
              onChange={onChange}
            />
            {getFormErrorMessage("consentPiShared")}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataBreachInfo;
