import React, { useEffect, useState } from "react";
import { handleGetRequest } from "../../services/GetTemplate";
import ClickableBox from "./ClickableBox";
import { handlePostRequest } from "../../services/PostTemplate";
import InvoiceProcessingForm from "./InvoiceProcessingForm";
import { useFormik } from "formik";
import * as Yup from "yup";

function InvoiceProcessing() {
  const [userCat, setUserCat] = useState([]);
  const [compaignCode, setCompaignCode] = useState();

  useEffect(() => {
    const handleFetch = async () => {
      try {
        const response = await handleGetRequest("lovCompaign");
        setUserCat(response.data);
      } catch (e) {
        console.log(e);
      }
    };
    handleFetch();
  }, []);

  const handleClick = async (item) => {
    setCompaignCode(item.code);
    formik.handleSubmit();
  };
  const validationSchema = Yup.object().shape({
    companycode: Yup.array().min(1, "Required"),
  });

  const initialValues = {
    companycode: [],
    billByDate: "",
    billallpendingcases: false,
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };
  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      data["campaignCode"] = compaignCode;
      data["companyCodeList"] = data.companycode;
      data["billallpendingcases"] = data.billallpendingcases ? "Y" : "N";
      data["billByDate"] =
        data.billallpendingcases === "Y" ? "" : data.billByDate;
      try {
        await handlePostRequest(data, "/invoicing/manualJob");
        formik.resetForm();
      } catch (e) {
        console.log(e);
      }
    },
  });

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  return (
    <>
      <InvoiceProcessingForm {...props} />

      <div className="d-flex flex-wrap">
        {userCat.map((item, index) => {
          return (
            <div key={index} className="mr-3 mb-3">
              <ClickableBox
                name={item.name}
                onClick={() => handleClick(item)}
              />
            </div>
          );
        })}
      </div>
    </>
  );
}

export default InvoiceProcessing;
