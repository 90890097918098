import { baseURL } from "../Config";
import axios from "axios";
import { toast } from "react-toastify";

export const eService = {
  getESigns: async (code, compaignName, pass, encryptedUrl, url) => {
    let res;
    await axios({
      method: "POST",
      url: `${baseURL}${compaignName}/getESignFields`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        compaignCode: code,
        pwd: pass,
        encryptedUrl,
        url,
      },
    })
      .then((response) => {
        res = response.data.data;
      })
      .catch((err) => {
        toast.warn(err?.response?.data?.messages);
        res = false;
      });
    return res;
  },
};
