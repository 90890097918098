import { Dialog } from "primereact/dialog";
import React from "react";
import HireFilterCaseListForm from "./HireFilterCaseListForm";

function HireFilterCaseListDialog({ showModal, onCloseModal }) {
  return (
    <Dialog
      header="Filter Cases"
      visible={showModal}
      style={{ width: "70vw" }}
      onHide={onCloseModal}
    >
      <HireFilterCaseListForm onCloseModal={onCloseModal} />
    </Dialog>
  );
}

export default HireFilterCaseListDialog;
