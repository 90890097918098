import React from "react";
import SimpleReactLightBox, { SRLWrapper } from "simple-react-lightbox";
import LazyLoad from "react-lazyload";
import { FaFileArchive } from "react-icons/fa"; // Import the zip file icon
import "./Multimedia.css";
import DeleteMedia from "./DeleteMedia";
import EsignMedia from "./EsignMedia";
import PdfMedia from "./PdfMedia";
import DocumentVideos from "./DocumentVideos";

function Multimedia({
  documents,
  onDeleteDocument,
  deleteApi,
  documentType, // New prop to specify the document type
}) {
  if (!documents?.length) {
    return null;
  }

  const nonVideoDocuments = documents.filter(
    (item) => item?.doctype !== "video/mp4"
  );

  return (
    <div>
      <SimpleReactLightBox>
        <SRLWrapper>
          <div className="multimedia-container">
            {nonVideoDocuments &&
              nonVideoDocuments.map((item, index) => (
                <LazyLoad key={index} height={200} offset={100} once>
                  <div className="documentItem">
                    {item?.doctype === "Esign" && (
                      <>
                        <DeleteMedia
                          documentId={item[documentType]}
                          deleteApi={deleteApi}
                          onDeleteDocument={onDeleteDocument}
                        />
                        <EsignMedia docurl={item?.docurl} />
                      </>
                    )}
                    {item?.doctype === "application/pdf" && (
                      <>
                        <DeleteMedia
                          documentId={item[documentType]}
                          deleteApi={deleteApi}
                          onDeleteDocument={onDeleteDocument}
                        />
                        <PdfMedia docurl={item?.docurl} />
                      </>
                    )}
                    {(item?.doctype === "image/jpeg" ||
                      item?.doctype === "image/png" ||
                      item?.doctype === "Image" ||
                      item?.doctype === "image/jpg") && (
                      <>
                        <DeleteMedia
                          documentId={item[documentType]}
                          deleteApi={deleteApi}
                          onDeleteDocument={onDeleteDocument}
                        />
                        <img
                          className="doc-img"
                          src={
                            "documentPath" in item
                              ? item?.documentPath?.replaceAll("\\", "//")
                              : "docurl" in item
                              ? item?.docurl?.replaceAll("\\", "//")
                              : item?.docbase64
                          }
                          alt={item?.docname || "Document"}
                        />
                        <p>{item?.docname}</p>
                      </>
                    )}
                    {item?.doctype === "application/zip" && (
                      <>
                        <DeleteMedia
                          documentId={item[documentType]}
                          deleteApi={deleteApi}
                          onDeleteDocument={onDeleteDocument}
                        />
                        <div className="zip-preview">
                          <FaFileArchive className="zip-icon" />
                          <a
                            href={item?.docurl?.replaceAll("\\", "//")}
                            download
                          >
                            {item?.docname || "Download Zip"}
                          </a>
                        </div>
                      </>
                    )}
                  </div>
                </LazyLoad>
              ))}
          </div>
        </SRLWrapper>
      </SimpleReactLightBox>
      <DocumentVideos
        documentList={documents}
        documentType={documentType}
        onDeleteDocument={onDeleteDocument}
        deleteApi={deleteApi}
      />
    </div>
  );
}

export default Multimedia;
