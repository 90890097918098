import React from "react";
import styled from "styled-components";
import { FiMail, FiUser, FiClock, FiCheckCircle } from "react-icons/fi";

const Container = styled.div`
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #f9f9f9;
`;

const Header = styled.h3`
  text-align: center;
  color: #333;
`;

const Detail = styled.p`
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #555;
  margin: 10px 0;
  padding: 5px 0;

  & > svg {
    margin-right: 10px;
    color: #007bff;
  }
`;

const EmailBodyContainer = styled.div`
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
`;

const EmailBodyHeader = styled.h4`
  margin-bottom: 10px;
  color: #007bff;
`;

const EmailBodyContent = styled.div`
  font-size: 14px;
  line-height: 1.6;
  color: #333;

  & a {
    color: #007bff;
    text-decoration: none;
  }

  & .main {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }

  & p {
    margin: 10px 0;
  }

  & .main-content {
    font-size: 14px;
    line-height: 1.6;
  }

  & .la-link {
    text-decoration: none;
    color: #007bff;
  }

  & .footer-text {
    font-size: 12px;
    color: #666;
  }

  & .footer-heading-text {
    font-size: 12px;
    color: #666;
    margin-bottom: 5px;
  }

  & .remove-padding {
    padding: 0;
  }
`;

const EmailDetail = ({ data }) => {
  const {
    ccemailaddress,
    createdon,
    emailaddress,
    emailbody,
    emailsubject,
    senflag,
  } = data;

  return (
    <Container>
      <Header>Email Details</Header>
      <Detail>
        <FiMail /> <strong>Subject:</strong> {emailsubject}
      </Detail>
      <Detail>
        <FiUser /> <strong>To:</strong> {emailaddress}
      </Detail>
      <Detail>
        <FiUser /> <strong>Cc:</strong> {ccemailaddress}
      </Detail>
      <Detail>
        <FiClock /> <strong>Created On:</strong> {createdon}
      </Detail>
      <Detail>
        {senflag === 1 ? <FiCheckCircle color="green" /> : <FiCheckCircle />}{" "}
        <strong>Status:</strong> {senflag === 1 ? "Sent" : "Not Sent"}
      </Detail>
      <EmailBodyContainer>
        <EmailBodyHeader>Email Body:</EmailBodyHeader>
        <EmailBodyContent dangerouslySetInnerHTML={{ __html: emailbody }} />
      </EmailBodyContainer>
    </Container>
  );
};

export default EmailDetail;
