import React from "react";
import LaInput from "../../../components/LaInput";
import LaDropdown from "../../../components/LaDropdown";
import LaStaticDropdown from "../../../components/LaStaticDropdown";

function OicAccidentDetails({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };
  return (
    <>
      <div style={{ marginLeft: "30px", marginTop: "20px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
          Accident Details & Witness Details
        </h6>
        <hr className="border__hr" />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Police Officer Name</label>
        <LaInput keyName="policeOfficerName" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Police Station Address</label>
        <LaInput keyName="policeStationAddress" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Client Involvement ID</label>
        <LaDropdown
          keyName="tblClientInvolvementId"
          optionLabel="name"
          url={"/lovClientInvolvement"}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Is Correct Vehicle?</label>
        <LaStaticDropdown
          keyName="correctVehicleYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Does Client Own Vehicle?</label>
        <LaStaticDropdown
          keyName="clientOwnVehicleYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">People in Vehicle</label>
        <LaInput keyName="peopleInVehicle" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Was Seatbelt Worn?</label>
        <LaStaticDropdown
          keyName="seatbeltYn"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Were There Any Witnesses?</label>
        <LaStaticDropdown
          keyName="tblRtawitnesses"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Who Did Client See?</label>
        <LaInput keyName="whoClientSee" {...props} />
      </div>

      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Who Client Saw (Detail)</label>
        <LaInput keyName="whoClientSeeDetail" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Were There Any Witnesses?</label>
        <LaStaticDropdown
          keyName="tblRtawitnesses"
          optionLabel="label"
          options={[
            { value: "Y", label: "Yes" },
            { value: "N", label: "No" },
          ]}
          {...props}
          placeholder="Select"
        />
      </div>
    </>
  );
}

export default OicAccidentDetails;
