import React, { useState } from "react";
import Logo from "../../assets/images/TCGlogo.png";
import "./Navbar.css";
import { NavDropdown, NavMenuItem, NavMenuItemWrapper } from "./NavItem";
import { FaFileAlt, FaPhone, FaUser } from "react-icons/fa";
import { useSelector } from "react-redux";
import UpdatePassword from "../ChangePassword";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { handlePostRequest } from "../../services/PostTemplate";

function Navbar() {
  // this is actually coming from tblCompanyprofile
  const user = JSON.parse(
    useSelector((state) => state.authenticationSlice.loggedIn)
  );
  const nav = JSON.parse(useSelector((state) => state.authenticationSlice.nav));
  // this is actually coming from tblUser
  const tblUser = JSON.parse(
    useSelector((state) => state.authenticationSlice.tblUser)
  );
  const [showChangePassword, setShowChangePassword] = useState();
  const navigate = useNavigate();

  const logout = async () => {
    await handlePostRequest({}, "logout");
    localStorage.clear();
    window.location.href = "/";
  };

  return (
    <div className="navbar navbar-default navbar-fixed-top" role="navigation">
      <div className="navbar-header">
        <a className="navbar-brand" href="/">
          <img src={Logo} alt="The claims group" width={80} />
        </a>
      </div>
      <div className="navbar-medium">
        {nav?.map((item, index) => {
          return (
            <NavDropdown key={index} icon={<FaFileAlt />} title={item?.label}>
              <NavMenuItemWrapper>
                {item?.items?.map((item, index) => {
                  return (
                    <NavMenuItem key={index} onClick={() => navigate(item?.to)}>
                      {item?.label}
                    </NavMenuItem>
                  );
                })}
              </NavMenuItemWrapper>
            </NavDropdown>
          );
        })}
      </div>

      <div className="navbar-right d-flex px-4">
        <div className="text-muted text-semibold ">
          {} <FaPhone /> &nbsp; {user?.phone}
        </div>
        <NavDropdown icon={<FaUser />} title={tblUser?.loginid}>
          <NavMenuItemWrapper>
            <NavMenuItem onClick={() => setShowChangePassword(true)}>
              Change Password
            </NavMenuItem>
            <NavMenuItem>
              <UserInformation>
                <div className="d-flex">
                  <div>
                    <div className="text-muted">{user?.contactperson}</div>
                    <div className="text-muted">{user?.name}</div>
                    <div className="text-muted">
                      {user?.tblUsercategory?.categoryname}
                    </div>
                    <div className="text-muted">{tblUser?.username}</div>
                  </div>
                </div>
              </UserInformation>
            </NavMenuItem>
            <NavMenuItem onClick={logout}> Logout</NavMenuItem>
          </NavMenuItemWrapper>
        </NavDropdown>
        <UpdatePassword
          show={showChangePassword}
          hide={() => setShowChangePassword(false)}
        />
      </div>
    </div>
  );
}

const UserInformation = styled.div`
  width: 300px;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  background: #fff;
  hover {
    background: #f5f5f5 !important;
  }
`;

export default Navbar;
