import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { getLovCampaign } from "../../services/JobsRegister";
import { useSelector, useDispatch } from "react-redux";
import {
  PostEditJobsAction,
  PostJobsAction,
} from "../../redux/actions/profileAction";
import EditViewJobsData from "./EditViewJobsData";

function EditViewJobs({ name, tag, userCat }) {
  const [add, setadd] = useState(false); // default is edit
  const [companyjobcode, setcompanyjobcode] = useState("");
  const [viewMode, setviewMode] = useState(false); // default is edit

  const [wiplash, setwiplash] = useState(0);
  const [minor, setminor] = useState(0);
  const [hybrid, sethybrid] = useState(0);
  const [pedestrian, setpedestrian] = useState(0);
  const [scotishRta, setscotishRta] = useState(0);
  const [compaignCode, setcompaignCode] = useState([]);
  const [compaignCodeValue, setcompaignCodeValue] = useState([]);
  const [selectedState, setSelectedState] = useState(null);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [loading, setloading] = useState(false);
  const [loadingIcon, setloadingIcon] = useState("");
  const [compaignType, setcompaignType] = useState("");
  const [standardRoadworthy, setstandardRoadworthy] = useState(0);
  const [standardUnRoadworthy, setstandardUnRoadworthy] = useState(0);
  const [prestigeRoadworthy, setprestigeRoadworthy] = useState(0);
  const [prestigeUnroadworthy, setprestigeUnroadworthy] = useState(0);
  const [recovery, setrecovery] = useState(0);
  const [salvage, setsalvage] = useState(0);
  const [repairs, setrepairs] = useState(0);
  const [faultrepairs, setfaultrepairs] = useState(0);
  const [solicitorsfees, setsolicitorsfees] = useState(0);
  const [bike, setbike] = useState(0);

  let states = [
    {
      code: "Y",
      name: "Active",
      type: null,
    },
    {
      code: "N",
      name: "Inactive",
      type: null,
    },
  ];

  const dispatch = useDispatch();

  async function funcgetLovCampaign() {
    const res = await getLovCampaign();
    setcompaignCode(res.data);
    setcompaignCodeValue(res.data[0]);
  }

  const CompanyCode = useSelector(
    (state) => state.profileSlice.singleCompanyData?.companycode
  );

  const companyJobsData = useSelector((state) => state.profileSlice.jobsData);
  const handleSubmit = async () => {
    if (add) {
      handleAddJobSubmit();
    } else {
      handleEditJobSubmit();
    }
  };
  const handleEditJobSubmit = async () => {
    setloading(true);
    setloadingIcon("pi pi-spin pi-spinner");
    const data = {
      compaigncode: compaignCodeValue.code,
      companycode: CompanyCode,
      status: selectedState.code,
      companyjobcode,
    };
    if (compaignType === "RTA") {
      data.wiplash = wiplash.toString();
      data.minor = minor.toString();
      data.hybrid = hybrid.toString();
      data.bike = bike.toString();
      data.pedestrian = pedestrian.toString();
      data.scotishRta = scotishRta.toString();
    } else if (compaignType === "HIRE") {
      data.standardroadworthy = standardRoadworthy.toString();
      data.standardunroadworthy = standardUnRoadworthy.toString();
      data.prestigeroadworthy = prestigeRoadworthy.toString();
      data.prestigeunroadworthy = prestigeUnroadworthy.toString();
      data.recovery = recovery.toString();
      data.salvage = salvage.toString();
      data.repairs = repairs.toString();
      data.faultrepairs = faultrepairs.toString();
    } else if (compaignType === "HDR") {
      data.solicitorsfees = solicitorsfees.toString();
    }

    try {
      await dispatch(PostEditJobsAction(data));
      setDisplayBasic(!displayBasic);
      setInitialValues();
      setloading(false);
      setloadingIcon("");
    } catch (err) {
      setloading(false);
      setloadingIcon("");
    }
  };

  const setInitialValues = () => {
    setSelectedState("");
    setwiplash("");
    setminor("");
    sethybrid("");
    setscotishRta("");
    setpedestrian("");
    setstandardRoadworthy("");
    setstandardUnRoadworthy("");
    setprestigeRoadworthy("");
    setprestigeUnroadworthy("");
    setrecovery("");
    setsalvage("");
    setrepairs("");
    setfaultrepairs("");
    setsolicitorsfees("");
    setbike("");
    setcompaignType("");
    setcompaignCodeValue("");
  };

  const editRow = async (rowData) => {
    setviewMode(false);
    console.log(rowData);
    setadd(false);
    setDisplayBasic(!displayBasic);
    setSelectedState({
      code: rowData?.status,
      name: rowData?.status === "Y" ? "Active" : "Inactive",
      type: null,
    });
    setcompaignCodeValue({
      code: rowData.tblCompaign.compaigncode,
      name: rowData.tblCompaign.compaignname,
      type: null,
    });
    setcompanyjobcode(rowData.companyjobcode);
    setwiplash(rowData.wiplash);
    setminor(rowData.minor);
    sethybrid(rowData.hybrid);
    setpedestrian(rowData.pedestrian);
    setscotishRta(rowData.scotishRta);
    setstandardRoadworthy(rowData.standardroadworthy);
    setstandardUnRoadworthy(rowData.standardunroadworthy);
    setprestigeRoadworthy(rowData.prestigeroadworthy);
    setprestigeUnroadworthy(rowData.prestigeunroadworthy);
    setrecovery(rowData.recovery);
    setsalvage(rowData.salvage);
    setrepairs(rowData.repairs);
    setfaultrepairs(rowData.faultrepairs);
    setsolicitorsfees(rowData.solicitorsfees);
    setbike(rowData.bike);
    setcompaignType(rowData.tblCompaign.compaignname);
  };
  const addRow = async () => {
    setviewMode(false);
    setadd(true);
    setDisplayBasic(!displayBasic);
    setInitialValues();
  };
  const handleAddJobSubmit = async () => {
    setloading(true);
    setloadingIcon("pi pi-spin pi-spinner");
    const data = {
      compaigncode: compaignCodeValue.code,
      companycode: CompanyCode,
      status: selectedState.code,
    };
    if (compaignType === "RTA") {
      data.wiplash = wiplash.toString();
      data.minor = minor.toString();
      data.hybrid = hybrid.toString();
      data.bike = bike.toString();
      data.pedestrian = pedestrian.toString();
      data.scotishRta = scotishRta.toString();
    } else if (compaignType === "HIRE") {
      data.standardroadworthy = standardRoadworthy.toString();
      data.standardunroadworthy = standardUnRoadworthy.toString();
      data.prestigeroadworthy = prestigeRoadworthy.toString();
      data.prestigeunroadworthy = prestigeUnroadworthy.toString();
      data.recovery = recovery.toString();
      data.salvage = salvage.toString();
      data.repairs = repairs.toString();
      data.faultrepairs = faultrepairs.toString();
    } else if (compaignType === "HDR") {
      data.solicitorsfees = solicitorsfees.toString();
    }
    try {
      await dispatch(PostJobsAction(data));
      setDisplayBasic(!displayBasic);
      setInitialValues();
      setloading(false);
      setloadingIcon("");
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    funcgetLovCampaign();
  }, []);

  const onViewMode = (rowData) => {
    editRow(rowData);
    setviewMode(true);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "baseline",
        }}
      >
        <label>
          <b> Name : {name}</b>
        </label>
        <label>
          <b>Tag : {tag}</b>{" "}
        </label>
        <label>
          <b>Category : {userCat?.name}</b>{" "}
        </label>
        <div className="mb-2">
          <Button
            className="btn btn-block btn-primary"
            label="Add"
            icon="pi pi-external-link"
            onClick={addRow}
          />
        </div>
      </div>
      <EditViewJobsData
        editRow={editRow}
        viewMode={onViewMode}
        companyJobsData={companyJobsData}
      />
      <Dialog
        header={viewMode ? "View Job" : add ? "Add Job" : "Edit Job"}
        visible={displayBasic}
        style={{ width: "80%" }}
        onHide={() => {
          setDisplayBasic(!displayBasic);
          setcompaignType("");
        }}
        draggable={false}
      >
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-6">
            <label htmlFor="childfee">Compaign</label>
            <Dropdown
              className="dropdown__width__100"
              inputId="IntroducerCategory"
              value={compaignCodeValue}
              options={compaignCode}
              onChange={(e) => {
                setcompaignType(e.value.name);
                setcompaignCodeValue(e.value);
              }}
              disabled={viewMode}
              placeholder="Select"
              optionLabel="name"
            />
          </div>
        </div>
        {compaignType === "RTA" ? (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-6">
              <label>Whiplash</label>
              <InputNumber
                disabled={viewMode}
                value={wiplash}
                onValueChange={(e) => setwiplash(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label>Minor</label>
              <InputNumber
                disabled={viewMode}
                value={minor}
                onValueChange={(e) => setminor(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
          </div>
        ) : null}
        {compaignType === "RTA" ? (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-6">
              <label>Hybrid</label>
              <InputNumber
                disabled={viewMode}
                value={hybrid}
                onValueChange={(e) => sethybrid(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label>Bike</label>
              <InputNumber
                disabled={viewMode}
                value={bike}
                onValueChange={(e) => setbike(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
          </div>
        ) : null}
        {compaignType === "RTA" ? (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-6">
              <label>Pedestrian</label>
              <InputNumber
                disabled={viewMode}
                value={pedestrian}
                onValueChange={(e) => setpedestrian(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
            <div className="p-field p-col-12 p-md-6">
              <label>Scotish Rta</label>
              <InputNumber
                disabled={viewMode}
                value={scotishRta}
                onValueChange={(e) => setscotishRta(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
          </div>
        ) : null}
        {compaignType === "HIRE" ? (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-4">
              <label>Standard Roadworthy</label>
              <InputNumber
                disabled={viewMode}
                value={standardRoadworthy}
                onValueChange={(e) => setstandardRoadworthy(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
            <div className="p-field p-col-12 p-md-4">
              <label>Standard Un Roadworthy</label>
              <InputNumber
                disabled={viewMode}
                value={standardUnRoadworthy}
                onValueChange={(e) => setstandardUnRoadworthy(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
            <div className="p-field p-col-12 p-md-4">
              <label>Prestige Roadworthy</label>
              <InputNumber
                disabled={viewMode}
                value={prestigeRoadworthy}
                onValueChange={(e) => setprestigeRoadworthy(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
          </div>
        ) : null}
        {compaignType === "HIRE" ? (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-3">
              <label>Prestige Un Roadworthy</label>
              <InputNumber
                disabled={viewMode}
                value={prestigeUnroadworthy}
                onValueChange={(e) => setprestigeUnroadworthy(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
            <div className="p-field p-col-12 p-md-3">
              <label>Recovery</label>
              <InputNumber
                disabled={viewMode}
                value={recovery}
                onValueChange={(e) => setrecovery(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
            <div className="p-field p-col-12 p-md-3">
              <label>Salvage</label>
              <InputNumber
                disabled={viewMode}
                value={salvage}
                onValueChange={(e) => setsalvage(e.value)}
                prefix="%"
                max={100}
              />
            </div>
          </div>
        ) : null}

        {compaignType === "HIRE" ? (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-4">
              <label>Repairs</label>
              <InputNumber
                disabled={viewMode}
                value={repairs}
                onValueChange={(e) => setrepairs(e.value)}
                prefix="%"
                max={100}
              />
            </div>
            <div className="p-field p-col-12 p-md-4">
              <label>Fault Repairs</label>
              <InputNumber
                disabled={viewMode}
                value={faultrepairs}
                onValueChange={(e) => setfaultrepairs(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
          </div>
        ) : null}

        {compaignType === "HDR" ? (
          <div className="p-fluid p-formgrid p-grid">
            <div className="p-field p-col-12 p-md-6">
              <label>Solicitors Fees</label>
              <InputNumber
                disabled={viewMode}
                value={solicitorsfees}
                onValueChange={(e) => setsolicitorsfees(e.value)}
                mode="currency"
                currency="GBP"
                minFractionDigits={2}
              />
            </div>
          </div>
        ) : null}

        <div className="p-fluid p-formgrid p-grid">
          <div className="p-field p-col-12 p-md-6">
            <label>Status</label>
            <Dropdown
              disabled={viewMode}
              className="dropdown__width__100"
              value={selectedState}
              options={states}
              onChange={(e) => setSelectedState(e.value)}
              placeholder="Select"
              optionLabel="name"
            />
          </div>
        </div>
        {!viewMode ? (
          <div style={{ textAlign: "center" }}>
            <Button
              icon={loadingIcon || ""}
              disabled={loading}
              onClick={handleSubmit}
              type="submit"
              label="Submit"
              className="btn btn-block btn-primary"
            />
          </div>
        ) : null}
      </Dialog>
    </div>
  );
}

export default EditViewJobs;
