import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { SplitButton } from "primereact/splitbutton";
import LaInput from "../LaInput";
import axios from "axios";
import { vehicleRegKey } from "../LaVehicleReg";
import VehicleDetailsButton from "../LaVehicleReg/VehicleDetailsButton";

function LaVehicleRegHire({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  keyNameRegNo,
  keyNameMakeModel,
  keyNameYearOfManufacture,
}) {
  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const props = {
    classNames,
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };
  const registerationnoItems = [
    {
      label: "No TP reg available",
      command: (e) => {
        formik.setFieldValue(keyNameRegNo, e.item.label);
        formik.setFieldValue(keyNameYearOfManufacture, "");
        formik.setFieldValue(keyNameMakeModel, "");
      },
    },
    {
      label: "Will be provided to hire firm",
      command: (e) => {
        formik.setFieldValue(keyNameRegNo, e.item.label);
        formik.setFieldValue(keyNameYearOfManufacture, "");
        formik.setFieldValue(keyNameMakeModel, "");
      },
    },
  ];

  const getMakeModel = async () => {
    // check if nothing is changed
    if (
      values[keyNameRegNo] === "No TP reg available" ||
      values[keyNameRegNo] === "Will be provided to hire firm"
    ) {
      return;
    }
    if (values[keyNameRegNo].length > 2) {
      setLoading(true);
      const url = `https://uk1.ukvehicledata.co.uk/api/datapackage/VehicleData?v=2&api_nullitems=1&auth_apikey=${vehicleRegKey}&user_tag=&key_VRM=${values[keyNameRegNo]}`;
      try {
        const response = await axios.get(url);
        formik.setFieldValue(
          keyNameMakeModel,
          response?.data?.Response?.DataItems?.VehicleRegistration?.MakeModel ||
            ""
        );
        formik.setFieldValue(
          keyNameYearOfManufacture,
          response?.data?.Response?.DataItems?.VehicleRegistration
            ?.YearOfManufacture || ""
        );
        setResponseData(response?.data);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    }
  };
  return (
    <>
      <hr className="border__hr" />
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Registration Number</label>
        <div className="p-inputgroup la__inputGroup">
          <InputText
            id={keyNameRegNo}
            placeholder="Registration Number"
            name={keyNameRegNo}
            value={values[keyNameRegNo]}
            onChange={onChange}
            onBlur={() => {
              getMakeModel();
            }}
            className={classNames({
              "p-invalid": isFormFieldValid(keyNameRegNo.toString()),
            })}
          />
          <div>
            <SplitButton model={registerationnoItems} disabled={loading} />
          </div>
        </div>
        {getFormErrorMessage(keyNameRegNo.toString())}
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Make Model</label>
        <LaInput
          keyName={keyNameMakeModel}
          placeholder="make model"
          {...props}
        />
      </div>
      {values[keyNameYearOfManufacture] && (
        <div className="p-col-12 p-mt-2">
          <div className="p-col-12">
            <span className="label-info Width__100">
              Year of Manufacture : {values[keyNameYearOfManufacture]}
            </span>
            <span className="d-flex justify-content-center mt-2">
              <VehicleDetailsButton responseData={responseData} />
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default LaVehicleRegHire;
