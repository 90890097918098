import React from "react";
import moment from "moment";
import styled from "styled-components";
import {
  FaArrowCircleUp,
  FaArrowCircleDown,
  FaUser,
  FaClipboard,
  FaCalendarAlt,
} from "react-icons/fa";

const LogTable = styled.table`
  border-collapse: collapse;
  width: 100%;
`;

const LogHeader = styled.th`
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
`;

const LogRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const LogData = styled.td`
  border: 1px solid #ccc;
  padding: 10px;
  vertical-align: middle;
  text-align: left;

  &:first-child {
    width: 150px; /* Adjust the width value as needed */
  }
`;

const IconWrapper = styled.span`
  display: flex;
  align-items: center;
`;

const ArrowUpIcon = styled(FaArrowCircleUp)`
  color: green;
  margin-right: 5px; /* Add spacing between icon and text */
`;

const ArrowDownIcon = styled(FaArrowCircleDown)`
  color: red;
  margin-right: 5px; /* Add spacing between icon and text */
`;

const UserIcon = styled(FaUser)`
  color: blue;
  margin-right: 5px; /* Add spacing between icon and text */
`;

const FieldIcon = styled(FaClipboard)`
  color: purple;
  margin-right: 5px; /* Add spacing between icon and text */
`;

const LogComponent = ({ logs }) => {
  const formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY HH:mm:ss");
  };

  return (
    <LogTable>
      <thead>
        <tr>
          <LogHeader>Audit Date</LogHeader>
          <LogHeader>Field Name</LogHeader>
          <LogHeader>Logged User</LogHeader>
          <LogHeader>New Value</LogHeader>
          <LogHeader>Old Value</LogHeader>
        </tr>
      </thead>
      <tbody>
        {logs.map((log, index) => (
          <LogRow key={index}>
            <LogData>
              <IconWrapper>
                <FaCalendarAlt />
                {formatDate(log.auditDate)}
              </IconWrapper>
            </LogData>
            <LogData>
              <IconWrapper>
                <FieldIcon />
                {log.fieldName}
              </IconWrapper>
            </LogData>
            <LogData>
              <IconWrapper>
                <UserIcon />
                {log.loggedUser}
              </IconWrapper>
            </LogData>
            <LogData>
              {log.newValue !== log.oldValue ? (
                <IconWrapper>
                  <ArrowUpIcon />
                  {log.newValue}
                </IconWrapper>
              ) : (
                log.newValue
              )}
            </LogData>
            <LogData>
              {log.newValue !== log.oldValue ? (
                <IconWrapper>
                  <ArrowDownIcon />
                  {log.oldValue}
                </IconWrapper>
              ) : (
                log.oldValue
              )}
            </LogData>
          </LogRow>
        ))}
      </tbody>
    </LogTable>
  );
};

export default LogComponent;
