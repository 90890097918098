import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { FiSearch, FiChevronUp, FiChevronDown, FiX } from "react-icons/fi";
import { toast } from "react-toastify";

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const SearchInput = styled.input`
  flex: 1;
  padding: 8px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-right: 8px;
`;

const ActionButton = styled.button`
  background-color: ${(props) => props.backgroundColor || "#007bff"};
  color: #fff;
  padding: 8px 12px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-right: 8px;

  &:hover {
    background-color: ${(props) => props.hoverColor || "#0056b3"};
  }
`;

const SearchIcon = styled(FiSearch)`
  margin-right: 8px;
`;

const ClearIcon = styled(FiX)`
  margin-right: 8px;
`;

const ResultsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 10px;
`;

const ResultsText = styled.span`
  margin-right: 5px;
`;

const ScrollButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 18px;

  &:hover {
    color: #007bff;
  }
`;

const AdvancedSearch = ({
  onSearch,
  onClear,
  searchResults,
  scrollToResult,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [scrollIndex, setScrollIndex] = useState(0);
  const searchResultsIndex = searchResults?.map((result) => result.index);

  const handleSearch = () => {
    if (searchQuery === "") {
      toast.error("Please enter a search query");
      handleClear();
      return;
    }
    onSearch(searchQuery);
    setScrollIndex(0); // Reset scrollIndex when search query changes
    handleScrollUp();
  };

  const handleClear = () => {
    setSearchQuery("");
    onClear();
  };

  const handleScrollUp = () => {
    if (scrollIndex > 0) {
      setScrollIndex(scrollIndex - 1);
      scrollToResult(searchResultsIndex[scrollIndex - 1]);
    } else {
      scrollToResult(searchResultsIndex[0]);
    }
  };

  useEffect(() => {
    if (searchResults && searchResults.length > 0) {
      scrollToResult(searchResultsIndex[0]);
    }
  }, [searchResults]);

  const handleScrollDown = () => {
    if (scrollIndex < searchResultsIndex.length - 1) {
      setScrollIndex(scrollIndex + 1);
      scrollToResult(searchResultsIndex[scrollIndex + 1]);
    } else {
      scrollToResult(searchResultsIndex[searchResultsIndex.length - 1]);
    }
  };

  return (
    <SearchContainer>
      <SearchInput
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder="Search notes"
      />
      <ActionButton
        onClick={handleSearch}
        backgroundColor="#007bff"
        hoverColor="#0056b3"
      >
        <SearchIcon />
        Search
      </ActionButton>
      {searchResults && searchResults.length > 0 && (
        <ActionButton
          onClick={handleClear}
          backgroundColor="#dc3545"
          hoverColor="#c82333"
        >
          <ClearIcon />
          Clear
        </ActionButton>
      )}

      {searchResults && searchResults.length > 0 && (
        <ResultsContainer>
          <ResultsText>
            {searchResults.length}{" "}
            {searchResults.length === 1 ? "Result" : "Results"}
          </ResultsText>
          <ScrollButton onClick={handleScrollUp}>
            <FiChevronUp />
          </ScrollButton>
          <ScrollButton onClick={handleScrollDown}>
            <FiChevronDown />
          </ScrollButton>
        </ResultsContainer>
      )}
    </SearchContainer>
  );
};

export default AdvancedSearch;
