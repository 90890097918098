import * as Yup from "yup";

const initialValues = {
  accdatetime: "",
  acctime: "",
  accdescription: "",
  acclocation: "",
  accreportedto: "",
  describeinjuries: "",
  dob: "",
  email: "",
  injuriesduration: "",
  landline: "",
  medicalattention: "",
  mobile: "",
  ninumber: "",
  occupation: "",
  password: "",
  remarks: "",
  witnesses: "",
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  region: "",
  anywitnesses: "",
};

const validationSchema = Yup.object().shape({
  accdatetime: Yup.string().required("required."),
  acctime: Yup.string().required("required."),
  acclocation: Yup.string().required("required."),
  address1: Yup.string().required("required."),
  city: Yup.string().required("required."),
  dob: Yup.string().required("required."),
  title: Yup.string().required("required."),
  firstname: Yup.string().required("required."),
  lastname: Yup.string().required("required."),
  postalcode: Yup.string().required("required."),
  accdescription: Yup.string().required("required."),
  describeinjuries: Yup.string().required("required."),
  injuriesduration: Yup.string().required("required."),
  medicalattention: Yup.string().required("required."),
  accreportedto: Yup.string().required("required."),
  mobile: Yup.string()
    .required("required.")
    .nullable()
    .max(11, "Max 11 digits")
    .min(11, "Min 11 digits")
    .matches(/^[0-9]+$/, "Must be only digits"),
  ninumber: Yup.string()
    .required("required.")
    .test("ninumber", "Invalid NIN", (value) => {
      if (
        value === "Will be provided to the solicitor" ||
        value === "Minor" ||
        value === "Solicitor obtained Number"
      ) {
        return true;
      }
      if (value) {
        let ninumberFirstChar = value[0];
        let ninumberSecondChar = value[1];
        let prefix = ninumberFirstChar + ninumberSecondChar;
        let prefixCheckList = ["BG", "GB", "KN", "NK", "NT", "TN", "ZZ"];
        let checklist = "D,F,I,Q,U,V";
        if (
          checklist.includes(ninumberSecondChar) ||
          checklist.includes(ninumberFirstChar) ||
          value[1] === "O" ||
          prefixCheckList.includes(prefix)
        ) {
          return false;
        } else {
          const re = new RegExp(
            /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1,}?\s*$/
          );
          return re.test(value);
        }
      }
      return true;
    }),
});
export { initialValues, validationSchema };
