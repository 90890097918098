import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useFormik } from "formik";
import classNames from "classnames";
import { handlePostRequest } from "../../services/PostTemplate";
import { useDispatch, useSelector } from "react-redux";
import { initialValues, validationSchema } from "./HireConstants";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { nameTitleOptions } from "../../utilities/constants";
import { RadioButton } from "primereact/radiobutton";
import { Checkbox } from "primereact/checkbox";
import { getHireDetails } from "../../redux/actions/hireAction";
import LaAddress from "../../components/LaAddress";
import { Chip } from "primereact/chip";
import { calculate_age } from "../../utilities/commonFunctions";
import LaDropdown from "../../components/LaDropdown";
import LaVehicleRegHire from "../../components/LaVehicleRegHire";
import LaMobile from "../../components/LaMobile/LaMobile";
import styled from "styled-components";
import LaStaticDropdown from "../../components/LaStaticDropdown";
import VDCFA from "./VDCFA";

function HireEdit({
  edit,
  onSave,
  isSubmitFormik,
  handleReturnEdit,
  onSetIsSubmitFormikFalse,
}) {
  const { hire } = useSelector((state) => state.hireSlice);
  const [isBusinessAccepted, setIsBusinessAccepted] = useState(false);
  const [hirebusinessdetailcode, setHirebusinessdetailcode] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSubmitFormik) {
      formik.handleSubmit();
      onSetIsSubmitFormikFalse();
    }
  }, [isSubmitFormik]);

  useEffect(() => {
    if (edit) {
      fillFormFields();
    }
  }, [edit, hire]);

  const fillFormFields = () => {
    const data = { ...hire };
    Object.keys(data).forEach((key) => {
      if (data[key] === null) {
        data[key] = "";
      }
    });
    Object.keys(data).forEach((key) => {
      if (formik.initialValues.hasOwnProperty(key)) {
        formik.setFieldValue(key, data[key]);
      }
    });
    formik.setFieldValue("circumcode", data?.circumcode?.circumcode.toString());

    if (data?.tblHirebusinesses && data?.tblHirebusinesses.length > 0) {
      const businessWithAcceptedStatus = data?.tblHirebusinesses.find(
        (business) => business.status === "A"
      );
      if (
        businessWithAcceptedStatus &&
        businessWithAcceptedStatus?.tblHirebusinessdetails.length
      ) {
        setIsBusinessAccepted(true);
        const {
          bookingdate,
          bookingmode,
          hirestartdate,
          hireenddate,
          hirebusinessdetailcode,
        } = businessWithAcceptedStatus.tblHirebusinessdetails[0];
        formik.setFieldValue("bookingdate", bookingdate);
        formik.setFieldValue("bookingmode", bookingmode);
        formik.setFieldValue("hirestartdate", hirestartdate);
        formik.setFieldValue("hireenddate", hireenddate);
        setHirebusinessdetailcode(hirebusinessdetailcode);
      }
    } else {
      setIsBusinessAccepted(false);
      setHirebusinessdetailcode(null);
      formik.setFieldValue("bookingdate", "");
      formik.setFieldValue("bookingmode", "");
      formik.setFieldValue("hirestartdate", "");
      formik.setFieldValue("hireenddate", "");
    }
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      data.circumcode = data?.circumcode?.code || data.circumcode;
      data["hirecode"] = hire?.hirecode;
      Object.keys(data).forEach((key) => {
        if (data[key] === "") {
          data[key] = null;
        }
      });
      if (isBusinessAccepted) {
        data["updateHireBuisnessDetail"] = {
          bookingdate: data.bookingdate,
          bookingmode: data.bookingmode,
          hirestartdate: data.hirestartdate,
          hireenddate: data.hireenddate,
          hirebusinessdetailcode,
        };
      } else {
        data["updateHireBuisnessDetail"] = null;
      }
      try {
        await handlePostRequest(data, "hire/updateHireCase");
        handleReturnEdit();
        dispatch(getHireDetails(hire?.hirecode));
      } catch (error) {
        console.log("error", error);
      } finally {
        onSetIsSubmitFormikFalse();
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const props = {
    isFormFieldValid: isFormFieldValid,
    getFormErrorMessage: getFormErrorMessage,
    classNames: classNames,
    onChange: formik.handleChange,
    values: formik.values,
    formik,
  };

  const vehicleRegProps = {
    ...props,
    keyNameRegNo: "registerationno",
    keyNameMakeModel: "makemodel",
    keyNameYearOfManufacture: "yearofmanufacture",
  };

  const tpVehicleRegProps = {
    ...props,
    keyNameRegNo: "partyregno",
    keyNameMakeModel: "partymakemodel",
    keyNameYearOfManufacture: "partyyearofmanufacture",
  };

  const addressProps = {
    ...props,
    keyNameCity: "city",
    keyNameRegion: "region",
    keyNamePostcode: "postalcode",
    keyNameAddress1: "address1",
    keyNameAddress2: "address2",
    keyNameAddress3: "address3",
    placeholder: "Enter Postcode",
  };

  return (
    <>
      <div className="p-ml-4">
        <form onSubmit={edit ? onSave : formik.handleSubmit}>
          <hr className="border__hr" />

          <div>
            <div className="row">
              <div className="col-md-10">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="p-col-3 ">
                    <label className="label__style">
                      Title <span className="text-danger">*</span>
                    </label>
                    <Dropdown
                      options={nameTitleOptions}
                      optionLabel="name"
                      placeholder="Select a Title"
                      value={formik.values.title}
                      onChange={(e) => formik.setFieldValue("title", e.value)}
                      className={
                        (classNames({
                          "p-invalid": isFormFieldValid("title"),
                        }),
                        "Width__100")
                      }
                    />
                    {getFormErrorMessage("title")}
                  </div>
                  {/* firstname */}
                  <div className="p-col-3">
                    <label className="label__style">First Name</label>
                    <InputText
                      id="firstname"
                      placeholder="First Name"
                      name="firstname"
                      value={formik.values.firstname}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("firstname"),
                      })}
                    />
                    {getFormErrorMessage("firstname")}
                  </div>
                  {/* middlename */}
                  <div className="p-col-3">
                    <label className="label__style">Middle Name</label>
                    <InputText
                      id="middlename"
                      placeholder="Middle Name"
                      name="middlename"
                      value={formik.values.middlename}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("middlename"),
                      })}
                    />
                    {getFormErrorMessage("middlename")}
                  </div>
                  {/* lastname */}
                  <div className="p-col-3">
                    <label className="label__style">Last Name</label>
                    <InputText
                      id="lastname"
                      placeholder="Last Name"
                      name="lastname"
                      value={formik.values.lastname}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("lastname"),
                      })}
                    />
                    {getFormErrorMessage("lastname")}
                  </div>
                </div>
              </div>
              <div className="col-md-10">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  {/* intcaseid */}
                  <div className="p-col-3 ">
                    <label className="label__style">Introducer Data</label>
                    <InputText
                      id="intcaseid"
                      placeholder="Int Case ID"
                      name="intcaseid"
                      value={formik.values.intcaseid}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("intcaseid"),
                      })}
                    />
                    {getFormErrorMessage("intcaseid")}
                  </div>
                  {/* intleadgen */}
                  <div className="p-col-3">
                    <label className="label__style"> Lead Gen</label>
                    <InputText
                      id="leadgen"
                      placeholder="Lead Gen"
                      name="leadgen"
                      value={formik.values.leadgen}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("leadgen"),
                      })}
                    />
                    {getFormErrorMessage("leadgen")}
                  </div>
                  {/* intcloser */}
                  <div className="p-col-3">
                    <label className="label__style"> Closer</label>
                    <InputText
                      id="closer"
                      placeholder="Closer"
                      name="closer"
                      value={formik.values.closer}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("closer"),
                      })}
                    />
                    {getFormErrorMessage("closer")}
                  </div>
                  {/* intpassword */}
                  <div className="p-col-3">
                    <label className="label__style"> Password</label>
                    <InputText
                      id="password"
                      placeholder="Password"
                      name="password"
                      value={formik.values.password}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("password"),
                      })}
                    />
                    {getFormErrorMessage("password")}
                  </div>
                </div>
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-10">
                {!isBusinessAccepted && (
                  <StyledAlert>
                    Hire Mode, Booking Date, Hire Start Date, Hire End Date are
                    disabled because there is no business accepted yet.
                  </StyledAlert>
                )}
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="Panel__Heading">Process Info</div>

                  {/* hiremode radiobutton */}
                  <div className="p-col-12">
                    <label className="label__style">Hire Mode</label>
                    <div className="d-flex flex-row justify-content-between col-md-4">
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="D"
                          name="bookingmode"
                          value="D"
                          onChange={formik.handleChange}
                          checked={formik.values.bookingmode === "D"}
                          disabled={!isBusinessAccepted}
                        />
                        <label htmlFor="D">Drivable</label>
                      </div>
                      <div className="p-field-radiobutton">
                        <RadioButton
                          inputId="N"
                          name="bookingmode"
                          value="N"
                          onChange={formik.handleChange}
                          checked={formik.values.bookingmode === "N"}
                          disabled={!isBusinessAccepted}
                        />
                        <label htmlFor="N">Non Drivable</label>
                      </div>
                    </div>
                  </div>
                  {getFormErrorMessage("bookingmode")}
                </div>
              </div>
            </div>

            <div className="p-fluid p-formgrid p-grid p-pr-4">
              {/* Dates */}
              <div className="p-col-4">
                <label className="label__style">Booking Date</label>
                {/* bookingdate */}
                <InputText
                  id="bookingdate"
                  type={"date"}
                  placeholder="Booking Date"
                  name="bookingdate"
                  value={formik.values.bookingdate}
                  onChange={formik.handleChange}
                  disabled={!isBusinessAccepted}
                  className={classNames({
                    "p-invalid": isFormFieldValid("bookingdate"),
                  })}
                />
                {getFormErrorMessage("bookingdate")}
              </div>
              {/* hire start date */}
              <div className="p-col-4">
                <label className="label__style">Hire Start Date</label>
                <InputText
                  id="hirestartdate"
                  type={"date"}
                  placeholder="Hire Start Date"
                  name="hirestartdate"
                  value={formik.values.hirestartdate}
                  onChange={formik.handleChange}
                  disabled={!isBusinessAccepted}
                  className={classNames({
                    "p-invalid": isFormFieldValid("hirestartdate"),
                  })}
                />
                {getFormErrorMessage("hirestartdate")}
              </div>
              {/* hire end date */}
              <div className="p-col-4">
                <label className="label__style">Hire End Date</label>
                <InputText
                  id="hireenddate"
                  type={"date"}
                  placeholder="Hire End Date"
                  name="hireenddate"
                  value={formik.values.hireenddate}
                  onChange={formik.handleChange}
                  disabled={!isBusinessAccepted}
                  tooltip="Enter your username"
                  tooltipOptions={{ position: "top" }}
                  className={classNames({
                    "p-invalid": isFormFieldValid("hireenddate"),
                  })}
                />
                {getFormErrorMessage("hireenddate")}
              </div>
            </div>

            <div className="row mt-4">
              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="Panel__Heading">Main Info</div>

                  {/* dob */}
                  <div className="p-col-12 p-mt-2">
                    <label className="p-d-block">
                      Date of Birth <span className="text-danger">*</span>
                    </label>
                    <InputText
                      type={"date"}
                      id="dob"
                      placeholder="Date of Birth"
                      name="dob"
                      value={formik.values.dob}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("dob"),
                      })}
                    />
                    {getFormErrorMessage("dob")}
                    <Chip
                      label={"Age " + calculate_age(formik.values.dob)}
                      className="p-px-4 custom-chip w-100 text-center"
                    />
                  </div>

                  {/* ninumber */}
                  <div className="p-col-6 p-mt-2">
                    <label className="p-d-block">
                      National Insurance Number
                    </label>
                    <InputText
                      id="ninumber"
                      placeholder="National Insurance Number"
                      name="ninumber"
                      value={formik.values.ninumber}
                      onChange={formik.handleChange}
                      className={classNames({
                        "p-invalid": isFormFieldValid("ninumber"),
                      })}
                    />
                    {getFormErrorMessage("ninumber")}
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="Panel__Heading">Contact details</div>
                  {/* mobile */}
                  <div className="row">
                    <VDCFA {...props} />
                    <div className="p-col-6 p-mt-2">
                      <label className="p-d-block">
                        Mobile <span className="text-danger">*</span>
                      </label>
                      <LaMobile keyName="mobile" masking {...props} />
                    </div>
                    {/* landLine */}
                    <div className="p-col-6 p-mt-2">
                      <label className="p-d-block">Landline</label>
                      <InputText
                        id="landLine"
                        placeholder="Landline"
                        name="landLine"
                        value={formik.values.landLine}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("landLine"),
                        })}
                      />
                      {getFormErrorMessage("landLine")}
                    </div>
                    {/* email */}
                    <div className="p-col-12 p-mt-2">
                      <label className="p-d-block">Email</label>
                      <InputText
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        className={classNames({
                          "p-invalid": isFormFieldValid("email"),
                        })}
                      />
                      {getFormErrorMessage("email")}
                    </div>
                    <LaAddress {...addressProps} />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h6
              style={{
                color: "#428BCA",
                fontWeight: "600",
                lineHeight: "1.42857143",
              }}
            >
              Accident Info
            </h6>
            <small style={{ color: "#999" }}>Enter accident information</small>
          </div>
          <hr className="border__hr" />
          <div>
            <div className="row">
              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="Panel__Heading">Main Info</div>
                  <div className="p-col-12 ">
                    <div className="row">
                      {/* accdate */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">
                          Accident Date <span className="text-danger">*</span>
                        </label>
                        <InputText
                          type={"date"}
                          id="accdate"
                          placeholder="Accident Date"
                          name="accdate"
                          value={formik.values.accdate}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("accdate"),
                          })}
                        />
                        {getFormErrorMessage("accdate")}
                        <Chip
                          label={
                            calculate_age(formik.values.accdate) + " days ago"
                          }
                          className="p-px-4 custom-chip w-100 text-center"
                        />
                      </div>
                      {/* accTime */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">
                          Accident Time <span className="text-danger">*</span>
                        </label>
                        <InputText
                          type="time"
                          id="acctime"
                          placeholder="Accident Time"
                          name="acctime"
                          value={formik.values.acctime}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("acctime"),
                          })}
                        />
                        {getFormErrorMessage("acctime")}
                      </div>
                      {/* circumcode */}
                      <div className="p-col-6 p-mt-2 ">
                        <label className="p-d-block">
                          Circumstances <span className="text-danger">*</span>
                        </label>
                        <LaDropdown
                          keyName="circumcode"
                          optionLabel="name"
                          placeholder="circumcode"
                          url={"/lovCircumstances"}
                          {...props}
                        />
                      </div>
                      {/* location */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">
                          Location <span className="text-danger">*</span>
                        </label>
                        <InputText
                          id="location"
                          placeholder="Location"
                          name="location"
                          value={formik.values.location}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("location"),
                          })}
                        />
                        {getFormErrorMessage("location")}
                      </div>

                      {/* description */}
                      <div className="p-col-12 p-mt-2">
                        <label className="p-d-block">Description</label>
                        <InputTextarea
                          id="description"
                          placeholder="Description"
                          name="description"
                          value={formik.values.description}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("description"),
                          })}
                        />
                        {getFormErrorMessage("description")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="Panel__Heading">Other Details</div>
                  <div className="p-col-12 ">
                    <div className="row">
                      {/* rdweathercond */}
                      <div className="p-col-12 p-mt-2">
                        <label className="p-d-block">
                          Road Weather Condition
                        </label>
                        <InputTextarea
                          id="rdweathercond"
                          rows={3}
                          placeholder="Road Weather Condition"
                          name="rdweathercond"
                          value={formik.values.rdweathercond}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("rdweathercond"),
                          })}
                        />
                        {getFormErrorMessage("rdweathercond")}
                      </div>

                      {/* vehicledamage */}
                      <div className="p-col-12 p-mt-2">
                        <label className="p-d-block"> Damage</label>
                        <InputTextarea
                          id="vehicledamage"
                          rows={3}
                          placeholder=" Damage"
                          name="vehicledamage"
                          value={formik.values.vehicledamage}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("vehicledamage"),
                          })}
                        />
                        {getFormErrorMessage("vehicledamage")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h6
              style={{
                color: "#428BCA",
                fontWeight: "600",
                lineHeight: "1.42857143",
              }}
            >
              Vehicle Info
            </h6>
            <small style={{ color: "#999" }}>Enter vehcile information</small>
          </div>
          <hr className="border__hr" />
          <div>
            <div className="row">
              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="Panel__Heading">Client Info</div>
                  <div className="p-col-12 ">
                    <div className="row">
                      {/* registerationno */}
                      <LaVehicleRegHire {...vehicleRegProps} />

                      <div className="p-col-12 p-mt-2">
                        <label className="p-d-block">Vehicle Type</label>
                        <LaStaticDropdown
                          keyName="vehicleType"
                          optionLabel="label"
                          options={[
                            { value: "S", label: "Standard" },
                            { value: "P", label: "Prestige" },
                          ]}
                          {...props}
                        />
                      </div>

                      {/* vehiclecondition radiobutton */}
                      <div className="p-col-8 p-mt-2">
                        <label className="p-d-block">Vehicle Condition</label>
                        <div className="d-flex flex-row justify-content-between">
                          <div className="p-field-radiobutton">
                            <RadioButton
                              inputId="vehiclecondition"
                              name="vehiclecondition"
                              value="drivable"
                              onChange={formik.handleChange}
                              checked={
                                formik.values.vehiclecondition === "drivable"
                              }
                            />
                            <label htmlFor="vehiclecondition">Drivable</label>
                          </div>
                          <div className="p-field-radiobutton">
                            <RadioButton
                              inputId="vehiclecondition"
                              name="vehiclecondition"
                              value="undrivable"
                              onChange={formik.handleChange}
                              checked={
                                formik.values.vehiclecondition === "undrivable"
                              }
                            />
                            <label htmlFor="vehiclecondition">
                              Non-Driveable
                            </label>
                          </div>
                          <div className="p-field-radiobutton">
                            <RadioButton
                              inputId="vehiclecondition"
                              name="vehiclecondition"
                              value="urgentrecovery"
                              onChange={formik.handleChange}
                              checked={
                                formik.values.vehiclecondition ===
                                "urgentrecovery"
                              }
                            />
                            <label htmlFor="vehiclecondition">
                              Urgent Recovery
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Recovered</label>
                        {/* checkbox */}
                        <Checkbox
                          inputId="recovered"
                          name="recovered"
                          value="recovered"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "recovered",
                              e.checked ? "Y" : "N"
                            );
                          }}
                          checked={formik.values.recovered === "Y"}
                        />
                        <label htmlFor="recovered" className="p-ml-2">
                          Recovered
                        </label>
                      </div>
                      {/* recoveredby*/}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Recovered By</label>
                        <InputText
                          id="recoveredby"
                          placeholder="Recovered By"
                          name="recoveredby"
                          value={formik.values.recoveredby}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("recoveredby"),
                          })}
                        />
                        {getFormErrorMessage("recoveredby")}
                      </div>
                      {/* instorage */}
                      <div className="p-col-6 p-mt-2">
                        {/* checkbox */}
                        <Checkbox
                          inputId="instorage"
                          name="instorage"
                          value="instorage"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "instorage",
                              e.checked ? "Y" : "N"
                            );
                          }}
                          checked={formik.values.instorage === "Y"}
                        />
                        <label htmlFor="instorage" className="p-ml-2">
                          In Storage
                        </label>
                      </div>
                      {/* storage */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Storage</label>
                        <InputText
                          id="storage"
                          placeholder="Storage"
                          name="storage"
                          value={formik.values.storage}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("storage"),
                          })}
                        />
                        {getFormErrorMessage("storage")}
                      </div>
                      {/* lengthoflicenceheld */}
                      <div className="p-col-12 p-mt-2">
                        <label className="p-d-block">
                          Length of Licence Held
                        </label>
                        <InputText
                          id="lengthoflicenceheld"
                          placeholder="Length of Licence Held"
                          name="lengthoflicenceheld"
                          value={formik.values.lengthoflicenceheld}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid(
                              "lengthoflicenceheld"
                            ),
                          })}
                        />
                        {getFormErrorMessage("lengthoflicenceheld")}
                      </div>

                      {/* policycover radiobuttons */}
                      <div className="p-col-8 p-mt-2">
                        <label className="p-d-block">Policy Cover</label>
                        <div className="d-flex flex-row justify-content-between">
                          <div className="p-field-radiobutton">
                            <RadioButton
                              inputId="policycover"
                              name="policycover"
                              value="fullycomprehensive"
                              onChange={formik.handleChange}
                              checked={
                                formik.values.policycover ===
                                "fullycomprehensive"
                              }
                            />
                            <label htmlFor="policycover">
                              Fully Comprehensive
                            </label>
                          </div>
                          <div className="p-field-radiobutton">
                            <RadioButton
                              inputId="policycover"
                              name="policycover"
                              value="TP"
                              onChange={formik.handleChange}
                              checked={formik.values.policycover === "TP"}
                            />
                            <label htmlFor="policycover">TP</label>
                          </div>
                          <div className="p-field-radiobutton">
                            <RadioButton
                              inputId="policycover"
                              name="policycover"
                              value="TPFT"
                              onChange={formik.handleChange}
                              checked={formik.values.policycover === "TPFT"}
                            />
                            <label htmlFor="policycover">
                              TP Fire And Theft
                            </label>
                          </div>
                        </div>
                      </div>
                      {/* policyholder */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Policy Holder</label>
                        <InputText
                          id="policyholder"
                          placeholder="Policy Holder"
                          name="policyholder"
                          value={formik.values.policyholder}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("policyholder"),
                          })}
                        />
                        {getFormErrorMessage("policyholder")}
                      </div>
                      {/* insurer */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Insurer</label>
                        <InputText
                          id="insurer"
                          placeholder="Insurer"
                          name="insurer"
                          value={formik.values.insurer}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("insurer"),
                          })}
                        />
                        {getFormErrorMessage("insurer")}
                      </div>
                      {/* policyno */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Policy No</label>
                        <InputText
                          id="policyno"
                          placeholder="Policy No"
                          name="policyno"
                          value={formik.values.policyno}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("policyno"),
                          })}
                        />
                        {getFormErrorMessage("policyno")}
                      </div>
                      {/* refno */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Reference Number</label>
                        <InputText
                          id="refno"
                          placeholder="Reference Number"
                          name="refno"
                          value={formik.values.refno}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("refno"),
                          })}
                        />
                        {getFormErrorMessage("refno")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="Panel__Heading">Third Party Info</div>
                  <div className="p-col-12 ">
                    <div className="row">
                      {/* partyregno */}
                      <LaVehicleRegHire {...tpVehicleRegProps} />
                      {/* partyname */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Party Name</label>
                        <InputText
                          id="partyname"
                          placeholder="Party Name"
                          name="partyname"
                          value={formik.values.partyname}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("partyname"),
                          })}
                        />
                        {getFormErrorMessage("partyname")}
                      </div>

                      {/* partycontactno */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Party Contact No</label>
                        <InputText
                          id="partycontactno"
                          placeholder="Party Contact No"
                          name="partycontactno"
                          value={formik.values.partycontactno}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("partycontactno"),
                          })}
                        />
                        {getFormErrorMessage("partycontactno")}
                      </div>
                      {/* partyaddress */}
                      <div className="p-col-12 p-mt-2">
                        <label className="p-d-block">Party Address</label>
                        <InputTextarea
                          id="partyaddress"
                          rows={3}
                          placeholder="Party Address"
                          name="partyaddress"
                          value={formik.values.partyaddress}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("partyaddress"),
                          })}
                        />
                        {getFormErrorMessage("partyaddress")}
                      </div>
                      {/* greencardno */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Green Card No</label>
                        <InputText
                          id="greencardno"
                          placeholder="Green Card No"
                          name="greencardno"
                          value={formik.values.greencardno}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("greencardno"),
                          })}
                        />
                        {getFormErrorMessage("greencardno")}
                      </div>
                      {/* partyinsurer */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Party Insurer</label>
                        <InputText
                          id="partyinsurer"
                          placeholder="Party Insurer"
                          name="partyinsurer"
                          value={formik.values.partyinsurer}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("partyinsurer"),
                          })}
                        />
                        {getFormErrorMessage("partyinsurer")}
                      </div>
                      {/* partypolicyno */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Party Policy No</label>
                        <InputText
                          id="partypolicyno"
                          placeholder="Party Policy No"
                          name="partypolicyno"
                          value={formik.values.partypolicyno}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("partypolicyno"),
                          })}
                        />
                        {getFormErrorMessage("partypolicyno")}
                      </div>
                      {/* partyrefno */}
                      <div className="p-col-6 p-mt-2">
                        <label className="p-d-block">Party Ref No</label>
                        <InputText
                          id="partyrefno"
                          placeholder="Party Ref No"
                          name="partyrefno"
                          value={formik.values.partyrefno}
                          onChange={formik.handleChange}
                          className={classNames({
                            "p-invalid": isFormFieldValid("partyrefno"),
                          })}
                        />
                        {getFormErrorMessage("partyrefno")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

const StyledAlert = styled.div`
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  display: flex;
`;

export default HireEdit;
