import React, { useState, useEffect } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { RadioButton } from "primereact/radiobutton";
import { Dialog } from "primereact/dialog";
import { hdraffectedperList } from "../../../utilities/constants";

function AffectedPersonModel({
  show,
  hide,
  handleAffectedReturn,
  affected,
  isEdit,
  viewmode,
  disabledForm,
}) {
  if (viewmode) {
    isEdit = "View";
  }
  const footer = (
    <div>
      {!viewmode ? (
        <>
          <button className="btn btn-primary" onClick={() => handleAffected()}>
            {!isEdit ? "Add" : "Update"}
          </button>
          {!isEdit && (
            <button
              className="btn btn-warning"
              onClick={() => seteffectDetails(hdraffectedperList)}
            >
              Reset
            </button>
          )}
        </>
      ) : (
        ""
      )}
    </div>
  );

  const [effectDetails, seteffectDetails] = useState(hdraffectedperList);

  const handleAffected = async () => {
    if (effectDetails?.personname) {
      handleAffectedReturn(effectDetails);
      hide(false);
      !isEdit && handleClear();
    }
  };

  const handleClear = () => {
    seteffectDetails(hdraffectedperList);
  };

  useEffect(() => {
    if (affected && Object.keys(affected).length !== 0)
      seteffectDetails(affected);
  }, [affected, show]);

  return (
    <Dialog
      header={
        isEdit && isEdit !== "View"
          ? "Edit Affected"
          : isEdit === "View"
          ? isEdit + " Affected Details"
          : "Add Affected"
      }
      footer={footer}
      visible={show}
      style={{ width: "50%" }}
      onHide={() => hide(false)}
    >
      <div
        className="p-fluid p-formgrid p-grid p-mt-4"
        disabledForm={disabledForm}
      >
        <div className="p-field p-col-12 p-md-6">
          <label>Person * </label>
          <InputText
            disabled={viewmode}
            value={effectDetails?.personname || ""}
            onChange={(e) => {
              seteffectDetails({
                ...effectDetails,
                personname: e.target.value,
              });
            }}
            placeholder="Full Name"
          />
        </div>

        <div className="p-field p-col-12 p-md-6">
          <label>Date of Birth </label>
          <InputText
            disabled={viewmode}
            type="date"
            value={effectDetails?.persondob || ""}
            onChange={(e) => {
              seteffectDetails({
                ...effectDetails,
                persondob: e.target.value,
              });
            }}
            placeholder="Full Name"
          />
        </div>

        <div className="p-field p-col-12 p-md-12">
          <label>Suffering From</label>
          <InputTextarea
            disabled={viewmode}
            value={effectDetails?.sufferingFrom || ""}
            onChange={(e) => {
              seteffectDetails({
                ...effectDetails,
                sufferingFrom: e.target.value,
              });
            }}
          />
        </div>

        <div className="p-col-12 p-mt-6">
          <label className="p-d-block">Has Medical Evidence?</label>
        </div>
        <div className="p-col-12">
          <div className="p-field-radiobutton p-d-flex">
            <RadioButton
              disabled={viewmode}
              value="Y"
              onChange={(e) => {
                seteffectDetails({
                  ...effectDetails,
                  medicalEvidence: e.value,
                });
              }}
              checked={effectDetails?.medicalEvidence === "Y"}
            />
            <label>Yes</label>
            &nbsp;
            <RadioButton
              disabled={viewmode}
              value="N"
              onChange={(e) => {
                seteffectDetails({
                  ...effectDetails,
                  medicalEvidence: e.value,
                });
              }}
              checked={effectDetails?.medicalEvidence === "N"}
            />
            <label>No</label>
          </div>
        </div>

        <div className="p-field p-col-12 p-md-12">
          <label>Medical Evidence Details</label>
          <InputTextarea
            disabled={viewmode}
            value={effectDetails?.evidenceDetail || ""}
            onChange={(e) => {
              seteffectDetails({
                ...effectDetails,
                evidenceDetail: e.target.value,
              });
            }}
          />
        </div>
      </div>
    </Dialog>
  );
}

export default AffectedPersonModel;
