import { Button } from "primereact/button";
import React, { useState } from "react";
import CaptchaDialog from "./CaptchaDialog";
import { FaEye } from "react-icons/fa";

function ShowHiddenFields({ iconOnly = false }) {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Button
        type="button"
        className="btn btn-primary"
        onClick={() => setShowModal(true)}
        tooltip={iconOnly ? "Show Hidden Fields" : ""}
        tooltipOptions={{ position: "top" }}
      >
        {iconOnly ? <FaEye /> : "Show Hidden Fields"}
      </Button>
      <CaptchaDialog showModal={showModal} onCloseModal={onCloseModal} />
    </>
  );
}

export default ShowHiddenFields;
