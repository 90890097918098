import React from "react";
import LaCheckbox from "../../../components/LaCheckbox";
import LaInputTextArea from "../../../components/LaInputTextArea";

function WitnessInformation({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  return (
    <>
      <div>
        <div className="Panel__Heading">Witness Information</div>
      </div>
      <div className="p-col-12 p-mt-2" style={{ display: "flex" }}>
        <LaCheckbox keyName="anywitnesses" {...props} />
        <label className="p-d-block" style={{ marginLeft: "10px" }}>
          Were there any witnesses?
        </label>
      </div>
      {values.anywitnesses && (
        <div className="p-col-12 p-mt-2">
          <label className="p-d-block">Witness Details</label>
          <LaInputTextArea
            keyName="witnessdetails"
            placeholder="Witness Details"
            {...props}
          />
        </div>
      )}
    </>
  );
}

export default WitnessInformation;
