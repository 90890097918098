import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import "./DataTable.css";
import { Chip } from "primereact/chip";
import styled from "styled-components";

const CenteredContainer = styled.div`
  /* Add styles to center the expanded card */
  display: flex;
  justify-content: center;
  align-items: center;
`;

const TableContainer = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeaderRow = styled.tr`
  background-color: #f0f0f0;
`;

const TableHeader = styled.th`
  padding: 10px;
  font-weight: bold;
`;

const TableDataRow = styled.tr``;

const TableData = styled.td`
  padding: 10px;
`;

const JobsData = () => {
  const [jobsData, setjobsData] = useState(null);
  const dt = useRef(null);
  const [expandedRows, setExpandedRows] = useState();

  const companyJobsData = useSelector(
    (state) => state.profileSlice.jobsFreshData
  );

  useEffect(() => {
    setjobsData(companyJobsData);
  }, [companyJobsData]);

  const compaignfeeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Compaign</span>
        {rowData.tblCompaign.compaignname}
      </React.Fragment>
    );
  };

  const bikeBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Bike</span>
        {rowData.bike}
      </React.Fragment>
    );
  };

  const pedestrianBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Pedestrian</span>
        {rowData.pedestrian}
      </React.Fragment>
    );
  };

  const minorBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Minor</span>
        {rowData.minor}
      </React.Fragment>
    );
  };

  const wiplashBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Whiplash</span>
        {rowData.wiplash}
      </React.Fragment>
    );
  };

  const scotishRtaBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <span className="p-column-title">Scotish Rta</span>
        {rowData.scotishRta}
      </React.Fragment>
    );
  };

  const statusBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Chip
          label={rowData.status === "Y" ? "Active" : "Inactive"}
          className={`p-px-4  ${
            rowData.status === "Y" ? "custom-chip" : "custom-chip-warning"
          }`}
        />
      </React.Fragment>
    );
  };

  const rowExpansionTemplate = (data) => {
    const hireFields = [
      { label: "Fault Repairs:", value: data?.faultrepairs },
      { label: "Prestige Roadworthy:", value: data?.prestigeroadworthy },
      { label: "Prestige Unroadworthy:", value: data?.prestigeunroadworthy },
      { label: "Recovery:", value: data?.recovery },
      { label: "Repairs:", value: data?.repairs },
      { label: "Salvage:", value: data?.salvage },
      { label: "Standard Roadworthy:", value: data?.standardroadworthy },
      { label: "Standard Unroadworthy:", value: data?.standardunroadworthy },
    ];

    const rtaFields = [
      { label: "Bike:", value: data?.bike },
      { label: "Hybrid:", value: data?.hybrid },
      { label: "Pedestrian:", value: data?.pedestrian },
      { label: "Scottish RTA:", value: data?.scotishRta },
      { label: "Wiplash:", value: data?.wiplash },
      { label: "Minor:", value: data?.minor },
    ];
    const hdrFields = [
      { label: "Solicitors Fees:", value: data?.solicitorsfees },
    ];

    return (
      <CenteredContainer>
        <TableContainer>
          <thead>
            <TableHeaderRow>
              <TableHeader>Hire Fields</TableHeader>
              <TableHeader>Value</TableHeader>
            </TableHeaderRow>
          </thead>
          <tbody>
            {hireFields.map((field, index) => (
              <TableDataRow key={index}>
                <TableData>{field.label}</TableData>
                <TableData>{field.value}</TableData>
              </TableDataRow>
            ))}

            {/* Add a separator row between the hireFields and rtaFields */}
            <tr>
              <td colSpan="2">&nbsp;</td>
            </tr>

            <TableHeaderRow>
              <TableHeader>RTA Fields</TableHeader>
              <TableHeader>Value</TableHeader>
            </TableHeaderRow>

            {rtaFields.map((field, index) => (
              <TableDataRow key={index}>
                <TableData>{field.label}</TableData>
                <TableData>{field.value}</TableData>
              </TableDataRow>
            ))}
          </tbody>

          <tbody>
            <tr>
              <td colSpan="2">&nbsp;</td>
            </tr>

            <TableHeaderRow>
              <TableHeader>HDR Fields</TableHeader>
              <TableHeader>Value</TableHeader>
            </TableHeaderRow>

            {hdrFields.map((field, index) => (
              <TableDataRow key={index}>
                <TableData>{field.label}</TableData>
                <TableData>{field.value}</TableData>
              </TableDataRow>
            ))}
          </tbody>
        </TableContainer>
      </CenteredContainer>
    );
  };

  return (
    <div className="">
      <div className="card p-datatable-sm">
        <DataTable
          scrollable
          ref={dt}
          value={jobsData}
          paginator
          rows={10}
          className="p-datatable-customers"
          emptyMessage="No data found."
          expandedRows={expandedRows}
          dataKey="companyjobcode"
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ maxWidth: "60px" }}></Column>
          <Column
            field="compaigndescr"
            header="Compaign"
            body={compaignfeeBodyTemplate}
            filter
            sortable
          />
          <Column field="hybrid" header="Hybrid" filter sortable />
          <Column
            field="bike"
            header="Bike"
            body={bikeBodyTemplate}
            filter
            sortable
          />
          <Column
            field="pedestrian"
            header="Pedestrian"
            body={pedestrianBodyTemplate}
            filter
            sortable
          />
          <Column
            field="minor"
            header="Minor"
            body={minorBodyTemplate}
            filter
            sortable
          />
          <Column
            field="wiplash"
            header="Whiplash"
            body={wiplashBodyTemplate}
            filter
            sortable
          />
          <Column
            field="scotishRta"
            header="Scotish Rta"
            body={scotishRtaBodyTemplate}
            filter
            sortable
          />
          <Column
            field="status"
            header="Status"
            body={statusBodyTemplate}
            filter
            sortable
          />
        </DataTable>
      </div>
    </div>
  );
};
export default JobsData;
