import React from "react";
import { FaTrash } from "react-icons/fa";
import styled from "styled-components";
import { handlePostRequest } from "../../services/PostTemplate";

import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { useSelector } from "react-redux";

const DeleteButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  border: none;
  color: red;
  cursor: pointer;
  margin-bottom: 5px;

  &:hover {
    transform: scale(1.2);
  }
`;

function DeleteMedia({ documentId, deleteApi, onDeleteDocument }) {
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const handleDelete = async (documentId) => {
    try {
      await handlePostRequest({ doccode: documentId }, deleteApi);
      onDeleteDocument();
    } catch (error) {
      console.log(error);
    }
  };
  const confirm1 = (documentId) => {
    confirmDialog({
      message: "Are you sure you want to proceed?",
      header: "Confirmation",
      icon: "pi pi-exclamation-triangle",
      accept: () => handleDelete(documentId),
      reject: () => {},
    });
  };

  if (user?.tblUsercategory?.categorycode !== "4") {
    return null;
  }

  return (
    <>
      <ConfirmDialog />
      <DeleteButton onClick={() => confirm1(documentId)}>
        <FaTrash color="red" />
      </DeleteButton>
    </>
  );
}

export default DeleteMedia;
