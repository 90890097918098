import React, { useEffect, useState } from "react";
import LaInput from "../../../components/LaInput";
import { InputText } from "primereact/inputtext";
import LaInputTextArea from "../../../components/LaInputTextArea";
import { Dropdown } from "primereact/dropdown";
import LaCheckbox from "../../../components/LaCheckbox";

function AccidentCircumstances({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const [noOfAccDaysTime, setNoOfAccDaysTime] = useState(false);
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
  };

  useEffect(() => {
    if (values.incidentdatetime && values.acctime) {
      const currentDate = new Date();
      const selectedDate = new Date(
        values.incidentdatetime + "T" + values.acctime
      );

      // Check if selected date is in the future
      if (selectedDate > currentDate) {
        setNoOfAccDaysTime(false);
        return;
      }

      // Calculate time difference
      const diffTime = Math.abs(currentDate - selectedDate);
      const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
      const diffHours = Math.floor((diffTime / (1000 * 60 * 60)) % 24);

      // Set the time difference object
      const diffTimeObj = {
        days: diffDays,
        hours: diffHours,
      };
      setNoOfAccDaysTime(diffTimeObj);
    } else {
      setNoOfAccDaysTime(false);
    }
  }, [values.incidentdatetime, values.acctime]);

  const handleMaxDate = (e, key) => {
    const today = new Date().toISOString().split("T")[0]; // Get today's date in ISO format

    if (e.target.value > today) {
      formik.setFieldValue(key, today);
    } else {
      formik.setFieldValue(key, e.target.value);
    }
  };

  return (
    <>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">
          Date <span className="text-danger">*</span>
        </label>
        <InputText
          type="date"
          id="incidentdatetime"
          name="incidentdatetime"
          value={values.incidentdatetime}
          onChange={(e) => {
            handleMaxDate(e, "incidentdatetime");
          }}
          className={classNames({
            "p-invalid": isFormFieldValid("incidentdatetime"),
          })}
        />
        {getFormErrorMessage("incidentdatetime")}
      </div>
      <div className="p-col-6 p-mt-2">
        <label className="p-d-block">
          Time <span className="text-danger">*</span>
        </label>
        <InputText
          type="time"
          id="acctime"
          name="acctime"
          value={values.acctime}
          onChange={(e) => {
            onChange(e);
          }}
          className={classNames({
            "p-invalid": isFormFieldValid("acctime"),
          })}
        />
        {getFormErrorMessage("acctime")}
      </div>
      {noOfAccDaysTime && (
        <div className="p-col-12">
          <span className="label-info">
            {noOfAccDaysTime.days === 1
              ? `${noOfAccDaysTime.days} day `
              : noOfAccDaysTime.days > 1
              ? `${noOfAccDaysTime.days} days `
              : ""}
            {noOfAccDaysTime.hours} hours ago
          </span>
        </div>
      )}
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Location<span className="text-danger">*</span>
        </label>
        <LaInput keyName="location" placeholder="Location" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Accident Description</label>
        <LaInputTextArea
          keyName="accdescription"
          placeholder="Injury Description"
          {...props}
        />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">
          Was it reported in the accident book and whom by?
        </label>
        <LaInput keyName="accreportedto" placeholder="Reported By" {...props} />
      </div>
      <div className="p-col-12 p-mt-2" style={{ display: "flex" }}>
        <LaCheckbox keyName="hospitalAttended" {...props} />
        <label className="p-d-block" style={{ marginLeft: "10px" }}>
          Did any Emergency Services attend?
        </label>
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Police Ref:</label>
        <LaInput keyName="policeRef" placeholder="Police Ref" {...props} />
      </div>
      <div className="p-col-12 p-mt-2" style={{ display: "flex" }}>
        <LaCheckbox keyName="hospitalAttended" {...props} />
        <label className="p-d-block" style={{ marginLeft: "10px" }}>
          Hospital attended?
        </label>
      </div>
    </>
  );
}

export default AccidentCircumstances;
