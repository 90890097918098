import { Button } from "primereact/button";
import React from "react";
import { handlePostRequest } from "../../../services/PostTemplate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function DuplicateCaseToRta() {
  const { rta } = useSelector((state) => state.rtaSlice);
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCatInternal = user?.tblUsercategory?.categorycode;

  const duplicateCasetoRta = async () => {
    const res = await handlePostRequest(
      { rtacode: rta.rtacode },
      "rta/copyRtaToRta"
    );
    navigate(`/rtadetails/${res.data?.rtacode}`);
  };

  if (userCatInternal !== "4" || rta?.rtacode === undefined) {
    return null;
  }

  return (
    <Button
      onClick={duplicateCasetoRta}
      label="Copy Case to Rta"
      className="btn-info btn-sm p-mr-2 p-mb-2"
    />
  );
}

export default DuplicateCaseToRta;
