import React from "react";
import styled from "styled-components";
import { AiOutlineBars } from "react-icons/ai";

const ClickableBox = ({ name, onClick }) => {
  const BoxContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 100px;
    height: 100px;
    color: black;
    padding: 10px;
    margin: 10px;
    font-size: 11px;
    // light grey
    background-color: #f2f2f2;
    text-align: center;
    &:hover {
      background-color: #e6e6e6;
    }
  `;

  return (
    <BoxContainer onClick={onClick}>
      <AiOutlineBars />
      <div style={{ padding: "2px" }}>{name}</div>
    </BoxContainer>
  );
};

export default ClickableBox;
