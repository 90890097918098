import React from "react";
import LaInputNumber from "../../components/LaInputNumber/LaInputNumber";
import { useUserCategory } from "../../useUserCategory";

function VDCFA({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  type,
  disabled,
  ...rest
}) {
  const userCategoryName = useUserCategory();
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    disabled,
    ...rest,
  };
  if (userCategoryName !== "LegalInternal") {
    return null;
  }
  return (
    <div className="p-col-6 p-mt-2">
      <label className="p-d-block">VD CFA Percentage</label>
      <LaInputNumber
        keyName="vdcfapercentage"
        placeholder="VD CFA Percentage"
        max={100}
        {...props}
      />
    </div>
  );
}

export default VDCFA;
