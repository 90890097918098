import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { handleGetRequest } from "../../services/GetTemplate";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import { campaignConfig } from "../CampaignGeneric/campaignConfig";
import { handlePostRequest } from "../../services/PostTemplate";

function ChangeInroForm({
  campaignName,
  getCampaignDetails,
  campaignCaseCode,
  onCloseModal,
}) {
  const [introducerOptions, setIntroducerOptions] = useState("");
  const [advisorOptions, setAdvisorOptions] = useState("");
  const { changeIntroApi, campaignCode } = campaignConfig[campaignName];
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const funcgetIntroducer = async () => {
    setLoading(true);
    const res = await handleGetRequest("/getIntoducers/" + campaignCode);
    setLoading(false);
    setIntroducerOptions(res?.data);
  };

  useEffect(() => {
    funcgetIntroducer();
  }, []);

  const handleIntroducer = async (e) => {
    formik.handleChange(e);
    const res = await handleGetRequest("/getAdvisor/" + e.value.companycode);
    setAdvisorOptions(res.data);
  };

  const formik = useFormik({
    validationSchema: Yup.object().shape({
      introducer: Yup.mixed().required("required"),
      advisor: Yup.mixed().required("required."),
    }),
    initialValues: {
      introducer: "",
      advisor: "",
    },
    onSubmit: async (data) => {
      const payload = {
        introducer: data?.introducer?.companycode,
        advisor: data?.advisor?.usercode,
        code: campaignCaseCode,
      };
      try {
        setLoading(true);
        await handlePostRequest(payload, changeIntroApi);
        setLoading(false);
        onCloseModal();
        dispatch(getCampaignDetails(campaignCaseCode));
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    },
  });

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid  mt-4">
          <div className="p-col-12 p-md-12">
            <label className="p-d-block">Introducer *</label>
            <Dropdown
              filter
              id="introducer"
              name="introducer"
              options={introducerOptions}
              value={formik.values.introducer}
              optionLabel="name"
              placeholder={loading ? "Loading..." : "Select a Name"}
              onChange={(e) => handleIntroducer(e)}
              className={classNames({
                "p-invalid": isFormFieldValid("introducer"),
                Width__100: true,
              })}
            />
            {getFormErrorMessage("introducer")}
          </div>
          <div className="p-col-12 p-md-12 mt-4">
            <label className="p-d-block">Advisor *</label>

            <Dropdown
              id="advisor"
              name="advisor"
              options={advisorOptions}
              value={formik.values.advisor}
              optionLabel="loginid"
              placeholder="Select a Name"
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("advisor"),
                Width__100: true,
              })}
            />
            {getFormErrorMessage("advisor")}
          </div>
        </div>
      </form>
      <div align="center" className="mt-4">
        <Button
          type="submit"
          icon={loading ? "pi pi-spin pi-spinner" : ""}
          className="btn btn-primary"
          label="Submit"
          onClick={formik.handleSubmit}
          disabled={loading}
        ></Button>
      </div>
    </div>
  );
}

export default ChangeInroForm;
