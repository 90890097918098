import React from "react";
import LaInput from "../../../components/LaInput";
import LaDropdown from "../../../components/LaDropdown";

function OicClaimantDetails({
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
}) {
  const props = {
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
  };
  return (
    <>
      <div style={{ marginLeft: "30px", marginTop: "20px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
          Claimant Details
        </h6>
        <hr className="border__hr" />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">ASKCUEPI</label>
        <LaInput keyName="askcuepi" {...props} />
      </div>
      <div className="p-col-12 p-mt-2">
        <label className="p-d-block">Employment Status</label>

        <LaDropdown
          keyName="tblEmploymentStatusId"
          optionLabel="name"
          url={"/lovEmploymentStatus"}
          {...props}
          placeholder="Select"
        />
      </div>
    </>
  );
}

export default OicClaimantDetails;
