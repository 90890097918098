import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "tokenExpiry",
  initialState: {
    isTokenExpiredForRelogin: false,
  },
  reducers: {
    SETTOKENEXPIRY: (state, action) => {
      state.isTokenExpiredForRelogin = action.payload;
    },
  },
});

export const { SETTOKENEXPIRY } = slice.actions;
export default slice.reducer;
