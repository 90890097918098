import React from "react";
import styled, { keyframes } from "styled-components";
import AddJobButton from "./AddJob/AddJobButton";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 50px;
`;

const Message = styled.div`
  margin-bottom: 20px;
  padding: 15px;
  font-size: 1.2rem;
  color: #333;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const NoJobsComponent = ({ getjobList, id }) => {
  return (
    <Container>
      <Message>
        No jobs found for this company. You can add a new job here:
      </Message>
      <AddJobButton getjobList={getjobList} id={id} />
    </Container>
  );
};

export default NoJobsComponent;
