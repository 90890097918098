import React, { useState } from "react";
import { Button } from "primereact/button";

function SignatureType({ onSubmit }) {
  const [signatureText, setSignatureText] = useState("");
  const [loading, setLoading] = useState(false);
  const loadingIcon = "pi pi-spin pi-spinner";

  const clear = () => {
    setSignatureText("");
  };

  const handleTypeSubmit = async () => {
    setLoading(true);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    // Reduce font size for the signature
    ctx.font = "2rem Cedarville Cursive";
    ctx.fontWeight = "600";
    ctx.fillStyle = "#000";
    // Adjust position to make the signature smaller and centered
    ctx.fillText(signatureText, 50, 80);

    const signatureTextField = canvas.toDataURL();
    const formatSinged = signatureTextField.replace(
      "data:image/png;base64,",
      ""
    );
    await onSubmit(formatSinged);
    setLoading(false);
  };

  return (
    <div align="center" className="mt-4">
      <input
        style={{
          border: "1px solid #CCCCCC",
          borderRadius: "6px",
          width: "100%",
          maxWidth: "500px",
          padding: "10px 20px",
        }}
        value={signatureText}
        placeholder="Type your signature here"
        onChange={(e) => setSignatureText(e.target.value)}
      />
      <div className="d-flex justify-content-center mt-4">
        <Button label="Clear" onClick={clear} className="btn btn-primary" />
        <Button
          label="Submit"
          onClick={handleTypeSubmit}
          className="p-ml-2 btn btn-secondary"
          disabled={loading}
          icon={loading && loadingIcon}
        />
      </div>
    </div>
  );
}

export default SignatureType;
