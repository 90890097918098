import { Button } from "primereact/button";
import React, { useState } from "react";
import AddJobDialog from "./AddJobDialog";

function AddJobButton({ getjobList, id }) {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Button
        type="submit"
        className="btn btn-primary"
        onClick={() => setShowModal(true)}
      >
        Add New Job
      </Button>
      <AddJobDialog
        showModal={showModal}
        onCloseModal={onCloseModal}
        getjobList={getjobList}
        id={id}
      />
    </>
  );
}

export default AddJobButton;
