import React from "react";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";

function LaStaticDropdown({
  keyName,
  getFormErrorMessage,
  values,
  options,
  onChange,
  placeholder,
  isFormFieldValid,
  ...rest
}) {
  return (
    <>
      <Dropdown
        id={keyName}
        name={keyName}
        value={values[keyName]}
        options={options || []}
        onChange={onChange}
        placeholder={placeholder}
        {...rest}
        className={classNames({
          "p-invalid": isFormFieldValid(keyName.toString()),
          Width__100: true,
        })}
      />
      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default LaStaticDropdown;
