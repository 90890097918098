import React from "react";
import LaVehicleReg from "../../../components/LaVehicleReg";

// keyNameRegNo: "registerationno",
// keyNameMakeModel: "makemodel",
// keyNameYearOfManufacture: "yearofmanufacture",
// keyNameInsurer: "insurer",
// keyNamePolicyNo: "policyno",
// keyNameRefNo: "refno",

function JointAgreementClaimantVehicle({
  classNames,
  isFormFieldValid,
  values,
  getFormErrorMessage,
  onChange,
  formik,
  keyNameRegNo,
  keyNameMakeModel,
  keyNameYearOfManufacture,
  keyNameInsurer,
  keyNamePolicyNo,
  keyNameRefNo,
}) {
  const vehicleRegProps = {
    classNames,
    isFormFieldValid,
    getFormErrorMessage,
    onChange,
    values,
    formik,
    keyNameRegNo,
    keyNameMakeModel,
    keyNameYearOfManufacture,
    keyNameInsurer,
    keyNamePolicyNo,
    keyNameRefNo,
  };

  return (
    <>
      <div style={{ marginLeft: "30px", marginTop: "20px" }}>
        <h6 style={{ fontWeight: "bold", fontSize: "12px" }}>
          Claimant Vehicle
        </h6>
      </div>
      <LaVehicleReg {...vehicleRegProps} />
    </>
  );
}

export default JointAgreementClaimantVehicle;
