import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";
import { Button } from "primereact/button";
import { handlePostRequest } from "../../services/PostTemplate";
import { InputTextarea } from "primereact/inputtextarea";

function EditIvoiceDetails({
  invoiceEditDetailsData,
  getInvoiceDetails,
  setIsVisbleEditDetails,
}) {
  useEffect(() => {
    formik.setFieldValue("adjust", invoiceEditDetailsData.adjust);
    formik.setFieldValue("charge", invoiceEditDetailsData.charge);
  }, [invoiceEditDetailsData]);

  const validationSchema = Yup.object().shape({
    adjust: Yup.string()
      .required("Required")
      .typeError("This must be a number"),
    charge: Yup.string().required("Required"),
  });

  const initialValues = {
    adjust: "",
    charge: "",
    comments: "",
  };

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="label__error p-d-block">{formik.errors[name]}</small>
      )
    );
  };

  const formik = useFormik({
    validationSchema: validationSchema,
    initialValues: initialValues,
    onSubmit: async (data) => {
      data["invoiceDetailId"] = invoiceEditDetailsData.invoicedetailid;
      data["invType"] = invoiceEditDetailsData.invType;
      await handlePostRequest(data, "invoicing/updateInvoiceDetail");
      setIsVisbleEditDetails(false);
      getInvoiceDetails();
      formik.resetForm();
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="p-fluid p-formgrid p-grid">
          <div className="p-col-12 p-md-12 mt-4">
            <label htmlFor="status">Adjust</label>
            <InputText
              id="adjust"
              name="adjust"
              value={formik.values.adjust}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("adjust"),
              })}
            />
            {getFormErrorMessage("adjust")}
          </div>
          <div className="p-col-12 p-md-12">
            <label htmlFor="status">Charge</label>
            <InputText
              id="charge"
              name="charge"
              value={formik.values.charge}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("charge"),
              })}
            />
            {getFormErrorMessage("charge")}
          </div>
          <div className="p-col-12 p-md-12">
            <label htmlFor="status">Comments</label>
            <InputTextarea
              rows={5}
              cols={30}
              id="comments"
              name="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
              className={classNames({
                "p-invalid": isFormFieldValid("comments"),
              })}
            />
            {getFormErrorMessage("comments")}
          </div>
        </div>
        <div className="p-col-12 p-md-12 mt-4">
          <Button type="submit" className="btn btn-primary mr-2">
            Submit
          </Button>
        </div>
      </form>
    </div>
  );
}

export default EditIvoiceDetails;
