import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import styled from "styled-components";

const StyledCard = styled.div`
  padding: 20px;
  background: #f4f4f4;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const Heading = styled.h2`
  color: #3498db; /* Dodger Blue */
  text-align: center;
  font-size: 24px;
  margin-bottom: 20px;
  text-transform: uppercase;
`;

export default function LineDemo() {
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue("--text-color");
    const textColorSecondary = documentStyle.getPropertyValue(
      "--text-color-secondary"
    );
    const surfaceBorder = documentStyle.getPropertyValue("--surface-border");
    const data = {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "Legal Assistance Requests",
          data: [10, 15, 20, 30, 25, 35, 40],
          fill: false,
          borderColor: "#3498DB", // Dodger Blue
          tension: 0.4,
        },
        {
          label: "Claims Assistance Requests",
          data: [5, 10, 15, 25, 20, 30, 35],
          fill: false,
          borderColor: "#E74C3C", // Alizarin
          tension: 0.4,
        },
      ],
    };
    const options = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: textColor,
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
        y: {
          ticks: {
            color: textColorSecondary,
          },
          grid: {
            color: surfaceBorder,
          },
        },
      },
    };

    setChartData(data);
    setChartOptions(options);
  }, []);

  return (
    <StyledCard>
      <Heading>Requests Over Time</Heading>
      <Chart type="line" data={chartData} options={chartOptions} />
    </StyledCard>
  );
}
