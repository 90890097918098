import React from "react";
import SideBox, {
  SideBoxWrapper,
} from "../../components/FormLayout/SideBoxSection";
import Notes from "../../components/Notes";
import { useSelector } from "react-redux";

function InvoiceNotes({ invoiceHeadId }) {
  const messages = useSelector(
    (state) => state.notesSlice?.notes?.tblInvoicenotes
  );
  return (
    <SideBoxWrapper className="w-100 p-mt-2">
      <SideBox title="Invoice Notes" className="mt-4">
        <Notes
          getNotesService={"invoicing/getInvoiceCaseNotes"}
          addNotesService={"invoicing/addNoteToInvoice"}
          caseType={"invoiceHeadId"}
          code={invoiceHeadId}
          messages={messages}
          onlyInternal
        />
      </SideBox>
    </SideBoxWrapper>
  );
}

export default InvoiceNotes;
