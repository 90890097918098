import { Button } from "primereact/button";
import React, { useState } from "react";
import AddProfileDialog from "./AddProfileDialog";

function AddProfileButton() {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Button
        type="submit"
        className="btn btn-primary"
        onClick={() => setShowModal(true)}
      >
        Add New Company
      </Button>
      <AddProfileDialog showModal={showModal} onCloseModal={onCloseModal} />
    </>
  );
}

export default AddProfileButton;
