import React from "react";
import styled from "styled-components";
import { AiOutlineRight } from "react-icons/ai";
import SideBox from "../../components/FormLayout/SideBoxSection";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const TableContainer = styled.div`
  overflow-x: auto; /* Enable horizontal scrolling on smaller screens */
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const TableHeader = styled.th`
  border-bottom: 1px solid #ccc;
  padding: 8px;
  text-align: left;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;

const TableCell = styled.td`
  border-bottom: 1px solid #ccc;
  padding: 8px;
  white-space: nowrap; /* Prevent text wrapping */
  overflow: hidden;
  text-overflow: ellipsis; /* Show ellipsis for overflowing text */
  color: ${(props) =>
    props.status === "New"
      ? "green"
      : props.status === "Rejected"
      ? "red"
      : "inherit"};
`;

const ClaimCodeLink = styled.a`
  cursor: pointer;
  color: blue;
  text-decoration: underline;
`;

function HdrCopyLogs({ hdrCopyLogs }) {
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);

  if (!hdrCopyLogs || !hdrCopyLogs.length) {
    return null;
  }

  if (user?.tblUsercategory?.categorycode === "2") {
    return null;
  }

  const handleClaimCodeClick = (e, hdrcode) => {
    e.preventDefault();
    navigate(`/hdrDetails/${hdrcode}`);
  };

  return (
    <SideBox className="mt-4" title="HDR Copy Logs">
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <TableHeader>Code</TableHeader>
              <TableHeader>Solicitor</TableHeader>
              <TableHeader>Status</TableHeader>
              <TableHeader>Date</TableHeader>
            </tr>
          </thead>
          <tbody>
            {hdrCopyLogs.map((log, index) => (
              <TableRow key={index}>
                <TableCell status={log.statusDescr}>
                  <ClaimCodeLink
                    href="#"
                    onClick={(e) => handleClaimCodeClick(e, log.hdrcode)}
                  >
                    {log.hdrClaimCode}
                  </ClaimCodeLink>
                </TableCell>
                <TableCell>{log.solicitorName}</TableCell>
                <TableCell status={log.statusDescr}>
                  {log.statusDescr}
                </TableCell>
                <TableCell>{log.createDate}</TableCell>
              </TableRow>
            ))}
          </tbody>
        </Table>
      </TableContainer>
    </SideBox>
  );
}

export default HdrCopyLogs;
