import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Skeleton } from "primereact/skeleton";
import { Card } from "primereact/card";
import { MdRestore } from "react-icons/md";
import { AiOutlineEye } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { resendEmail } from "../../services/Messages";
import { Dialog } from "primereact/dialog";

function MessagesTable({ messages, loading, compainName, posturl }) {
  const dispatch = useDispatch();
  const [viewEmailDetails, setViewEmailDetails] = React.useState(false);
  const resend = async ({ code }) => {
    console.log("resend", compainName, code);
    await resendEmail({ [compainName]: code }, posturl)(dispatch);
  };

  const skeleteon = () => {
    return <Skeleton></Skeleton>;
  };

  const tableSkeleton = () => {
    return (
      <DataTable value={[{}, {}, {}, {}, {}, {}]}>
        <Column
          body={skeleteon}
          header="Sent To"
          sortable
          filterMatchMode="contains"
        ></Column>
        <Column
          body={skeleteon}
          header="User Name"
          sortable
          filterMatchMode="contains"
        ></Column>
        <Column
          body={skeleteon}
          header="Created On"
          sortable
          filterMatchMode="contains"
        ></Column>
        <Column
          body={skeleteon}
          header="Current Task"
          sortable
          filterMatchMode="contains"
        ></Column>
        <Column
          body={skeleteon}
          header="Remarks"
          sortable
          filterMatchMode="contains"
        ></Column>
        <Column
          body={skeleteon}
          header="Acts"
          filterMatchMode="contains"
        ></Column>
      </DataTable>
    );
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="pointer">
        <MdRestore size="20" onClick={() => resend(rowData)} />
        <AiOutlineEye
          color="green"
          size="20"
          onClick={() => setViewEmailDetails(rowData)}
        />
      </div>
    );
  };
  return (
    <Card>
      {!loading && messages && messages.length ? (
        <DataTable value={messages} dataKey="rtamessagecode">
          <Column
            field="sentto"
            header="Sent To"
            filter
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="userName"
            header="User Name"
            filter
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="createdon"
            header="Created On"
            filter
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            field="remarks"
            header="Remarks"
            filter
            sortable
            filterMatchMode="contains"
          ></Column>
          <Column
            body={actionTemplate}
            header="Acts"
            filterMatchMode="contains"
          ></Column>
        </DataTable>
      ) : (
        tableSkeleton()
      )}
      <Dialog
        header={"Email Details"}
        visible={viewEmailDetails}
        style={{ width: "50%" }}
        onHide={() => setViewEmailDetails(false)}
        contentStyle={{ padding: "0 0rem 2rem 0rem " }}
      >
        <div>
          {Object.keys(viewEmailDetails).map((key) => {
            return (
              <div className="p-2">
                <strong>{key.toLocaleUpperCase()}</strong> :{" "}
                {key === "message" ? (
                  <div
                    className="pb-4"
                    dangerouslySetInnerHTML={{
                      __html: viewEmailDetails[key],
                    }}
                  ></div>
                ) : viewEmailDetails[key] ? (
                  viewEmailDetails[key]
                ) : (
                  "-"
                )}
              </div>
            );
          })}
        </div>
      </Dialog>
    </Card>
  );
}

export default MessagesTable;
