import { Button } from "primereact/button";
import React, { useState } from "react";
import { FaEdit } from "react-icons/fa";
import EditUserDialog from "./EditUserDialog";

function EditUserButton({ data, getuserList }) {
  const [showModal, setShowModal] = useState(false);

  const onCloseModal = () => {
    setShowModal(false);
  };
  return (
    <>
      <Button
        tooltip="Edit"
        tooltipOptions={{ position: "top" }}
        style={{ color: "#3F51B5" }}
        className="btn btn-sm p-button-text"
        onClick={() => {
          setShowModal(true);
        }}
      >
        <FaEdit />
      </Button>
      <EditUserDialog
        showModal={showModal}
        onCloseModal={onCloseModal}
        data={data}
        getuserList={getuserList}
      />
    </>
  );
}

export default EditUserButton;
