import * as Yup from "yup";

const minordetails = {
  gtitle: "",
  gfirstname: "",
  gmiddlename: "",
  glastname: "",
  gdob: "",
  gmobile: null,
  glandline: "",
  gemail: "",
  gpostalcode: "",
  gaddress1: "",
  gaddress2: "",
  gaddress3: "",
  gcity: "",
  gregion: "",
};

const claimantdetails = {
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  clawbackDate: "",
  dob: "",
  ninumber: "",
  englishlevel: "",
  mobile: null,
  landline: "",
  email: "",
  occupation: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  region: "",
  scotland: false,
  minor: false,
  showMinorModal: "",
  claimantDetails: "",
  minorDetails: "",
  accidentDetails: "",
  vehiclesDetails: "",
  images: "",
  translatordetail: "",
  password: "",
  alternativenumber: "",
};

const accidentdetails = {
  accdate: "",
  acctime: "",
  circumcode: "",
  circumdescr: "",
  location: "",
  description: "",
  rdweathercond: "",
  driverpassenger: "",
  airbagopened: false,
  injclasscodes: "",
  injdescr: "",
  injlevel: "",
  injdescription: "",
  injlength: "",
  ongoing: false,
  medicalinfo: false,
  medicalevidence: "",
  vehicledamage: "",
  injurySustained: "",
};

const vehicledetails = {
  vehiclecondition: "",
  vehicleType: "",
  location: "",
  reportedtopolice: "",
  vdImages: "",
  reportedon: "",
  refno: "",
  referencenumber: "",
  registerationno: "",
  makemodel: "",
  insurer: "",
  policyno: "",
  partyregno: "",
  partymakemodel: "",
  partyinsurer: "",
  partypolicyno: "",
  partyrefno: "",
  greencardno: "",
  address1: "",
  partyname: "",
  partycontactno: "",
  partyaddress: "",
  evidencedatails: "",
  alternativenumber: "",
  policycover: "",
  policyholder: "",
  recovered: "",
  recoveredby: "",
  instorage: "",
  storage: "",
  lengthoflicenceheld: "",
  detail: "",
  yearofmanufacture: "",
};

const OicFields = {
  askcuepi: "",
  carRepairingYn: "",
  claimForLossesYn: "",
  claimantDescription: "",
  claimantDetailsObtained: "",
  claimantResponsibilityExplanation: "",
  clientAttendHospitalYn: "",
  clientCircumstancesSevereYn: "",
  clientOwnVehicleYn: "",
  correctVehicleYn: "",
  explanationReason: "",
  hospitalName: "",
  hospitalNights: "",
  peopleInVehicle: "",
  physiotherapyYn: "",
  policeOfficerName: "",
  policeStationAddress: "",
  seatbeltYn: "",
  signatoryFirstName: "",
  signatoryJobTitle: "",
  signatoryLastName: "",
  signatoryMiddleName: "",
  stillOffWorkYn: "",
  tempReplacementVehicleYn: "",
  timeOffWorkYn: "",
  totalDaysOffWork: "",
  whoClientSee: "",
  whoClientSeeDetail: "",
  tblClientInvolvementId: "",
  tblEmploymentStatusId: "",
  tblExplanationReasonId1: "",
  tblExplanationReasonId2: "",
  tblRtawitnesses: "",
};

const initialValues = {
  ...claimantdetails,
  ...accidentdetails,
  ...vehicledetails,
  ...minordetails,
  ...OicFields,
};

const validationSchema = Yup.object().shape({
  title: Yup.string().required("required."),
  firstname: Yup.string().required("required."),
  dob: Yup.string().required("required."),
  mobile: Yup.string()
    .required("required.")
    .nullable()
    .max(11, "Max 11 digits")
    .min(11, "Min 11 digits")
    .matches(/^[0-9]+$/, "Must be only digits"),
  postalcode: Yup.string().required("required."),
  accdate: Yup.string().required("required."),
  acctime: Yup.string().required("required."),
  circumcode: Yup.mixed().required("required."),
  location: Yup.string().required("required."),
  description: Yup.string().required("required."),
  driverpassenger: Yup.string().required("required."),
  // injdescription: Yup.string().required("required."),  TODO: check why this is not working
  injclasscodes: Yup.mixed().required("required."),
  vehicleType: Yup.string().required("required."),
  // injurySustained: Yup.string().required("required."),
  registerationno: Yup.mixed().when("vehicleType", {
    is: (vehicleType) => vehicleType !== "P",
    then: Yup.string().required("required."),
  }),
  makemodel: Yup.mixed().when("vehicleType", {
    is: (vehicleType) => vehicleType !== "P",
    then: Yup.string().required("required."),
  }),
  englishlevel: Yup.string().required("required."),
  gtitle: Yup.mixed().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gfirstname: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  glastname: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gdob: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gaddress1: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gaddress3: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gcity: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gregion: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gpostalcode: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  injlength: Yup.mixed().when("ongoing", {
    is: (value) => value === false || value === "N",
    then: Yup.mixed().required("required."),
  }),
  ninumber: Yup.string()
    .required("required.")
    .test("ninumber", "Invalid NIN", (value) => {
      if (
        value === "Will be provided to the solicitor" ||
        value === "Minor" ||
        value === "Solicitor obtained Number"
      ) {
        return true;
      }
      if (value) {
        let ninumberFirstChar = value[0];
        let ninumberSecondChar = value[1];
        let prefix = ninumberFirstChar + ninumberSecondChar;
        let prefixCheckList = ["BG", "GB", "KN", "NK", "NT", "TN", "ZZ"];
        let checklist = "D,F,I,Q,U,V";
        if (
          checklist.includes(ninumberSecondChar) ||
          checklist.includes(ninumberFirstChar) ||
          value[1] === "O" ||
          prefixCheckList.includes(prefix)
        ) {
          return false;
        } else {
          const re = new RegExp(
            /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1,}?\s*$/
          );
          return re.test(value);
        }
      }
      return true;
    }),
});

const passengerKeys = {
  title: "",
  firstname: "",
  middlename: "",
  lastname: "",
  dob: "",
  ninumber: "",
  mobile: null,
  landine: "",
  email: "",
  postalcode: "",
  address1: "",
  address2: "",
  address3: "",
  city: "",
  region: "",
  scotland: false,
  minor: false,
  gtitle: "",
  gfirstname: "",
  gmiddlename: "",
  glastname: "",
  gdob: "",
  gmobile: null,
  glandline: "",
  gemail: "",
  gpostalcode: "",
  gaddress1: "",
  gaddress2: "",
  gaddress3: "",
  gcity: "",
  gregion: "",
  driverpassenger: "",
  injclasscodes: "",
  injdescription: "",
  injlength: "",
  ongoing: false,
  medicalinfo: false,
  medicalevidence: "",
};

const passengerValidationSchema = Yup.object().shape({
  title: Yup.string().required("required."),
  firstname: Yup.string().required("required."),
  lastname: Yup.string().required("required."),
  dob: Yup.string().required("required."),
  mobile: Yup.string()
    .required("required.")
    .nullable()
    .max(11, "Max 11 digits")
    .min(11, "Min 11 digits"),
  postalcode: Yup.string().required("required."),
  driverpassenger: Yup.string().required("required."),
  injclasscodes: Yup.mixed().required("required."),
  injdescription: Yup.string().required("required."),
  gtitle: Yup.mixed().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gfirstname: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gdob: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  gpostalcode: Yup.string().when("minor", {
    is: (isMinor) => isMinor === true || isMinor === "Y",
    then: Yup.string().required("required."),
  }),
  injlength: Yup.mixed().when("ongoing", {
    is: (value) => value === false || value === "N",
    then: Yup.mixed().required("required."),
  }),
  ninumber: Yup.string()
    .required("required.")
    .test("ninumber", "Invalid NIN", (value) => {
      if (
        value === "Will be provided to the solicitor" ||
        value === "Minor" ||
        value === "Solicitor obtained Number"
      ) {
        return true;
      }
      if (value) {
        let ninumberFirstChar = value[0];
        let ninumberSecondChar = value[1];
        let prefix = ninumberFirstChar + ninumberSecondChar;
        let prefixCheckList = ["BG", "GB", "KN", "NK", "NT", "TN", "ZZ"];
        let checklist = "D,F,I,Q,U,V";
        if (
          checklist.includes(ninumberSecondChar) ||
          checklist.includes(ninumberFirstChar) ||
          value[1] === "O" ||
          prefixCheckList.includes(prefix)
        ) {
          return false;
        } else {
          const re = new RegExp(
            /^\s*[a-zA-Z]{2}(?:\s*\d\s*){6}[a-zA-Z]{1,}?\s*$/
          );
          return re.test(value);
        }
      }
      return true;
    }),
});

export {
  initialValues,
  validationSchema,
  passengerKeys,
  passengerValidationSchema,
};
