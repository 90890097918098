import { Button } from "primereact/button";
import React from "react";
import { handlePostRequest } from "../../../services/PostTemplate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function DuplicateCaseToHdr() {
  const { hdr } = useSelector((state) => state.hdrSlice);
  const navigate = useNavigate();
  const userStore = useSelector((state) => state.authenticationSlice.loggedIn);
  const user = userStore && JSON.parse(userStore);
  const userCatInternal = user?.tblUsercategory?.categorycode;

  const duplicateCaseToHdr = async () => {
    const res = await handlePostRequest(
      { hdrcode: hdr.hdrclaimcode },
      "hdr/copyHdrToHdr"
    );
    navigate(`/hdrDetails/${res.data?.hdrcode}`);
  };

  if (userCatInternal !== "4" || hdr?.hdrclaimcode === undefined) {
    return null;
  }

  return (
    <Button
      onClick={duplicateCaseToHdr}
      label="Copy Case to Hdr"
      className="btn-info btn-sm p-mr-2 p-mb-2"
    />
  );
}

export default DuplicateCaseToHdr;
