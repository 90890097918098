// LaDropdown.js
import React, { useEffect } from "react";
import { Dropdown } from "primereact/dropdown";
import classNames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { fetchDropdownOptions } from "../../redux/actions/dropdownAction";

function LaDropdown({
  onChange,
  values,
  isFormFieldValid,
  getFormErrorMessage,
  keyName,
  placeholder,
  optionLabel,
  url,
  hardRefresh = false,
  ...props
}) {
  const dispatch = useDispatch();
  const options = useSelector((state) => state.dropdownSlice?.dropdowns[url]);

  useEffect(() => {
    const checkAndFetchOptions = async () => {
      // Check if options are already present in the Redux store
      if (!options || hardRefresh) {
        dispatch(fetchDropdownOptions(url, optionLabel));
      }
    };

    // Invoke the function to check and fetch options
    checkAndFetchOptions();
  }, [dispatch, options, url, optionLabel, hardRefresh]);

  return (
    <>
      <Dropdown
        id={keyName}
        placeholder={placeholder}
        name={keyName}
        value={values[keyName]}
        options={options || []}
        onChange={onChange}
        optionLabel={optionLabel}
        {...props}
        className={classNames({
          "p-invalid": isFormFieldValid(keyName.toString()),
          Width__100: true,
        })}
      />
      {getFormErrorMessage(keyName.toString())}
    </>
  );
}

export default LaDropdown;
