import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import ClaimDetail from "./ClaimDetail";
import EffectsInfo from "./EffectsInfo";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";
import AffectedPersonModel from "./affectedperson";
import AffectedPersonTable from "./affectedperson/affectedpersontable";
import AffectedRoomModel from "./AffectedRoom";
import AffectedRoomTable from "./AffectedRoom/AffectedRoomTable";
import { hdrValidation } from "../../utilities/validation";
import {
  mainClaimantConstants,
  jointTenancyConstants,
  nameTitleOptions,
} from "../../utilities/constants";
import { addHdrCase } from "../../redux/actions/hdrAction";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AccSubmissionCriteria from "../../components/AccSubmissionCriteria";
import HdrClaimantAddress from "./HdrClaimantAddress";
import HdrJointTenantAddress from "./HdrJointTenantAddress";
import { campaignCodes } from "../../components/CampaignGeneric/campaignConfig";
import EnergyDebtSection from "./EnergyDebtSection";

function Hdr() {
  const [isJointTenancy, setisJointTenancy] = useState("");
  const [showJointTenant, setshowJointTenant] = useState(false);
  const [mainClaimant, setmainClaimant] = useState(mainClaimantConstants);
  const [claimantDetails, setclaimantDetails] = useState({});
  const [jointTenantDetails, setJointTenantDetails] = useState(
    jointTenancyConstants
  );
  const [tenantDetails, settenantDetails] = useState({});
  const [effectsInfoDetails, seteffectsInfoDetails] = useState({});
  const [affectedPerson, setaffectedPerson] = useState([]);
  const [affectedRoom, setaffectedRoom] = useState([]);
  const [displayBasic, setDisplayBasic] = useState(false);
  const [displayAffectedRoom, setDisplayAffectedRoom] = useState(false);
  const [errors, seterrors] = useState({});
  const [roomError, setRoomError] = useState(false);
  const [healthAffected, sethealthAffected] = useState("");
  const [isAccSubmissionCriteria, setisAccSubmissionCriteria] = useState(false);
  const [accSubmissionCriteria, setaccSubmissionCriteria] = useState({});
  const [hdrClaimantAddressobj, sethdrClaimantAddressobj] = useState({});
  const [hdrJointTenantAddressobj, sethdrJointTenantAddressobj] = useState({});
  const [hdrEnergyDebtObj, sethdrEnergyDebtObj] = useState({});

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const handleAddAffected = (affected) => {
    let newArr = JSON.parse(JSON.stringify(affectedPerson));
    newArr.push(affected);
    newArr[newArr.length - 1].id = newArr.length;
    setaffectedPerson(newArr);
  };

  const handleAddAffectedRoom = (affected) => {
    let newArr = JSON.parse(JSON.stringify(affectedRoom));
    newArr.push(affected);
    newArr[newArr.length - 1].id = newArr.length;
    setaffectedRoom(newArr);
  };

  const handleRemoveAffected = (id) => {
    let newArr = JSON.parse(JSON.stringify(affectedPerson));
    setaffectedPerson(newArr.filter((elm) => Number(elm?.id) !== Number(id)));
  };

  const handleRemoveAffectedRoom = (id) => {
    let newArr = JSON.parse(JSON.stringify(affectedRoom));
    setaffectedRoom(newArr.filter((elm) => Number(elm?.id) !== Number(id)));
  };

  const handleUpdateAffected = (affected) => {
    const filtered = affectedPerson.filter((elm) => elm?.id !== affected?.id);
    const index = affectedPerson.findIndex((elm) => elm?.id === affected?.id);
    filtered.splice(index, 0, affected);
    setaffectedPerson(filtered);
  };

  const handleUpdateAffectedRoom = (affected) => {
    const filtered = affectedRoom.filter((elm) => elm?.id !== affected?.id);
    const index = affectedRoom.findIndex((elm) => elm?.id === affected?.id);
    filtered.splice(index, 0, affected);
    setaffectedRoom(filtered);
  };

  const handleJointTenancy = (e) => {
    setisJointTenancy(e.value);
    setshowJointTenant(e.value === "yes" ? true : false);
  };

  const handlehealthAffected = (value) => {
    sethealthAffected(value);
  };

  const handleSubmit = async () => {
    !affectedRoom.length ? setRoomError(true) : setRoomError(false);
    // jointTenantDetails["ttitle"] = jointTenantDetails["ttitle"]?.code;
    // mainClaimant["ctitle"] = mainClaimant["ctitle"]?.code;
    claimantDetails["healthAffected"] = healthAffected?.healthAffected;
    claimantDetails["healthRelDetails"] = healthAffected?.healthRelDetails;
    claimantDetails["introducer"] =
      accSubmissionCriteria?.introducer?.companycode;
    claimantDetails["advisor"] = accSubmissionCriteria?.advisor?.usercode;
    hdrEnergyDebtObj["outstandingDebt"] =
      hdrEnergyDebtObj?.outstandingDebt === true
        ? "Y"
        : hdrEnergyDebtObj?.outstandingDebt === "Y"
        ? "Y"
        : "N";
    const updatedMainClaimant = {
      ...mainClaimant,
      ...hdrClaimantAddressobj,
      ...hdrEnergyDebtObj,
    };
    const updatedJointTenantDetails = {
      ...jointTenantDetails,
      ...hdrJointTenantAddressobj,
    };
    const hdrJson = {
      saveTblHdrclaim: claimantDetails,
      saveTblHdrclaimant: { ...updatedMainClaimant, isJointTenancy },
      saveTblHdrjointtenancy: updatedJointTenantDetails,
      saveTblHdrtenancy: tenantDetails,
      saveTblHdraffectedperList: affectedPerson,
      saveTblHdraffectedroomList: affectedRoom,
    };
    const allJson = {
      ...claimantDetails,
      ...mainClaimant,
      ...jointTenantDetails,
      ...tenantDetails,
      ...affectedPerson,
      ...affectedRoom,
      ...effectsInfoDetails,
    };
    const isvalid = await hdrValidation(allJson, isJointTenancy);
    seterrors(isvalid?.errors);
    if (!Object.keys(isvalid?.errors).length) {
      const res = await addHdrCase(hdrJson)(dispatch);
      if (res?.responsecode === 1) {
        navigate("../hdrDetails/" + res?.data);
      }
    }
  };

  const handleAccSum = (data) => {
    setisAccSubmissionCriteria(true);
    setaccSubmissionCriteria(data);
  };

  return (
    <>
      {!isAccSubmissionCriteria ? (
        <AccSubmissionCriteria
          handleAccSum={handleAccSum}
          campaignCode={campaignCodes.HDR}
        />
      ) : (
        <div>
          <div className="p-mt-4 p-ml-4">
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <h6
                  style={{
                    color: "#428BCA",
                    fontWeight: "600",
                    lineHeight: "1.42857143",
                  }}
                >
                  HDR Main Info
                </h6>
                <small style={{ color: "#999" }}>Enter main information</small>
              </div>
              <div>
                <Button
                  type="button"
                  icon="pi pi-arrow-circle-right"
                  iconPos="right"
                  className="btn btn-primary"
                  label="Next"
                  onClick={handleSubmit}
                ></Button>
              </div>
            </div>
            <hr className="border__hr" />

            <div className="p-fluid p-formgrid p-grid">
              <div className="p-col-12 p-mt-6">
                <label className="p-d-block">Is this a Joint Tenancy?</label>
              </div>
              <div className="p-col-12 p-mb-2">
                <div className="field-radiobutton">
                  <RadioButton
                    name="isJointTenancy"
                    value="yes"
                    onChange={(e) => handleJointTenancy(e)}
                    checked={isJointTenancy === "yes"}
                  />
                  <label htmlFor="isJointTenancysyes" className="p-ml-1">
                    Yes
                  </label>
                </div>
                <div className="field-radiobutton">
                  <RadioButton
                    name="isJointTenancy"
                    value="no"
                    onChange={(e) => handleJointTenancy(e)}
                    checked={isJointTenancy === "no"}
                  />
                  <label htmlFor="isJointTenancysno" className="p-ml-1">
                    No
                  </label>
                </div>
              </div>
              <div className="p-col-6">
                <div className="p-fluid p-formgrid p-grid p-pr-4">
                  <div className="Panel__Heading">Main Claimant</div>
                  <div className="p-col-12">
                    <label className="p-d-block">Name *</label>

                    <Dropdown
                      options={nameTitleOptions}
                      optionLabel="name"
                      placeholder="Select a Name"
                      value={mainClaimant?.ctitle}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          ctitle: e.value,
                        });
                      }}
                    />
                  </div>
                  <div className="p-col-12 p-mt-2">
                    <InputText
                      placeholder="First"
                      value={mainClaimant?.cfname}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          cfname: e.target.value,
                        });
                      }}
                      className={
                        errors?.cfname && "p-inputtext-error p-d-block"
                      }
                    />
                    <small
                      className={errors?.cfname && "label__error p-d-block"}
                    >
                      {errors?.cfname}
                    </small>
                  </div>
                  <div className="p-col-12 p-mt-2">
                    <InputText
                      placeholder="Middle"
                      value={mainClaimant?.cmname}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          cmname: e.target.value,
                        });
                      }}
                      className={
                        errors?.cmname && "p-inputtext-error p-d-block"
                      }
                    />
                    <small
                      className={errors?.cmname && "label__error p-d-block"}
                    >
                      {errors?.cmname}
                    </small>
                  </div>
                  <div className="p-col-12 p-mt-2">
                    <InputText
                      placeholder="Surname"
                      value={mainClaimant?.csname}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          csname: e.target.value,
                        });
                      }}
                      className={
                        errors?.csname && "p-inputtext-error p-d-block"
                      }
                    />
                    <small
                      className={errors?.csname && "label__error p-d-block"}
                    >
                      {errors?.csname}
                    </small>
                  </div>
                  <div className="p-col-12 p-mt-6">
                    <label className="p-d-block">Date of birth *</label>
                    <InputText
                      type="date"
                      value={mainClaimant?.cdob}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          cdob: e.target.value,
                        });
                      }}
                      className={errors?.cdob && "p-inputtext-error p-d-block"}
                    />
                    <small className={errors?.cdob && "label__error p-d-block"}>
                      {errors?.cdob}
                    </small>
                  </div>
                  <div className="p-col-12 p-mt-4">
                    <label className="p-d-block">NI number</label>
                    <InputText
                      placeholder="FDFDFD"
                      value={mainClaimant?.cninumber}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          cninumber: e.target.value,
                        });
                      }}
                      className={
                        errors?.cninumber && "p-inputtext-error p-d-block"
                      }
                    />
                    <small
                      className={errors?.cninumber && "label__error p-d-block"}
                    >
                      {errors?.cninumber}
                    </small>
                  </div>
                  <div className="p-col-6 p-mt-6">
                    <label className="p-d-block">Mobile *</label>
                    <InputText
                      placeholder="Mobile"
                      value={mainClaimant?.cmobileno}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          cmobileno: e.target.value,
                        });
                      }}
                      className={
                        errors?.cmobileno && "p-inputtext-error p-d-block"
                      }
                    />
                    <small
                      className={errors?.cmobileno && "label__error p-d-block"}
                    >
                      {errors?.cmobileno}
                    </small>
                  </div>
                  <div className="p-col-6 p-mt-6">
                    <label className="p-d-block">Landline</label>
                    <InputText
                      placeholder="Landline"
                      value={mainClaimant?.clandline}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          clandline: e.target.value,
                        });
                      }}
                      className={
                        errors?.clandline && "p-inputtext-error p-d-block"
                      }
                    />
                    <small
                      className={errors?.clandline && "label__error p-d-block"}
                    >
                      {errors?.clandline}
                    </small>
                  </div>
                  <div className="p-col-12 p-mt-2">
                    <label className="p-d-block">Best time to contact</label>
                    <InputText
                      value={mainClaimant?.ccontacttime}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          ccontacttime: e.target.value,
                        });
                      }}
                      className={
                        errors?.ccontacttime && "p-inputtext-error p-d-block"
                      }
                    />
                    <small
                      className={
                        errors?.ccontacttime && "label__error p-d-block"
                      }
                    >
                      {errors?.ccontacttime}
                    </small>
                  </div>
                  <div className="p-col-12 p-mt-2">
                    <label className="p-d-block">Email</label>
                    <InputText
                      value={mainClaimant?.cemail}
                      onChange={(e) => {
                        setmainClaimant({
                          ...mainClaimant,
                          cemail: e.target.value,
                        });
                      }}
                      className={
                        errors?.cemail && "p-inputtext-error p-d-block"
                      }
                    />
                    <small
                      className={errors?.cemail && "label__error p-d-block"}
                    >
                      {errors?.cemail}
                    </small>
                  </div>
                  <HdrClaimantAddress
                    sethdrClaimantAddressobj={sethdrClaimantAddressobj}
                    hdrClaimantAddressobj={hdrClaimantAddressobj}
                  />
                </div>
              </div>
              {showJointTenant ? (
                <div className="p-col-6">
                  <div className="p-fluid p-formgrid p-grid p-pr-4">
                    <div className="Panel__Heading">Joint Tenant</div>
                    <div className="p-col-12">
                      <label className="p-d-block">Name *</label>
                      <Dropdown
                        options={nameTitleOptions}
                        optionLabel="name"
                        placeholder="Select a Name"
                        value={jointTenantDetails?.ttitle}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            ttitle: e.value,
                          });
                        }}
                      />
                    </div>
                    <div className="p-col-12 p-mt-2">
                      <InputText
                        placeholder="First"
                        value={jointTenantDetails?.tfname}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            tfname: e.target.value,
                          });
                        }}
                        className={
                          errors?.tfname && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={errors?.tfname && "label__error p-d-block"}
                      >
                        {errors?.tfname}
                      </small>
                    </div>
                    <div className="p-col-12 p-mt-2">
                      <InputText
                        value={jointTenantDetails?.tmname}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            tmname: e.target.value,
                          });
                        }}
                        placeholder="Middle"
                        className={
                          errors?.tmname && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={errors?.tmname && "label__error p-d-block"}
                      >
                        {errors?.tmname}
                      </small>
                    </div>
                    <div className="p-col-12 p-mt-2">
                      <InputText
                        value={jointTenantDetails?.tsname}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            tsname: e.target.value,
                          });
                        }}
                        placeholder="Surname"
                        className={
                          errors?.tsname && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={errors?.tsname && "label__error p-d-block"}
                      >
                        {errors?.tsname}
                      </small>
                    </div>
                    <div className="p-col-12 p-mt-6">
                      <label className="p-d-block">Date of birth *</label>
                      <InputText
                        value={jointTenantDetails?.tdob}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            tdob: e.target.value,
                          });
                        }}
                        type="date"
                        className={
                          errors?.tdob && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={errors?.tdob && "label__error p-d-block"}
                      >
                        {errors?.tdob}
                      </small>
                    </div>
                    <div className="p-col-12 p-mt-4">
                      <label className="p-d-block">NI number</label>
                      <InputText
                        value={jointTenantDetails?.tninumber}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            tninumber: e.target.value,
                          });
                        }}
                        placeholder="Ni Number"
                        className={
                          errors?.tninumber && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={
                          errors?.tninumber && "label__error p-d-block"
                        }
                      >
                        {errors?.tninumber}
                      </small>
                    </div>
                    <div className="p-col-6 p-mt-6">
                      <label className="p-d-block">Mobile *</label>
                      <InputText
                        value={jointTenantDetails?.tmobileno}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            tmobileno: e.target.value,
                          });
                        }}
                        placeholder="Mobile"
                        className={
                          errors?.tmobileno && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={
                          errors?.tmobileno && "label__error p-d-block"
                        }
                      >
                        {errors?.tmobileno}
                      </small>
                    </div>
                    <div className="p-col-6 p-mt-6">
                      <label className="p-d-block">Landline</label>
                      <InputText
                        value={jointTenantDetails?.tlandline}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            tlandline: e.target.value,
                          });
                        }}
                        placeholder="Landline"
                        className={
                          errors?.tlandline && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={
                          errors?.tlandline && "label__error p-d-block"
                        }
                      >
                        {errors?.tlandline}
                      </small>
                    </div>
                    <div className="p-col-12 p-mt-2">
                      <label className="p-d-block">Best time to contact</label>
                      <InputText
                        value={jointTenantDetails?.tcontacttime}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            tcontacttime: e.target.value,
                          });
                        }}
                        className={
                          errors?.tcontacttime && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={
                          errors?.tcontacttime && "label__error p-d-block"
                        }
                      >
                        {errors?.tcontacttime}
                      </small>
                    </div>
                    <div className="p-col-12 p-mt-2">
                      <label className="p-d-block">Email</label>
                      <InputText
                        value={jointTenantDetails?.temail}
                        onChange={(e) => {
                          setJointTenantDetails({
                            ...jointTenantDetails,
                            temail: e.target.value,
                          });
                        }}
                        className={
                          errors?.temail && "p-inputtext-error p-d-block"
                        }
                      />
                      <small
                        className={errors?.temail && "label__error p-d-block"}
                      >
                        {errors?.temail}
                      </small>
                    </div>
                    <HdrJointTenantAddress
                      sethdrJointTenantAddressobj={sethdrJointTenantAddressobj}
                      hdrJointTenantAddressobj={hdrJointTenantAddressobj}
                    />
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <ClaimDetail
            errors={errors}
            handleClaimReturn={setclaimantDetails}
            handleTenantReturn={settenantDetails}
            hasTenant
          />
          <EnergyDebtSection
            sethdrEnergyDebtObj={sethdrEnergyDebtObj}
            hdrEnergyDebtObj={hdrEnergyDebtObj}
          />
          <EffectsInfo
            errors={errors}
            handleEffectsInfoDetails={handlehealthAffected}
          />
          <AffectedPersonModel
            show={displayBasic}
            hide={setDisplayBasic}
            handleAffectedReturn={handleAddAffected}
          />
          <AffectedPersonTable
            handleUpdateAffected={handleUpdateAffected}
            affectedPerson={affectedPerson}
            handleRemoveAffected={handleRemoveAffected}
          />
          <div
            className="p-mt-4"
            onClick={() => setDisplayBasic(!displayBasic)}
          >
            <p style={{ cursor: "pointer", color: "#555" }}>
              --- affected person: click to add ---
            </p>
          </div>

          <AffectedRoomModel
            show={displayAffectedRoom}
            hide={setDisplayAffectedRoom}
            handleAffectedReturn={handleAddAffectedRoom}
          />

          <AffectedRoomTable
            handleUpdateAffected={handleUpdateAffectedRoom}
            affectedRoom={affectedRoom}
            handleRemoveAffected={handleRemoveAffectedRoom}
          />

          <div
            className="p-mt-4"
            onClick={() => setDisplayAffectedRoom((display) => !display)}
          >
            <p style={{ cursor: "pointer", color: "#555" }}>
              --- affected room: click to add ---
            </p>
            <small className={roomError && "label__error p-d-block "}>
              {roomError ? "required." : null}
            </small>
          </div>
          <br />
        </div>
      )}
    </>
  );
}

export default Hdr;
