import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

const OptionButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const Button = styled.button`
  position: relative;
  height: 33px;
  width: 100%;
  padding-right: 25px; /* Add padding to accommodate the icon */
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 0px !important;
  cursor: pointer;

  &:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-top: 6px solid #000;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;

const OptionsList = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 0;
  margin: 0;
  list-style: none;
  z-index: 1;
  width: 150px;
`;

const OptionItem = styled.li`
  padding: 10px;
  cursor: pointer;

  &:hover {
    background-color: #f0f0f0;
  }
`;

function OptionButton({ options, onChangeOption }) {
  const [showOptions, setShowOptions] = useState(false);
  const optionsRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        optionsRef.current &&
        !optionsRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowOptions(false);
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleButtonClick = (e) => {
    e.preventDefault();
    setShowOptions(!showOptions);
  };

  const handleOptionClick = (option) => {
    onChangeOption(option);
    setShowOptions(false);
  };

  return (
    <OptionButtonContainer>
      <Button ref={buttonRef} onClick={handleButtonClick}>
        Options
      </Button>
      {showOptions && (
        <OptionsList ref={optionsRef}>
          {options.map((option, index) => (
            <OptionItem key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </OptionItem>
          ))}
        </OptionsList>
      )}
    </OptionButtonContainer>
  );
}

export default OptionButton;
